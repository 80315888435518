<template>
	<v-app id="manageApp">
		<v-main>
			<v-container style="margin: 0">
				<router-view></router-view>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.container {
	max-width: 100% !important;
	height: 100%;
	margin: 10px;
	background: #fff;
	//background-color: #f5f5f5;
}
</style>
