import layout from "@/views/layout/index.vue";
const websiteStyleManage = () => import("@/views/contentCenter/websiteStyleManage.vue");
const templateManage = () => import("@/views/contentCenter/templateManage.vue");
const themeColor = () => import("@/views/contentCenter/themeColor.vue");
const mapDepot = () => import("@/views/contentCenter/mapDepot.vue");
const cornerLabelManage = () => import("@/views/contentCenter/cornerLabelManage.vue");
const videoDatabase = () => import("@/views/contentCenter/videoDatabase.vue");
export default {
	path: "/contentCenter",
	name: "contentCenter",
	component: layout,
	meta: {
		title: "内容管理",
		icon: "el-icon-ice-cream-square",
	},
	children: [
		{
			path: "websiteStyleManage",
			name: "websiteStyleManage",
			component: websiteStyleManage,
			meta: {
				title: "网站风格",
			},
		},
		{
			path: "templateManage",
			name: "templateManage",
			component: templateManage,
			meta: {
				title: "模板",
			},
		},
		{
			path: "themeColor",
			name: "themeColor",
			component: themeColor,
			meta: {
				title: "主题色",
			},
		},
		{
			path: "mapDepot",
			name: "mapDepot",
			component: mapDepot,
			meta: {
				title: "logo库",
			},
		},
		{
			path: "videoDatabase",
			name: "videoDatabase",
			component: videoDatabase,
			meta: {
				title: "video库",
			},
		},
		{
			path: "cornerLabelManage",
			name: "cornerLabelManage",
			component: cornerLabelManage,
			meta: {
				title: "角标管理",
			},
		},
	],
};
