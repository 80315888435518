import layout from "@/views/layout/index.vue";
const requirementList = () => import("@/views/taskSystem/requirementList/index.vue");
const taskManagement = () => import("@/views/taskSystem/taskManagement/index.vue");
export default {
	path: "/taskSystem",
	name: "taskSystem",
	component: layout,
	meta: {
		title: "任务系统2.0",
		icon: "el-icon-collection",
	},
	children: [
		{
			path: "requirementList",
			component: requirementList,
			name: "requirementList",
			meta: {
				title: "需求管理",
			},
		},
		{
			path: "taskManagement",
			component: taskManagement,
			name: "taskManagement",
			meta: {
				title: "任务管理",
			},
		},
	],
};
