<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>
<script>
import { connectWs } from "@/plugins/ws";
export default {
	name: "App",
	provide() {
		return {
			reload: this.reload,
		};
	},
	data() {
		return {
			isRouterAlive: true,
		};
	},
	created() {},
	methods: {
		// 通过声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
		reload() {
			this.isRouterAlive = false;
			this.$nextTick(() => {
				this.isRouterAlive = true;
			});
		},
	},
	mounted() {
		connectWs();
	},
};
</script>
<style lang="scss">
.tox-tinymce-aux {
	z-index: 9999 !important;
}

.viewer-toolbar li {
	margin: 0 0.5vmax;
	transform: scale(1.2);
}

.viewer-button {
	top: 1vmax !important;
	right: 5vmax !important;

	&::before {
		bottom: 1.5vmax !important;
		left: 1.5vmax !important;
		position: absolute;
	}
}
</style>
