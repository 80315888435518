<template>
  <div class="wrap">
    <div class="top">
      <h4>颜色分类</h4>
      <div class="button">
        <el-button type="primary" @click="dialogVisible=true">新增</el-button>
        <el-button v-show="!moveCategory" type="primary" @click="handleMove">批量移动</el-button>
        <el-button v-show="moveCategory" @click="moveCategory=false;spanIndex=[];moveColorList=[];">取消选中</el-button>
        <el-button v-show="moveCategory" @click="selectFinishBeforeRequest">选中完成</el-button>
        <el-button v-if="!flagMove" @click="clickMerge" class="btn_all" 
        type="primary" style=" width: 111px;background: #7F9DB5;border: #7F9DB5;">拖拽排序</el-button>
        <el-button v-else type="primary" @click="SaveClickMerge" style="width: 111px;
        margin-left:10px;background: #7F9DB5;border: #7F9DB5;">保存拖拽</el-button>
        <el-button style="width:80px" v-if="flagMove" @click="cancel">取 消</el-button>
      </div>
    </div>
    <div class="label-parent"  row-key="id">
      <div class="colorCard" style="position: relative;top: 0;margin-right:10px;
      width: 7rem;height: 2.5rem;"
      v-for="(item,index) in colorTypeAll" :key="item.id"
      :style="flagMove ? 'cursor:move ' : ''">
        <div class="shade" v-if="flagMove">
        	<div class="dragItem">
        		<span class="dragIcon" :class="{isBlack:item.code=='#000'}">
        			<b class="icon-a-zhiding1" @click="tableToTop(colorTypeAll[index], index)" v-if="index!== 0"></b>
        		</span>
        		<span class="dragIcon" :class="{isBlack:item.code=='#000'}">
        			<i class="el-icon-rank"></i>
        		</span>
        	</div>
        </div>
        <ColorPickerParentRectItem class="itemClass" 
        :colorItem="item"  :flagMove="flagMove"
        :activeParentValue="item.id==parentId"></ColorPickerParentRectItem>
      </div>
    </div>
    <div class="label-children" ref="moveObject" >
      <ColorPickerRectItem class="itemClass" :index="index" :spanIndex="spanIndex"
      :colorItem="item"  :moveCategory="moveCategory" 
       v-for="(item,index) in colorCardByCategory"
      :key="item.id" 
      ></ColorPickerRectItem>
    </div>
      <el-dialog
        title="新增色卡分类" top="30vh" @close="closeDialog"
        :visible.sync="dialogVisible"
        width="30%">
        <div>色卡类别名称：&nbsp;&nbsp;
          <el-input  v-model="typeName" style="width:30%;"></el-input></div>
        <div style="margin-top:10px;">色卡类别颜色：&nbsp;&nbsp;
        	<el-input v-model="code" style="width:30%;"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addTypeNameSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="编辑色卡分类" top="30vh" @close="closeDialog"
        :visible.sync="dialogEditVisible"
        width="30%">
        <div>色卡类别名称：&nbsp;&nbsp;
          <el-input  v-model="typeName" style="width:30%;"></el-input></div>
        <div style="margin-top:10px;">色卡类别颜色：&nbsp;&nbsp;
        	<el-input v-model="code" style="width:30%;"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogEditVisible = false">取 消</el-button>
          <el-button type="primary" @click="editTypeNameSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="移动色卡" top="30vh" @close="closeDialog"
        :visible.sync="dialogMoveVisible"
        width="30%">
        <div>选择要移动至哪个色卡分类：&nbsp;&nbsp;
          <el-select v-model="operateId" placeholder="请选择">
          <el-option
            v-for="item in colorTypeAll"
            :key="item.id"
            :label="item.typeName"
            :value="item.id">
          </el-option>
        </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogMoveVisible = false">取 消</el-button>
          <el-button type="primary" @click="moveCategorySubmit">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>
<script>
// import API from "@/api/designCenter/designColor.js";
// import { downloadFile } from "@/utils/utils";
import {selectColorType,selectColorCard,editColorType,
deleteColorType,batchUpdateColorCardType,sortColorType} from '@/api/designCenter/designColor.js';
import ColorPickerRectItem from '@/components/MyDzxt/ColorPickerRectItem.vue';
import ColorPickerParentRectItem from '@/components/MyDzxt/ColorPickerParentRectItem.vue';
import Sortable from "sortablejs";
export default {
  components:{ColorPickerParentRectItem,ColorPickerRectItem},
  data(){
    return {
      flagMove:false,
      newData:[],
      saveBeforeMove:[],
      sortable: '',
      dialogVisible: false,
      dialogEditVisible:false,
      dialogMoveVisible:false,
      moveCategory:false,
      moveColorList:[],
      spanIndex:[],
      parentId:0,
      operateId:'',
      typeName:'',
      code:'',
      colorTypeAll: [{
        id:1,
        typeName:'green',
        code:'#123123',
      },{
        id:2,
        typeName:'grey',
        code:'#666',
      },{
        id:3,
        typeName:'dgreen',
        code:'#098987',
      }],
      colorCardByCategory:[],
    };
  },
  watch:{
  },
  methods:{
    tableToTop (row, index) {
    this.colorTypeAll.splice(0, 0, this.colorTypeAll.splice(index, 1)[0])
    // this.tableData.splice(index, 1)
    // this.tableData.unshift(row)
    this.newData = [].concat(this.colorTypeAll.map((item, index) => ({
    id: item.id,
    // sortNum: index + 1,
    })));

    },
    initSortable (disabled) {
    const el = document.querySelector('.label-parent');
    // const el = document.querySelector('.el-table__body-wrapper tbody')
    const sortable = new Sortable(el, {
    disabled: disabled,
    scroll: true,
    animation: 180,
    dragClass: "dragClass", //设置拖拽样式类名
    ghostClass: "ghostClass", //设置拖拽停靠样式类名
    chosenClass: "chosenClass",
    onEnd: (evt) => { // 监听拖动结束事件
    const currRow = this.colorTypeAll.splice(evt.oldIndex, 1)[0]
    this.colorTypeAll.splice(evt.newIndex, 0, currRow);
    // const newData = []
    this.colorTypeAll.forEach((item, index) => {
    this.newData[index] = {
    id: item.id,
    }
    })
    }
    })
    this.sortable = sortable
    },
    clickMerge () {
    this.parentId=-1;
    this.moveCategory=false;
    this.spanIndex=[];
    this.$notify({
    message: '可按住开始拖拽',
    type: 'success'
    });
    this.flagMove = true
    this.initSortable(false)
    },
    SaveClickMerge () {
    if (this.newData.length == 0) {
    // this.$message.warning('还没有拖拽任何信息哦！')
    this.$notify({
    message: '还没有拖拽任何信息哦！',
    type: 'warning'
    });
    return
    } else {
      sortColorType({
        designSystemColorTypeList:this.newData,
      }).then(res=>{
        if(res.code=='200'){
        this.$notify({
        message: '批量拖拽保存成功',
        type: 'success'
        });
        selectColorType().then(res=>{
          if(res.code=='200'){
          this.colorTypeAll=res.data;
          }
        })
        this.sortable.destroy();
        this.flagMove = false;
        this.newData = [];
        }
      })
    }
    },
    cancel () {
    this.flagMove = false;
    this.sortable.destroy();
    this.newData = [];
    selectColorType().then(res=>{
    if(res.code=='200'){
    this.colorTypeAll=res.data;
    }
    })
    },
    addTypeNameSubmit(){
      if(!this.typeName.trim()||!this.code.trim()){
         this.$message.error('请填写完全！');
      }else{
      editColorType({
        typeName:this.typeName,
        code:this.code
      }).then(res=>{
        if(res.code=='200'){
          selectColorType().then(res=>{
          if(res.code=='200'){
            this.colorTypeAll=res.data;
          }
      });
        }
      })
      this.dialogVisible = false;
      this.typeName='';
      this.code='';
      }
    },
    editTypeNameSubmit(){
      if(!this.typeName.trim()||!this.code.trim()){
         this.$message.error('请填写完全！');
      }else{
      editColorType({
        id:this.operateId,
        typeName:this.typeName,
        code:this.code
      }).then(res=>{
        if(res.code=='200'){
          selectColorType().then(res=>{
        if(res.code=='200'){
          this.colorTypeAll=res.data;
        }
      });
        }
      })
      this.dialogEditVisible = false;
      this.typeName='';
      }
    },
    deleteColor(id){
      this.$confirm('确定删除此色卡分类?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteColorType({
            typeId:id,
          }).then(res=>{
            if(res.code=='200'){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              selectColorType().then(res=>{
              if(res.code=='200'){
                this.colorTypeAll=res.data;
              }
            });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    editColor({id,typeName,code}){
      this.operateId=id;
      this.typeName=typeName;
      this.code=code;
      this.dialogEditVisible=true;
    },
    closeDialog(){
      this.typeName="";
      this.code="";
    },
    handleMove(){
      this.flagMove = false;
      this.newData=[];
      this.moveCategory=true;
      this.$notify.info({
          title: '消息',
          message: '请选中要移动的色卡！',
          duration:'1500'
        });
    },
    selectFinishBeforeRequest(){
      if(this.moveColorList.length==0){
        this.$notify({
        message: '未选中任何色卡!',
        type: 'warning',
        duration:'1500'
      });
        this.dialogMoveVisible=false;
      }else{
        this.dialogMoveVisible=true;
      }
      this.operateId='';
    },
    moveCategorySubmit(){
      let parentId=this.operateId;
      batchUpdateColorCardType({
        typeId:this.operateId,
        designSystemColorCardList:this.moveColorList,
      }).then(res=>{
        if(res.code=='200'){
          this.$message({
          message: '批量修改成功!',
          type: 'success'
        });
        this.parentId=parentId;
        this.moveColorList=[];
        selectColorCard({
          typeId:this.parentId,
        }).then(res=>{
          if(res.code=='200'){
            this.colorCardByCategory=res.data;  
          }
        })
        }
      })
      this.spanIndex=[];
      this.dialogMoveVisible=false;
      this.moveCategory=false;
      this.operateId='';
    },
    getSelectColorCard(id){
      this.parentId=id;
      selectColorCard({
        typeId:id,
      }).then(res=>{
        this.colorCardByCategory=res.data;
        this.moveColorList=[];
      })
    },
    addMove(colorItem){
      let addId=colorItem.id;
      if(this.moveCategory){
        let findIndex=this.moveColorList.findIndex(item => addId == item.id)
        if(findIndex==-1){
          this.moveColorList.push(colorItem);
        }else {
          this.moveColorList.splice(findIndex,1);
        }
      }
    }
    },
    mounted(){
      selectColorType().then(res=>{
        if(res.code=='200'){
          this.colorTypeAll=res.data;
          this.parentId=this.colorTypeAll[0].id;
          selectColorCard({
            typeId:this.parentId,
          }).then(res=>{
            if(res.code=='200'){
              this.colorCardByCategory=res.data;
            }
          });
            }
          });
      this.$bus.$on('deleteColor',this.deleteColor);
      this.$bus.$on('editColor',this.editColor);
      this.$bus.$on('getSelectColorCard',this.getSelectColorCard);
      this.$bus.$on('addMove',this.addMove);
    },
    beforeDestroy(){
      this.$bus.$off('deleteColor');
      this.$bus.$off('editColor');
      this.$bus.$off('getSelectColorCard');
      this.$bus.$off('addMove');

    }
};
</script>

<style scoped lang="scss">
.wrap {
  padding: 15px 20px;
  .itemClass {
    margin-right: 8px;
    margin-bottom: 6px;
  }
}
.top {
  width: 100%;
  height: 34px;
  h4 {
    float: left;
  }
  .button{
    float: right;
  }
}
.label-parent{
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap:wrap;
  
}
.label-children {
  overflow-y:scroll;
  width: 100%;
  height: 720px;
  padding: 10px 0 0 20px;
  display: flex;
  flex-wrap:wrap;
  align-content: flex-start;
  background-color: #F0F0F0;
  border: 1px solid #DBDBDB;

}
.colorCodeWrap {
  position: relative;
  padding: 10px;

  h3 {
    height: 48px;
    line-height: 48px;
    text-indent: 15px;
    color: #60666a;
    font-weight: bold;
    border-left: 3px #55a290 solid;
  }

  .el-row {
    width: 100%;
    overflow: auto;
    padding: 10px;

    .el-col {
      margin-bottom: 5px;

      .ellipse {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 10px;
        text-align: center;
        color: #fff;
      }
    }
  }
}
// 拖拽
::v-deep .dragClass {
background: rgba($color: #e1f1ff, $alpha: 0.5) !important;
}

// 停靠
::v-deep .ghostClass {
background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}

// 选择
::v-deep .chosenClass:hover > td {
background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}
.shade {
position: absolute;
top: 0;
z-index:3;
// background-color: #E4F2FF;
opacity: 0.7;
display: flex;
width: 112px;
height: 40px;
justify-content: flex-end;
align-items:center;
.dragIcon{
display:inline-block;
width: 30px;
height: 30px;
color: #000;
line-height: 30px;
 &.isBlack {
  b,i{
  color: #fff;
  }
 }
}
.dragItem{
width: 55%;
height: 65%;
margin-left:10px;
margin-top: 5px;
cursor: pointer;
font-size: 20px;
display: flex;
align-items: flex-end;
justify-content: right;
}
}
</style>