<template>
    <div>
        <div id="tool-option" v-show="showTextTool || showImgTool">
            <div class="text-tool" v-show="showTextTool">
                <template v-if="!isMb">
                    <div class="top" key="aa">
                        <el-select size="normal" class="fontSelect" :value="textProperty.fontFamily" @change="changeElementProperty($event, 'fontFamily')" :placeholder="lang.pleaseSelect">
                            <el-option v-for="item in fontList" :key="item" :label="item" :value="item" :style="{ fontFamily: item }"></el-option>
                        </el-select>
                        <el-select size="normal" class="fontSizeSelect" :value="textProperty.fontSize" @change="changeElementProperty($event, 'fontSize')" :placeholder="lang.pleaseSelect">
                            <el-option label="30" :value="30"></el-option>
                            <el-option label="40" :value="40"></el-option>
                            <el-option label="50" :value="50"></el-option>
                            <el-option label="60" :value="60"></el-option>
                            <el-option label="70" :value="70"></el-option>
                            <el-option label="80" :value="80"></el-option>
                            <el-option label="90" :value="90"></el-option>
                            <el-option label="100" :value="100"></el-option>
                        </el-select>
                        <div class="text-icon" :class="{ active: textProperty.fontWeight === 'bold' }" @click="changeElementProperty(textProperty.fontWeight === 'normal' || textProperty.fontWeight === 400 ? 'bold' : 'normal', 'fontWeight')">
                            <b class="icon-a-icon-Bzhuanhuan"></b>
                        </div>
                        <div class="text-icon" :class="{ active: textProperty.fontStyle === 'italic' }" @click="changeElementProperty(textProperty.fontStyle === 'normal' || !textProperty.fontStyle ? 'italic' : 'normal', 'fontStyle')">
                            <b class="icon-a-icon-izhuanhuan"></b>
                        </div>
                        <div class="text-icon" :class="{ active: textProperty.underline }" @click="changeElementProperty(!textProperty.underline, 'underline')">
                            <b class="icon-a-icon-uzhuanhuan"></b>
                        </div>
                        <el-popover popper-class="curvedTextPopover" placement="bottom" width="200" trigger="click">
                            <div class="text-icon" slot="reference">
                                <b class="icon-a-icon-bxzhuanhuan"></b>
                            </div>
                            <div>
                                <div style="margin-bottom: 10px">
                                    <label style="margin-right: 4px">{{ lang.filp }}</label>
                                    <el-switch :value="textProperty.flipped" @change="changeElementProperty($event, 'flipped')"></el-switch>
                                </div>
                                <div>
                                    <label>{{ lang.textCurve }}</label>
                                    <el-slider class="flex-1" :show-tooltip="false" v-model="textProperty.radian" :min="100" :max="2000"
                                               @input="changeElementProperty($event, 'radian')"></el-slider>
                                </div>
                            </div>
                        </el-popover>
                        <el-popover popper-class="color-popover text-color-popover" placement="bottom-end" trigger="click">
                            <div class="text-icon color-icon" slot="reference">
                                <b class="icon-font-colors"></b>
                                <b class="icon-down"></b>
                            </div>
                            <div class="colorWrap" scrollbar>
                                <ColorPickerItem :colorItem="item" :isActive="item.code === textProperty.fill" v-for="(item, index) in colorList" :key="index" @changeColor="changeElementProperty($event.code, 'fill', 'textColor', $event)"></ColorPickerItem>
                            </div>
                        </el-popover>
                    </div>
                    <div class="bottom">
                        <label>
                            <textarea :value="textProperty.text" rows="2" placeholder="Enter text here" @input="changeTextVal($event.target.value)"></textarea>
                        </label>
                    </div>
                </template>
                <template v-else>
                    <div class="top" key="bb">
                        <el-select size="small" class="fontSelect" :value="textProperty.fontFamily" @change="changeElementProperty($event, 'fontFamily')" :placeholder="lang.pleaseSelect">
                            <el-option v-for="item in fontList" :key="item" :label="item" :value="item" :style="{ fontFamily: item }"></el-option>
                        </el-select>
                        <el-select size="small" class="fontSizeSelect" :value="textProperty.fontSize" @change="changeElementProperty($event, 'fontSize')" :placeholder="lang.pleaseSelect">
                            <el-option label="30" :value="30"></el-option>
                            <el-option label="40" :value="40"></el-option>
                            <el-option label="50" :value="50"></el-option>
                            <el-option label="60" :value="60"></el-option>
                            <el-option label="70" :value="70"></el-option>
                            <el-option label="80" :value="80"></el-option>
                            <el-option label="90" :value="90"></el-option>
                            <el-option label="100" :value="100"></el-option>
                        </el-select>
                        <div class="copy-icon" @click="copyElement">
                            <b class="icon-a-icon-Copyzhuanhuan"></b>
                            <span>{{ lang.copy }}</span>
                        </div>
                    </div>
                    <div class="bottom">
                        <el-button size="small" @click="editTextDrawer = true">{{
                                lang.editText
                            }}<b class="icon-edit"></b></el-button>
                        <div class="text-icon" :class="{ active: textProperty.fontWeight === 'bold' }" @click="changeElementProperty(textProperty.fontWeight === 'normal' || textProperty.fontWeight === 400 ? 'bold' : 'normal', 'fontWeight')">
                            <b class="icon-a-icon-Bzhuanhuan"></b>
                        </div>
                        <div class="text-icon" :class="{ active: textProperty.fontStyle === 'italic' }" @click="changeElementProperty(textProperty.fontStyle === 'normal' || !textProperty.fontStyle ? 'italic' : 'normal', 'fontStyle')">
                            <b class="icon-a-icon-izhuanhuan"></b>
                        </div>
                        <div class="text-icon" :class="{ active: textProperty.underline }" @click="changeElementProperty(!textProperty.underline, 'underline')">
                            <b class="icon-a-icon-uzhuanhuan"></b>
                        </div>
                        <el-popover popper-class="curvedTextPopover" placement="bottom" width="200" trigger="click">
                            <div class="text-icon" slot="reference">
                                <b class="icon-a-icon-bxzhuanhuan"></b>
                            </div>
                            <div>
                                <div style="margin-bottom: 10px">
                                    <label style="margin-right: 4px">{{ lang.filp }}</label>
                                    <el-switch :value="textProperty.flipped" @change="changeElementProperty($event, 'flipped')"></el-switch>
                                </div>
                                <div>
                                    <label>{{ lang.textCurve }}</label>
                                    <el-slider class="flex-1" :show-tooltip="false" :value="textProperty.radian" :min="100" :max="2000" @input="changeElementProperty($event, 'radian')"></el-slider>
                                </div>
                            </div>
                        </el-popover>
                        <el-popover popper-class="color-popover" placement="bottom-end" trigger="click">
                            <div class="text-icon color-icon" slot="reference">
                                <b class="icon-font-colors"></b>
                                <b class="icon-down"></b>
                            </div>
                            <div class="colorWrap" scrollbar>
                                <ColorPickerItem :colorItem="item" :isActive="item.code === textProperty.fill" v-for="(item, index) in colorList" :key="index" @changeColor="changeElementProperty($event.code, 'fill')"></ColorPickerItem>
                            </div>
                        </el-popover>
                    </div>
                </template>
            </div>
            <!-- 修改颜色 -->
            <div class="img-tool" v-show="showImgTool">
                <div class="image-icon" @click="showReplaceDialog" title="Replace">
                    <b class="icon-a-icon-Replacezhuanhuan"></b>
                    <span>{{ lang.replace2 }}</span>
                </div>
                <div class="image-icon" @click="copyElement" title="Copy">
                    <b class="icon-a-icon-Copyzhuanhuan"></b>
                    <span>{{ lang.copy2 }}</span>
                </div>
                <div class="image-icon" @click="mirrorElement" v-show="!isMb" title="Flip">
                    <b class="icon-a-icon-Mirrorzhuanhuan"></b>
                    <span>{{ lang.mirror }}</span>
                </div>
                <div class="image-icon" @click="centerElement" v-show="!isMb" title="Align Center">
                    <b class="icon-a-icon-centerzhuanhuan"></b>
                    <span>{{ lang.center }}</span>
                </div>
                <div class="image-icon" title="Adjust Clipart" @click="uploadOnChange">
                    <b class="icon-caijian"></b>
                    <span>{{ lang.cut }}</span>
                </div>
                <el-popover popper-class="popoverClass" placement="right" trigger="manual" v-model="showImgColorPopover">
                    <div class="image-icon" slot="reference" @click="changeImgColorIconClick" title="Edit Color">
                        <svg t="1671066504971" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="20178" width="200" height="200">
                            <path d="M511.999665 0.00034a511.999665 511.999665 0 0 0-255.999832 69.119955l128.512915 222.207854A252.773835 252.773835 0 0 1 511.999665 256.000172z" fill="#F06292" p-id="20179"></path>
                            <path d="M511.999665 512.000005V256.000172a252.773835 252.773835 0 0 0-127.486917 35.327977z" fill="#E91E63" p-id="20180"></path>
                            <path d="M384.512748 291.328149L256.001832 69.120295A515.787663 515.787663 0 0 0 69.121955 256.000172l222.206854 128.511916a255.999833 255.999833 0 0 1 93.183939-93.183939z" fill="#BA68C8" p-id="20181"></path>
                            <path d="M511.999665 512.000005L384.513748 291.328149a255.999833 255.999833 0 0 0-93.182939 93.183939z" fill="#9C27B0" p-id="20182"></path>
                            <path d="M639.489582 291.328149L767.999498 69.120295A506.469669 506.469669 0 0 0 511.999665 0.00034v255.999832a252.773835 252.773835 0 0 1 127.487917 35.327977z" fill="#E57373" p-id="20183"></path>
                            <path d="M639.489582 291.328149A252.773835 252.773835 0 0 0 511.999665 256.000172v255.999833l127.489917-220.671856z" fill="#F44336" p-id="20184"></path>
                            <path d="M767.999498 512.000005a252.773835 252.773835 0 0 1-35.326977 127.487917L954.879375 767.999838A511.999665 511.999665 0 0 0 1023.99933 512.000005z" fill="#FFD54F" p-id="20185"></path>
                            <path d="M767.999498 512.000005H511.999665l220.672856 127.487917A252.773835 252.773835 0 0 0 767.999498 512.000005z" fill="#FFC107" p-id="20186"></path>
                            <path d="M732.672521 384.512088L954.879375 256.000172A511.999665 511.999665 0 0 0 767.999498 69.120295L639.489582 291.328149a255.999833 255.999833 0 0 1 93.183939 93.183939z" fill="#FF8A65" p-id="20187"></path>
                            <path d="M732.672521 384.512088a255.999833 255.999833 0 0 0-93.183939-93.183939L511.999665 512.000005l220.671856-127.487917z" fill="#FF5722" p-id="20188"></path>
                            <path d="M69.121955 256.000172A503.80767 503.80767 0 0 0 0.002 512.000005h255.999832a252.773835 252.773835 0 0 1 35.327977-127.487917z" fill="#9575CD" p-id="20189"></path>
                            <path d="M291.329809 384.512088A252.773835 252.773835 0 0 0 255.999833 512.000005h255.999832z" fill="#673AB7" p-id="20190"></path>
                            <path d="M1023.99933 512.000005a511.999665 511.999665 0 0 0-69.119955-255.999833L732.672521 384.512088A251.749835 251.749835 0 0 1 767.999498 512.000005z" fill="#FFB74D" p-id="20191"></path>
                            <path d="M767.999498 512.000005a252.773835 252.773835 0 0 0-35.326977-127.487917L511.999665 512.000005z" fill="#FF9800" p-id="20192"></path>
                            <path d="M291.329809 639.487922L69.119955 767.999838a511.999665 511.999665 0 0 0 186.879878 186.879877l128.509915-222.207854a255.999833 255.999833 0 0 1-93.181939-93.183939z" fill="#4FC3F7" p-id="20193"></path>
                            <path d="M291.329809 639.487922a255.999833 255.999833 0 0 0 93.182939 93.183939L511.999665 512.000005z" fill="#03A9F4" p-id="20194"></path>
                            <path d="M732.672521 639.487922a255.999833 255.999833 0 0 1-93.183939 93.183939L767.999498 954.879715A511.999665 511.999665 0 0 0 954.879375 767.999838z" fill="#FFF176" p-id="20195"></path>
                            <path d="M732.672521 639.487922L511.999665 512.000005 639.489582 732.671861a255.999833 255.999833 0 0 0 93.183939-93.183939z" fill="#FFEB3B" p-id="20196"></path>
                            <path d="M511.999665 767.999838v255.999832a506.469669 506.469669 0 0 0 255.999833-69.119955L639.489582 732.671861A252.773835 252.773835 0 0 1 511.999665 767.999838z" fill="#AED581" p-id="20197"></path>
                            <path d="M639.489582 732.671861L511.999665 512.000005v255.999833a252.773835 252.773835 0 0 0 127.487917-35.327977z" fill="#8BC34A" p-id="20198"></path>
                            <path d="M256.001832 954.879715a511.999665 511.999665 0 0 0 255.999833 69.119955V767.999838a252.773835 252.773835 0 0 1-127.489917-35.327977z" fill="#81C784" p-id="20199"></path>
                            <path d="M511.999665 512.000005L384.513748 732.671861A252.773835 252.773835 0 0 0 511.999665 767.999838V512.000005z" fill="#4CAF50" p-id="20200"></path>
                            <path d="M0.002 512.000005a511.999665 511.999665 0 0 0 69.119955 255.999833l222.206854-128.511916A252.773835 252.773835 0 0 1 256.001832 512.000005z" fill="#7986CB" p-id="20201"></path>
                            <path d="M511.999665 512.000005H255.999833a252.773835 252.773835 0 0 0 35.329976 127.487917z" fill="#3F51B5" p-id="20202"></path>
                        </svg>
                        <span>{{ lang.color }}</span>
                    </div>
                    <div style="width:510px;">
                        <div class="editColor-colorPicker">
                            <div class="editColor-colorPicker-title">
                                <span>Choose Which Color to Change</span>
                                <div>
                                    <span>Single Color</span>
                                    <el-switch :value="imageProperty.singleColor" @change="changeSingleColor">
                                    </el-switch>
                                </div>
                            </div>
                            <div class="editColor-colorPicker-selected" scrollBar>
                                <dSColorPickerRectItem :colorItem="{ code: item.color }" :isActive="item.color === selectedImgColor" :showHook="true" v-for="item in imageProperty.singleColor ? imageProperty.singleColorList : selectedImgColorList" :key="item.color" @changeColor="selectImgColor(item.color)">
                                </dSColorPickerRectItem>
                            </div>
                            <div class="parentAndChildPanel" v-show="selectedImgColor">
                                <div class="editColor-colorPicker-title" style="position: absolute;left:0;height:22px;">
                                    Choose the Replaced Color
                                </div>
                                <div v-show="selectedImgColor" style="position: absolute;right:0;" class="moreClass">
                                    <a @click="colorShowList=colorList;showMoreColorsDialog = true;">More Colors</a>
                                </div>
                                <!-- moreColors弹窗 -->
                                <div class="moreColorsDialog" v-show="showMoreColorsDialog" @click="closeDialog($event)" ref="moreColorsDialog">
                                    <div class="dialog">
                                        <div class="title">
                                            <span>More Colors</span>
                                            <b class="icon-guanbi" style="cursor: pointer;" @click="closeMoreColorDialog"></b>
                                        </div>
                                        <div class="searchInput" ref="searchInput">
											<span class="suffix-inner">
												<b v-if="inputColorValue==''" class="icon-a-uspp-sousuozhuanhuan"></b>
												<b v-else class="icon-guanbi" @click="inputColorValue='';selectedColorCategoryId='';colorShowList=colorList;showOops=false;showChild=true;"
                                                ></b>
											</span>
                                            <input type="text" class="inputSearch" placeholder="Search Pantone Color" v-model="inputColorValue" @input="debouncedColorValue()">
                                        </div>
                                        <div class="colorCardSection">
                                            <div v-show="!showOops" :key="keyRefresh" class="editColor-colorPicker-colors" scrollBar>
                                                <dSColorPickerRectItem :colorItem="item" :showHook="false" :isActive="item.code === selectedImgReplaceColor" v-for="(item, index) in colorShowList" :key="index" @changeColor="selectImgReplaceColor($event.code, $event)">
                                                </dSColorPickerRectItem>
                                            </div>
                                            <!-- 搜索为空展示oops -->
                                            <div v-show="showOops" class="showOops">
                                                <div class="imgWrapOops">
                                                    <img src="../../assets/image/noresults.png" alt="">
                                                </div>
                                                <div class="tip">Sorry, no results, please enter another Pantone color
                                                    number!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="editColor-colorPicker-colorContainer" v-show="selectedImgColor">
                                <!-- 子级颜色 -->
                                <div v-show="selectedImgColor" class="editColor-colorPicker-colors childrenPanel" scrollBar>
                                    <dSColorPickerRectItem :colorItem="item" :showHook="false" :isActive="item.code === selectedImgReplaceColor" v-for="(item, index) in colorList" :key="index" @changeColor="selectImgReplaceColor($event.code, $event)"></dSColorPickerRectItem>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>
        <!-- 手机端修改文字 -->
        <el-drawer :visible.sync="editTextDrawer" custom-class="addTextDrawerWrap drawerWrap" :with-header="false" direction="btt" size="150px">
            <div class="drawerContent">
                <div class="header twoIcon">
                    <div class="close-icon extend" @click="editTextDrawer = false">
                        <b class="icon-a-icon-qxzhuanhuan"></b>
                    </div>
                    <div>{{ lang.editText }}</div>
                    <div
                            class="yes-icon extend"
                            @click="
							changeTextVal(textProperty.text);
							editTextDrawer = false;
						"
                    >
                        <b class="icon-yes"></b>
                    </div>
                </div>
                <div class="main">
                    <label class="textLabel">
                        <textarea v-model="textProperty.text" rows="2" :placeholder="lang.enterHere"></textarea>
                    </label>
                </div>
            </div>
        </el-drawer>
        <el-drawer :visible.sync="imgToolDialog" :with-header="false" direction="btt" custom-class="addImgDrawerWrap drawerWrap" append-to-body @close="cancelChangeImgColorPopover">
            <div class="colorHead">
                <b class="icon-close icon-item" @click.stop="cancelChangeImgColorPopover"></b>
                <div class="colorTitle">{{ lang.imageColor }}</div>
                <b class="icon-yes icon-item" @click="confirmChangeImgColorPopover"></b>
            </div>
            <div class="singleColor">
                <span>{{ lang.singleColor }}</span>
                <label class="switch" for="myCheckBox">
                    <input id="myCheckBox" class="myCheckBox" type="checkbox" :checked="imageProperty.singleColor" @change="mbchangesingleColor"/>
                    <div class="slider"></div>
                </label>
            </div>
            <div class="chooseColor">
                <div class="artImg">
                    <img :src="artImg" loading="lazy"/>
                </div>
                <div class="chooseColorText">
                    <div class="chooseText">
                        {{ lang.chooseColor }}
                    </div>
                    <div class="editColor-colorPicker-selected" scrollBar>
                        <ColorPickerItem :colorItem="{ code: item.color }" :isActive="item.color === selectedImgColor" v-for="item in imageProperty.singleColor ? imageProperty.singleColorList : selectedImgColorList" :key="item.color" @changeColor="selectImgColor(item.color)"></ColorPickerItem>
                    </div>
                </div>
            </div>
            <div class="replaceColor" v-show="selectedImgColor.length != 0">
                <div class="editColor-title">{{ lang.chooseReplacedColor }}</div>
                <div class="editColor-colorPicker-colorContainer">
                    <div class="editColor-colorPicker-colors" scrollBar>
                        <ColorPickerItem :colorItem="item" :isActive="item.code === selectedImgReplaceColor" v-for="(item, index) in colorList" :key="index" @changeColor="selectImgReplaceColor($event.code)"></ColorPickerItem>
                    </div>
                </div>
            </div>
        </el-drawer>
        <base-dialog v-model="cropperDialog" v-if="device !== 'mb'" width="50%">
            <div class="title">Crop Area</div>
            <div class="cropper-icon">
                <el-button @click="changeScale(1)"><b class="icon-a-tgsc-addzhuanhuan"></b></el-button>
                <el-button @click="changeScale(-1)"><b class="icon-a-tgsc-jzhuanhuan"></b></el-button>
            </div>
            <div class="cropper">
                <vue-cropper ref="cropper" :img="imageSrc" :output-width="300" :output-height="300" :output-type="'png'" :guides="true" :viewMode="1" :dragMode="'move'" :background="false" :rotatable="true" :scalable="true" :zoomable="true" :cropBoxMovable="true" :cropBoxResizable="true" :aspect-ratio="2" :auto-crop="true"></vue-cropper>
            </div>
            <div class="cropper-btn">
                <el-button @click="cropperDialog = false">Cancel</el-button>
                <el-button type="primary" @click="cropImage">Continue</el-button>
            </div>
        </base-dialog>
    </div>
</template>
<script>
import {VueCropper} from 'vue-cropper'
import ColorPickerItem from "@/components/MyDzxt/ColorPickerItem.vue";
import dzMixin from "@/mixins/dzMixin";
import dSColorPickerRectItem from "@/components/MyDzxt/dSColorPickerRectItem.vue";
import {changeImgColor, debounce, colorRgb, dataURLtoFile, urlAddVersion} from "@/utils/utils";
import {uploadFile} from "@/utils/oss";
import {getColorCardByColorName} from "@/api/newDzxt";
import BaseDialog from "@/components/Quote/BaseDialog.vue";

export default {
    mixins: [dzMixin],
    data() {
        return {
            inputColorValue: '',
            showChild: false,
            showOops: false,
            keyRefresh: 0,
            colorShowList: [],
            colorCardList: [],//子级色卡数组
            debouncedColorValue: null,
            cropperDialog: false,
            showMoreColorsDialog: false,
            imageSrc: "",
            showImgColorPopover: false,
            editTextDrawer: false,
            selectedImgColor: "",
            selectedImgReplaceColor: "",
            showTextTool: false,
            showImgTool: false,
            imgToolDialog: false,
            artImg: "",
        };
    },
    components: {ColorPickerItem, BaseDialog, VueCropper, dSColorPickerRectItem},
    computed: {
        textProperty() {
            return this.canvas.textProperty;
        },
        imageProperty() {
            return this.canvas.imageProperty;
        },
        lang() {
            return this.$store.getters.lang.design;
        },
        colorList() {
            return this.$store.state.design.colorList;
        },
        fontList() {
            return this.$store.getters["design/fontList"];
        },
        selectedImgColorList() {
            return this.canvas.selectedImgColorList;
        },
        isMb() {
            return this.$store.state.device === "mb";
        },
    },
    methods: {
        closeMoreColorDialog() {
            this.showMoreColorsDialog = false;
            this.colorShowList = this.colorList;
            this.showOops = false;
            this.inputColorValue = '';
        },
        //图片放大缩小
        changeScale(num) {
            num = num || 1
            this.$refs.cropper.changeScale(num)
        },
        uploadOnChange() {
            let activeObject = this.canvas.c.getActiveObject();
            this.imageSrc = activeObject.getSrc();
            this.cropperDialog = true;
        },
        beforeUpload(file) {
            const isLt10M = file.size / 1024 / 1024 < 10;
            const isUploadType = /\.(gif|jpg|jpeg|png|svg|psd|pdf|bmp|ai|)$/.test(file.name.toLowerCase());
            if (!isLt10M) {
                this.$message.error("The size of the uploaded profile picture should not exceed 2MB!");
                return isLt10M;
            }
            if (!isUploadType) {
                this.$message.error("Error uploading file type, Accepted File Types (JPG、JPEG、PNG、SVG、GIF、BMP、PDF、PSD、AI)");
                return isUploadType;
            }
            return true;
        },
        uploadFileToDesign(file) {
            uploadFile(file,{
                systemName:'customOss'
            })
                    .then((res) => {
                        this.replaceImg({src: res, property: 0});
                    })
        },
        async replaceImg({src, property}) {
            try {
                this.$store.commit("design/set_loading", true);
                let selectedEle = await this.canvas.replaceImg(src, {artId: property});
                this.$Bus.$emit("triggerActiveItem", selectedEle);
                if (!this.isMb) {
                    await this.canvas.analyzeImgColor(src);
                }
                this.$Bus.$emit("triggerUpdateLocation");
                this.canvas.saveStateToHistory();
                this.$store.commit("design/set_loading", false);
                this.cropperDialog = false;
            } catch (e) {
                this.$store.commit("design/set_loading", false);
            }
        },
        cropImage() {
            this.$store.commit("design/set_loading", true);
            this.$refs.cropper.getCropData(async data => {
                let file = dataURLtoFile(data, 1);
                this.uploadFileToDesign(file)
                this.$store.commit("design/set_loading", false);
            })
            this.cropperDialog = false;
        },
        changeSingleColor(val) {
            this.changeElementProperty(val, "singleColor");
            if (val) {
                let color = this.selectedImgColorList[0].color;
                if (this.imageProperty.singleColorList.length == 0) this.changeElementProperty([{color: color}], "singleColorList");
                let filter = new fabric.Image.filters.BlendColor({
                    color: this.imageProperty.singleColorList[0].color,
                    mode: "tint",
                    alpha: 0.8,
                });
                this.canvas.applyFilter(0, filter);
            } else {
                let activeObj = this.canvas.c.getActiveObject();
                if (!activeObj) {
                    return false;
                }
                this.canvas.applyFilter(0, null);
            }
        },
        mbchangesingleColor(e) {
            let state = e.target.checked;
            this.changeSingleColor(state);
        },
        selectImgColor(color) {
            this.selectedImgColor = color;
            this.$Bus.$emit('synchroSelectedImgColorLeft', color);
        },
        selectImgReplaceColor(color, colorFullMsg) {
            if (this.showMoreColorsDialog) this.showMoreColorsDialog = false;
            if (this.selectedImgReplaceColor == color) return;
            this.selectedImgReplaceColor = color;
            let result = this.confirmChangeImgColorPopover();
            if (result === false) return;
            if (this.historyPicColorRecords && this.historyPicColorRecords.length == 8) {
                this.historyPicColorRecords.splice(7, 1);
            }
            if (this.historyPicColorRecords) {
                let index = this.historyPicColorRecords.findIndex(item => item.code == color);
                if (index != -1) {
                    this.historyPicColorRecords.splice(index, 1);
                }
            }
            let selectedColorNow = colorFullMsg.pantone;
            if (this.historyPicColorRecords) {
                this.historyPicColorRecords.unshift(colorFullMsg);
            }
            this.$Bus.$emit('getHistoryPicColorRecords', this.historyPicColorRecords, selectedColorNow);
            localStorage.setItem('historyPicColorRecords', JSON.stringify(this.historyPicColorRecords));
        },
        showReplaceDialog() {
            this.$store.commit("design/set_Replace", true);
        },
        mirrorElement() {
            this.canvas.mirrorElement();
        },
        centerElement() {
            this.canvas.position("center");
            this.locationToolBar();
        },
        changeImgColorIconClick() {
            if (this.isMb) {
                this.artImg = this.canvas.getArtImg();
                this.imgToolDialog = !this.imgToolDialog;
            } else {
                this.showImgColorPopover = !this.showImgColorPopover;
            }
        },
        cancelChangeImgColorPopover() {
            this.selectedImgColor = "";
            this.selectedImgReplaceColor = "";
            this.showImgColorPopover = false;
            this.imgToolDialog = false;
        },
        confirmChangeImgColorPopover() {
            this.confirmChangeImgColor(this.selectedElement, this.canvas.c);
            this.showImgColorPopover = false;
            this.imgToolDialog = false;
        },
        loadImage(url) {
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = urlAddVersion(url);
                img.crossOrigin = "Anonymous";
            });
        },
        confirmChangeImgColor() {
            if (!this.selectedImgColor || !this.selectedImgReplaceColor) {
                this.$message.info('Please select the color you want to replace');
                return false;
            }
            if (this.imageProperty.singleColor) {
                this.changeElementProperty([{color: this.selectedImgReplaceColor}], "singleColorList");
                this.selectedImgColor = this.selectedImgReplaceColor;
                let filter = new fabric.Image.filters.BlendColor({
                    color: this.selectedImgReplaceColor,
                    mode: "tint",
                    alpha: 0.8,
                });
                this.canvas.applyFilter(0, filter);
                return;
            }
            let ele = this.canvas.c.getActiveObject();
            let url = ele.getSrc();
            changeImgColor(
                    url,
                    {
                        oldColor: this.selectedImgColor,
                        newColor: this.selectedImgReplaceColor,
                    },
                    (base64) => {
                        let file = dataURLtoFile(base64, 1);
                        uploadFile(file,{
                            systemName:'customOss'
                        }).then(async (url) => {
                            let img = await this.loadImage(url);
                            ele.setElement(img);
                            this.selectedImgColor = colorRgb(this.selectedImgReplaceColor);
                            this.$Bus.$emit('synchroSelectedImgColorLeft', this.selectedImgColor);//同步颜色
                            this.canvas.analyzeImgColor(url);
                            this.canvas.c.requestRenderAll();
                        });
                    }
            );
        },
        changeTextVal(val) {
            console.log(val)
            let activeEle = this.canvas.c.getActiveObject();
            activeEle.set("text", val);
            this.canvas.c.requestRenderAll();
            this.$nextTick(() => {
                this.locationToolBar();
            });
        },
        changeElementProperty(val, property, flag, colorFullMsg) {
            this.canvas.changeElementProperty(val, property);
            this.canvas.getObjectAttr();
            if (flag == 'textColor') {
                if (this.historyTextColorRecords && this.historyTextColorRecords.length == 8) {
                    this.historyTextColorRecords.splice(7, 1);
                }
                if (this.historyTextColorRecords) {
                    let index = this.historyTextColorRecords.findIndex(item => item.code == val);
                    if (index != -1) {
                        this.historyTextColorRecords.splice(index, 1);
                    }
                }
                if (this.historyTextColorRecords) {
                    this.historyTextColorRecords.unshift(colorFullMsg);
                }
                localStorage.setItem('historyTextColorRecords', JSON.stringify(this.historyTextColorRecords));
                this.$Bus.$emit('getHistoryTextColorRecords', this.historyTextColorRecords);
            }
        },
        copyElement() {
            this.canvas.clone();
        },
        isCustomType(type) {
            return type === "text" || type === "i-text" || type === "curved-text" || type === "image" || type === "textbox";
        },
        locationToolBar() {
            let obj = this.canvas.c.getActiveObject();
            if (!obj) {
                return false;
            }
            let tool = document.getElementById("tool-option"),
                    designBody = document.getElementById("designBody"),
                    box1 = tool.getBoundingClientRect(),
                    box2 = designBody.getBoundingClientRect();
            let bound = obj.getBoundingRect();
            let absCoords = this.canvas.c.getAbsoluteCoords(obj);
            let left = absCoords.left - (box1.width - bound.width) / 2;
            let top = absCoords.top + 20;
            if (left < box2.left) {
                left = box2.left;
            } else {
                if (left > box2.width + box2.left - box1.width) {
                    left = box2.width + box2.left - box1.width;
                }
            }
            tool.style.left = left + "px";
            tool.style.top = top + "px";
        },
        // getObjectAttr(item) {
        // 	const activeObject = this.canvas.c.getActiveObject();
        // 	if (!activeObject) {
        // 		return;
        // 	}
        // 	// 不是当前obj，跳过
        // 	if (item && item !== activeObject) return;
        // 	const textTypes = ["i-text", "text", "textbox", "curved-text"],
        // 		imgTypes = ["image"];
        // 	if (textTypes.includes(activeObject.type)) {
        // 		let obj = {};
        // 		obj.type = activeObject.type;
        // 		obj.text = activeObject.get("text");
        // 		obj.textAlign = activeObject.get("textAlign");
        // 		obj.angle = Math.round(activeObject.get("angle"));
        // 		obj.scale = Number(Number(activeObject.get("scaleX")).toFixed(2));
        // 		obj.strokeWidth = activeObject.get("strokeWidth");
        // 		obj.charSpacing = activeObject.get("charSpacing");
        // 		obj.lineHeight = activeObject.get("lineHeight");
        // 		obj.fill = activeObject.get("fill");
        // 		obj.stroke = activeObject.get("stroke");
        // 		obj.fontSize = activeObject.get("fontSize");
        // 		obj.fontWeight = activeObject.get("fontWeight");
        // 		obj.fontStyle = activeObject.get("fontStyle");
        // 		obj.underline = activeObject.get("underline");
        // 		obj.fontFamily = activeObject.get("fontFamily");
        // 		if (activeObject.type === "curved-text") {
        // 			obj.flipped = activeObject.get("flipped");
        // 			obj.radian = activeObject.get("radian");
        // 		}
        // 		this.textProperty = Object.assign({}, this.textProperty, obj);
        // 	}
        // 	if (imgTypes.includes(activeObject.type)) {
        // 		let obj = {};
        // 		obj.IsMain = Number(activeObject.IsMain) || 0;
        // 		obj.angle = Math.round(activeObject.get("angle"));
        // 		obj.scale = Number(activeObject.get("scaleX").toFixed(3));
        // 		obj.picPath = activeObject.getSrc();
        // 		obj.singleColor=activeObject.get('singleColor')||false;
        // 		obj.singleColorList=activeObject.get('singleColorList')||[];
        // 		this.imageProperty = obj;
        // 	}
        // },
        handleResize() {
            this.locationToolBar();
        },
        hideTools() {
            this.showTextTool = false;
            this.showImgTool = false;
            this.showImgColorPopover = false;
            this.selectedImgColor = "";
            this.selectedImgReplaceColor = "";
        },
        closeDialog(e) {
            let moreColorsDialog = this.$refs.moreColorsDialog;
            if (e.target === moreColorsDialog) {
                this.closeMoreColorDialog();
            }
        },
        //色卡模糊查询
        getColorCardByColorNameByRequest() {
            if (this.inputColorValue.trim() == '') {
                this.colorShowList = this.colorList;
                this.showOops = false;
                return;
            }
            getColorCardByColorName({
                colorName: this.inputColorValue
            }).then(res => {
                if (res.code == '200') {
                    if (res.data.length == 0) {
                        this.showOops = true;
                    } else {
                        this.showOops = false;
                    }
                    this.colorShowList = res.data;
                    this.keyRefresh++;
                } else if (res.code == '500') {
                    this.showOops = true;
                }
            })
        },
        getHistoryPicColorRecordsToToolTips(arr) {
            this.historyPicColorRecords = arr;
        },
        getHistoryTextColorRecordsToToolTips(arr) {
            this.historyTextColorRecords = arr;
        },
        synchroSelectedImgColor(color) {
            this.selectedImgColor = color;
        },
    },
    created() {
        this.debouncedColorValue = debounce(this.getColorCardByColorNameByRequest, 200);
    },
    mounted() {
        this.$Bus.$on("triggerUpdateLocation", this.locationToolBar);
        this.$Bus.$on("hideTools", this.hideTools);
        this.$Bus.$on("getHistoryPicColorRecordsToToolTips", this.getHistoryPicColorRecordsToToolTips);
        this.$Bus.$on("getHistoryTextColorRecordsToToolTips", this.getHistoryTextColorRecordsToToolTips);
        this.$Bus.$on('synchroSelectedImgColor', this.synchroSelectedImgColor);
        window.addEventListener("resize", this.handleResize);
        const textTypes = ["i-text", "text", "textbox", "curved-text"],
                imgTypes = ["image"];
        this.canvas.c.on({
            "object:added": (e) => {
                this.hideTools();
            },
            "object:moving": (e) => {
                window.requestAnimationFrame(() => {
                    let type = e.target.type;
                    if (this.isCustomType(type)) {
                        this.locationToolBar();
                    }
                });
            },
            "object:modified": (e) => {
                let type = e.target.type;
                this.canvas.getObjectAttr();
                if (this.isCustomType(type)) {
                    this.locationToolBar();
                }
            },
            "object:removed": (e) => {
                this.hideTools();
            },
            "mouse:down": (e) => {
                if (e.target && e.button === 1) {
                    const type = e.target.type;
                    this.canvas.getObjectAttr(e.target);
                    if (textTypes.includes(type)) {
                        this.showTextTool = true;
                        this.showImgTool = false;
                    }
                    if (imgTypes.includes(type)) {
                        this.showImgTool = true;
                        this.showTextTool = false;
                        this.artImg = this.canvas.getArtImg();
                        if (!this.isMb) {
                            let imgSrc = e.target.toDataURL({format: "png"});
                            this.canvas.analyzeImgColor(imgSrc);
                        }
                    }
                    if (!textTypes.includes(type) && !imgTypes.includes(type)) {
                        this.showTextTool = false;
                        this.showImgTool = false;
                    }
                    this.$nextTick(() => {
                        if (this.isCustomType(type)) {
                            this.locationToolBar();
                        }
                    });
                } else {
                    this.hideTools();
                }
            },
        });
        this.historyPicColorRecords = JSON.parse(localStorage.getItem('historyPicColorRecords'));
        this.historyTextColorRecords = JSON.parse(localStorage.getItem('historyTextColorRecords'));
    },
    beforeDestroy() {
        this.canvas.c.off();
        this.$Bus.$off("triggerUpdateLocation", this.locationToolBar);
        this.$Bus.$off("hideTools", this.hideTools);
        this.$Bus.$off("getHistoryPicColorRecordsToToolTips", this.getHistoryPicColorRecordsToToolTips);
        this.$Bus.$off("getHistoryTextColorRecordsToToolTips", this.getHistoryTextColorRecordsToToolTips);
        this.$Bus.$off('synchroSelectedImgColor', this.synchroSelectedImgColor);
        window.addEventListener("resize", this.handleResize);
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/dzxt_theme";

.editColor-colorPicker {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: Calibri;

    .editColor-colorPicker-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
    }

    .editColor-colorPicker-selected {
        display: flex;
        align-items: center;
        margin: 12px 0;

        .color-name {
            margin-left: 8px;
        }
    }

    .editColor-colorPicker-selected {
        display: grid;
        grid-template-columns: repeat(auto-fill, 2.875rem);
        grid-column-gap: 14px;
        grid-row-gap: 10px;
        max-height: 110px;
        overflow: hidden auto;
    }

    .editColor-colorPicker-colorContainer {
        margin-bottom: 10px;
    }

    .editColor-colorPicker-colors {
        max-height: 170px;
        overflow: hidden auto;
    }

    .parentAndChildPanel {
        position: relative;
        width: 100%;
        height: 30px;


        .moreClass {
            text-decoration: underline;
            color: #2996FB;
            font-size: 16px;
        }
    }

    .editColor-colorPicker-colorContainer {
        flex: 1;
        overflow: hidden auto;

        .editColor-colorPicker-colors {
            display: grid;
            grid-template-columns: repeat(auto-fill, 2.875rem);
            grid-column-gap: 14px;
            grid-row-gap: 10px;
        }

        .childrenPanel {
            width: 100%;
            padding: 10px;
            background: #F0F0F0;
            border: 1px solid #DBDBDB;
        }
    }

}

::v-deep .base-dialog-model-con {
    position: relative;

    .button {
        text-align: center;
    }

    .title {
        font-size: 20px;
        border-bottom: 1px solid;
        padding: 11px 20px;
        font-weight: bold;
    }

    .cropper-icon {
        display: flex;
        position: absolute !important;
        z-index: 10 !important;
        top: 70px;
        right: 25px;

        .el-button {
            border-radius: 50%;
            background-color: #909399;
            width: 20px;
            color: white;
            display: flex;
            justify-content: center;
            border: 1px solid #909399;
        }
    }

    .cropper-btn {
        text-align: right;
        padding: 20px;
    }

    .cropper {
        height: 600px !important;
        padding: 20px;

        .vue-cropper {
            background-repeat: initial !important;
        }
    }
}

#tool-option {
    position: fixed;
    background: #fff;
    font-size: 14px;
    z-index: 1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
    border-radius: 4px;
    user-select: none;

    .text-tool {
        .top {
            display: flex;
            padding: 0.5em;

            & > .el-select {
                margin-right: 4px;
            }

            .fontSizeSelect {
                width: 90px;
            }

            .text-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                margin: 0 2px;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: #f4f5f6;
                    color: $dz-primary;
                }

                &.active {
                    background-color: #f4f5f6;
                    color: $dz-primary;
                }

                b {
                    font-size: 14px;
                }
            }
        }

        .bottom {
            padding: 0 0.5rem 0.5rem;

            textarea {
                color: inherit;
                outline-width: 0;
                -webkit-overflow-scrolling: touch;
                padding: 0.5em;
                resize: none;
                white-space: pre;
                width: 100%;
                border: 1px solid #e6e6e6;
                border-radius: 4px;
            }
        }
    }

    .img-tool {
        display: flex;

        .image-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.4rem 1rem;
            border-right: 1px solid #ccc;
            cursor: pointer;

            &:last-child {
                border-right: none;
            }

            span {
                display: none;
            }
        }

        b {
            font-size: 20px;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    @include respond-to(mb) {
        border: none;
        border-radius: 0;
        .text-tool {
            width: 303px;
            background-color: #f2f2f2;
            font-size: 12px;

            .top {
                & > .el-select ::v-deep {
                    margin-right: 4px;
                }

                .fontSelect {
                    width: 150px;
                }

                .fontSizeSelect {
                    width: 62px;
                }

                .copy-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 62px;
                    background: #ffffff;
                    border: 1px solid #e6e6e6;
                    border-radius: 2px;

                    span {
                        margin-left: 4px;
                    }
                }
            }

            .bottom {
                display: flex;
                align-items: center;

                button {
                    width: 100px;
                    margin-right: 4px;
                }

                .text-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    margin-right: 4px;
                    border-radius: 4px;
                    cursor: pointer;

                    &.active {
                        background-color: #ffffff;
                        color: $dz-primary;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    b {
                        font-size: 12px;
                    }
                }
            }
        }
        .img-tool {
            .image-icon {
                width: 78px;
                padding: 0.4rem 1rem;

                span {
                    display: block;
                }
            }

            b {
                font-size: 18px;
            }

            svg {
                width: 22px;
                height: 22px;
            }
        }
    }
}

::v-deep .addImgDrawerWrap {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: auto !important;

    .colorHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .icon-item {
            font-size: 20px;
            color: #333638;
            margin: 0 10px;
            padding: 2px;
        }

        .colorTitle {
            text-align: center;
            font-size: 14px;
            font-family: Arial;
            font-weight: 400;
            color: #333333;
        }
    }

    .singleColor {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        margin: 10px auto;

        span {
            font-size: 12px;
            font-family: Arial;
            font-weight: 400;
            color: #333333;
            margin-left: 10px;
        }

        &:after {
            position: absolute;
            bottom: -5px;
            left: 0;
            content: "";
            height: 1px;
            width: 100%;
            border: 1px solid #dbdbdb;
        }
    }

    .chooseColor {
        margin: 20px 0;
        display: flex;
        align-items: center;

        .artImg {
            padding: 5px;
            width: 110px;
            height: 110px;
            aspect-ratio: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .chooseColorText {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-left: 10px;

            .chooseText {
                margin: 5px 0;
                height: 14px;
                align-self: flex-start;
                font-size: 12px;
                font-family: Arial;
                font-weight: 400;
                color: #333333;
            }

            .editColor-colorPicker-selected {
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;
                max-height: 86px;
                overflow-x: hidden;
                overflow-y: auto;
                overflow: hidden auto;
            }
        }
    }

    .replaceColor {
        padding: 10px 10px 20px;

        .editColor-title {
            padding: 10px 0;
            font-size: 12px;
            font-family: Arial;
            font-weight: 400;
            color: #333333;
        }

        .editColor-colorPicker-colorContainer {
            .editColor-colorPicker-colors {
                grid-column-gap: 10px;
                grid-row-gap: 10px;
                display: grid;
                grid-template-columns: repeat(auto-fill, 34px);
                max-height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                overflow: hidden auto;
            }
        }
    }
}

.switch {
    width: 45px;
    height: 24px;
    position: relative;
    margin-right: 10px;

    .myCheckBox {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        z-index: 2;
    }

    .slider {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 14px;
        background-color: #cecece;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 20px;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            background-color: #fff;
            transition: 0.3s all;
        }
    }

    .myCheckBox:checked + .slider::before {
        left: calc(100% - 20px);
    }

    .myCheckBox:checked + .slider::after {
        content: "";
        height: 100%;
        width: 100%;
        border-radius: 14px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #2996fb;
        z-index: -1;
        transition: 0.4s all;
    }
}

::v-deep .addTextDrawerWrap {
    padding: 0 0 10px;
    font-size: 14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 10px;
        font-weight: 700;

        b {
            font-weight: 400;
        }
    }

    .main {
        padding: 0 10px;

        label.textLabel {
            display: block;
            background: #f5f5f5;
            border-radius: 10px;
            width: 100%;

            textarea {
                border: 0;
                color: inherit;
                outline-width: 0;
                -webkit-overflow-scrolling: touch;
                padding: 1rem;
                resize: none;
                white-space: pre;
                width: 100%;
                background: #f5f5f5;
                border-radius: 10px;
            }
        }
    }
}

.moreColorsDialog {
    position: fixed;
    z-index: 10000;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);

    .dialog {
        position: absolute;
        z-index: 20000;
        background-color: #FFFFFF;
        overflow: hidden;
        top: 10%;
        left: 50%;
        width: 535px;
        height: 75%;
        max-width: 535px;
        max-height: 718px;
        min-height: 380px;

        .title {
            width: 100%;
            height: 40px;
            padding: 14px 0 7px;
            font-size: 16px;
            font-family: Calibri;
            font-weight: bold;
            color: #333333;

            b {
                position: absolute;
                font-size: 11px;
                font-weight: bold;
                right: 20px;
                top: 18px;
            }
        }

        .searchInput {
            position: relative;
            padding: 8px 29px 0 20px;

            ::v-deep {
                .highlight {
                    font-weight: 700;
                }
            }

            .inputSearch {
                width: 101%;
                height: 35px;
                font-size: 14px;
                background: #EBEBEB;
                border-radius: 17px;
                padding: 0 0 0 14px;
                border: 1px solid transparent;
                transition: all .3s;

                &:focus {
                    border: 1px solid $dz-primary;
                    background-color: #ffffff;
                }
            }

            .suffix-inner {
                position: absolute;
                top: 25px;
                right: 35px;
                transform: translateY(-50%);
                cursor: pointer;

                b {
                    color: #A6A6A6;
                }
            }
        }

        .colorCardSection {
            width: 535px;
            height: 88%;
            padding: 10px 9px 0 14px;

            .editColor-colorPicker-colors {
                width: 100%;
                max-height: 99%;
                display: grid;
                grid-template-columns: repeat(auto-fill, 2.875rem);
                grid-column-gap: 16px;
                grid-row-gap: 10px;
                align-items: flex-start;
            }

            .showOops {
                margin: 0 auto;
                width: 100%;
                height: 99%;

                .imgWrapOops {
                    width: 50%;
                    margin: 0 auto;
                    transform: translateX(-12px);
                }

                .tip {
                    margin-top: 15px;
                }
            }
        }
    }
}
</style>
