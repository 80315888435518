import request from "@/utils/request";
//拖拽排序
export function sortColorType (data) {
  return request({
    url: `/design/colorCard/sort`,
    method: "post",
    data
  });
}
//获取所有色卡分类
export function selectColorType(){
  return request({
    url: `/design/colorCard/selectColorType`,
    method: "get",
  });
}
//获取所有色卡(子级)
export function selectColorCard (data) {
  return request({
    url: `/design/colorCard/selectColorCard`,
    method: "post",
    data
  });
}
//新增或修改色卡分类
export function editColorType (data) {
  return request({
    url: `/design/colorCard/editColorType`,
    method: "post",
    data
  });
}
//删除色卡分类
export function deleteColorType (params) {
  return request({
    url: `/design/colorCard/deleteColorType`,
    method: "get",
    params
  });
}
//批量修改色卡分类
export function batchUpdateColorCardType (data) {
  return request({
    url: `/design/colorCard/batchUpdateColorCardType`,
    method: "post",
    data
  });
}
// export default {
  

  // // 获取所有色卡
  // getAllColorCard() {
  //   return request({
  //     url: `/design/color/getAllColorCard`,
  //     method: "post"
  //   });
  // },
  // // 批量新增色卡
  // batchAddColorCard(data) {
  //   return request({
  //     url: `/design/color/batchAddColorCard`,
  //     method: "post",
  //     data
  //   });
  // }
// };
