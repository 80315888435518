<template>
	<div class="artList" v-show="showArt" @click.stop>
		<div class="listWrap" scrollbar v-loadmore="loadArt" :infinite-scroll-disabled="disabledLoadArt">
			<div class="list-item linear-gradient-1" v-for="item in artList" :key="item.id" :title="item.elementName">
				<cliparts
					:item="item"
					:clipartId="item.id"
					:isCollection="item.isCollection"
					:clipartImage="item.clipartImage"
					:clipartName="item.elementName"
					@addImg="addImg"
				>
				</cliparts>
			</div>
		</div>
		<div class="loadMore" v-show="loadingArt">loading...</div>
	</div>
</template>

<script>
import cliparts from "@/components/MyDzxt/cliparts.vue";
import { getArtList, likeQuery } from "@/api/newDzxt";
import dzMixin from "@/mixins/dzMixin";

export default {
	mixins: [dzMixin],
	data() {
		return {
			loadingArt: false,
			artForm: {
				page: 1,
				pageSize: 60,
				clipartTypeId: null,
				total: 0,
				pages: 1,
				likeQuery: "",
			},
			artList: [],
		};
	},
	components: {
		cliparts,
	},
	watch: {
		isLogin() {
			this.getArtList();
		},
	},
	computed: {
		showArt() {
			return this.$store.state.design.showArt;
		},
		noMoreArt() {
			return this.artForm.page >= this.artForm.pages;
		},
		disabledLoadArt() {
			return this.loadingArt || this.noMoreArt;
		},
		status() {
			return this.$store.state.design.status;
		},
		artStatus() {
			return this.$store.state.design.artStatus;
		},
		isLogin() {
			return this.$store.getters.isLogin;
		},
		userId() {
			return this.$store.state.userInfo?.id;
		},
	},
	methods: {
		loadArt() {
			this.artForm.page++;
			this.getArtList("scroll");
		},
		getArtList(type = "select") {
			this.loadingArt = true;
			if (this.artForm.likeQuery) {
				likeQuery(
					Object.assign({}, this.artForm, {
						userId: this.userId,
						proId: this.$store.state.design.defaultProId,
						language: "en-us",
						quoteName: this.$store.state.design?.pageInfo?.quoteCateName,
					}),
				).then((res) => {
					if (type === "scroll") {
						let list = res.data.collectionList;
						this.artList = this.artList.concat(list);
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					} else if (type === "select") {
						this.artList = res.data.collectionList;
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					}
				});
			} else {
				getArtList(
					Object.assign({}, this.artForm, {
						userId: this.userId,
						proId: this.$store.state.design.defaultProId,
						language: "en-us",
					}),
				).then((res) => {
					if (type === "scroll") {
						let list = res.data.records;
						this.artList = this.artList.concat(list);
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					} else if (type === "select") {
						this.artList = res.data.records;
						this.artForm.total = res.data.total;
						this.artForm.pages = res.data.pages;
						this.loadingArt = false;
					}
				});
			}
		},
		addImg({ src, property }) {
			this.$store.commit("design/set_showArt", false);
			this.$Bus.$emit("triggerAddArt", {
				src,
				property,
			});
		},
		selectArtCategory(item) {
			this.artList = [];
			this.artForm.page = 1;
			this.artForm.clipartTypeId = item.id;
			this.artForm.likeQuery = "";
			this.getArtList();
		},
		searchArt(val) {
			this.artList = [];
			this.artForm.page = 1;
			this.artForm.clipartTypeId = null;
			this.artForm.likeQuery = val;
			this.getArtList();
		},
	},
	mounted() {
		this.getArtList();
		this.$Bus.$on("selectArtCategory", this.selectArtCategory);
		this.$Bus.$on("searchArt", this.searchArt);
	},
	beforeDestroy() {
		this.$Bus.$off("selectArtCategory", this.selectArtCategory);
		this.$Bus.$off("searchArt", this.searchArt);
	},
};
</script>

<style scoped lang="scss">
.artList {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px 30px;
	background-color: #e6e6e6;
	z-index: 2;

	.loadMore {
		position: absolute;
		left: 50%;
		bottom: 5px;
		transform: translateX(-50%);
		text-align: center;
	}

	.listWrap {
		display: grid;
		align-content: flex-start;
		overflow: hidden auto;
		padding: 20px;
		height: 100%;
		background-color: #ffffff;

		.list-item {
			position: relative;
			padding: 10px;
			background: #ffffff;
			border: 1px solid #e0e0e0;
			cursor: pointer;
		}
	}
}

.artList {
	.listWrap {
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 10px;

		.list-item {
			aspect-ratio: 1;
		}
	}
}
</style>
