import layout from "@/views/layout/index.vue";
import userModule from "@/views/InquiryOrderManage/userModule/index.vue";
const taskManage = () => import("@/views/drawingDesign/taskManage/index.vue");
const usermanagement = () => import("@/views/drawingDesign/usermanagement.vue");
const userCenter = () => import("@/views/drawingDesign/userCenter.vue");
const artworkResourceManagement = () => import("@/views/drawingDesign/resourceManagement.vue");
export default {
    path: "/taskManage",
    name: "taskManageWrap",
    component: layout,
    meta: {
        title: "图稿任务系统",
        icon: "el-icon-edit"
    },
    children: [
        {
            path: "taskManage",
            name: "taskManage",
            component: taskManage,
            meta: {
                title: "图稿任务列表",
                type: 0
            }
        },
        {
            path: "usermanagement",
            name: "usermanagement",
            component: usermanagement,
            meta: {
                title: "图稿任务统计",
                type: 0
            }
        },
        {
            path: "userCenter",
            name: "tgUserCenter",
            component: userCenter,
            meta: {
                title: "分配配置",
                type: 0
            }
        },
        {
            path: "artworkResourceManagement",
            name: "artworkResourceManagement",
            component: artworkResourceManagement,
            meta: {
                title: "资源库",
                type: 0
            }
        },
        {
            path: "",
            redirect: "userModule"
        },
        {
            path: "userModule",
            name: "userModule",
            component: userModule,
            meta: {
                title: "用户模块"
            }
        }
    ]
}