<template>
    <div>
        <base-dialog :value="myDesignDialog" @update="update" v-if="device !== 'mb'" width="65%" >
            <div class="wrap" >
                <div class="wrap_title">
                	<!-- <div v-for="(item, index) in titleList" :key="index" @click="titleClick(item)"
                        :class="isActive === item.id ? 'titleName' : 'titleName2'">{{ item.name }}</div> -->
                    <el-input class="inputSearch" style="width:250px;"
                    placeholder="请输入邮箱" v-model="inputValue" clearable>
                    </el-input>
                	<el-cascader class="block"  v-model="proId" placeholder="请选择平台"
                    :props="{emitPath:false,value:'id',label:'proName'}"
                    :options="options" ></el-cascader>
                    <el-button icon="el-icon-search" type="primary" class="search"
                     @click="getMyCreations()"></el-button>
                	<div class="titleName">My Creation</div>
                </div>

                <!-- My Creation -->
                <div class="wrap_Likes" v-if="name === 0">
                    <div class="div">
                        <div class="wrap_Likes_subTitle">
                            <div v-for="(item, index) in subTitleList" :key="index" @click="likesClick(item)"
                                :class="subTitleActive === item.id ? 'subTitle_div' : 'subTitle_div2'">
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                    <div v-show="showOopsTemplate&&likesStatus === 1">
                    	<div class="imgWrapOops">
                    		<img src="../../assets/image/noresults.png" alt="">
                    	</div>
                    	<div class="tip">Sorry, no results, please enter another email or platform!</div>
                    </div>
                    <div v-show="showOopsCliparts&&likesStatus === 2">
                    	<div class="imgWrapOops">
                    		<img src="../../assets/image/noresults.png" alt="">
                    	</div>
                    	<div class="tip">Sorry, no results, please enter another email or platform!</div>
                    </div>
                    <!-- Templates -->
                    <div class="templates_div" v-show="likesStatus === 1">
                        <div class="box-item linear-gradient-1" v-for="(citem, index) in favoriteTemplatesList"
                            :key="index">
                            <templates
                                :item="citem"
                                :templateId="citem.id"
                                :templateImg="citem.templateUrl"
                                :isFavourite="1"
                                @selectTemplate="editCreationTemplate(citem)"
                                >
                            </templates>
                            <div class="item-sign">
                                <div class="item-sign-edit">
                                    <span v-show="editCitem.id!=citem.id">{{ citem.templateName }}</span>
                                    <el-input v-model="templatesName2" v-show="editCitem.id&&editCitem.id==citem.id" @blur="editLogoNameBlur(citem)"
                                        class="inner"></el-input>
                                    <b class="icon-edit" @click="logoNameClick($event, citem)"></b>
                                </div>
                                <div>{{updateTime(citem.updateTime)}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- Cliparts -->
                    <div class="templates_div templates_Cliparts" v-if="likesStatus === 2">
                        <div class="box-item linear-gradient-1" v-for="(citem, index) in favoriteElementList" :key="index">
                            <cliparts
                                :item="citem"
                                :clipartId="citem.clipartId"
                                :clipartName="citem.clipartName"
                                :clipartImage="citem.clipartUrl"
                                :checked="0"
                                :isCollection="1"
                                @addImg="editImg"
                            ></cliparts>
                            <div class="updateTime" style="height:30px;background-color:#fff;">{{updateTime(citem.updateTime)}}</div>
                        </div>
                    </div>
                </div>

                <!-- 前台设计系统的My Creation -->
                <!-- <div class="wrap_Likes" v-if="name === 1">
                    <div class="div">
                        <div class="wrap_Likes_subTitle">
                            <div v-for="(item, index) in subTitleList" :key="index" @click="myCreationClick(item)"
                                :class="subTitleActive === item.id ? 'subTitle_div' : 'subTitle_div2'">
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                    <div class="templates_div" v-show="myCreationStatus === 1">
                        <div class="box-item" v-for="(citem, index) in creationTemplatesList" :key="index">
                            <div class="box-item linear-gradient-1">
                                <div class="item-img">
                                    <img loading="lazy" class="item-name" :src="citem.templateUrl" alt="" width="244px"
                                        height="138px" />
                                </div>
                                <div class="list-item-btn">
                                    <div class="item-btn">
                                        <div title="edit" class="edit" @click="editCreationTemplate(citem)">
                                            <b class="icon-a-T-editzhuanhuan"></b>
                                        </div>
                                        <div class="del" title="delete" @click="deleteUserUploadPic(citem)" v-throttle>
                                            <b class="icon-shanchu"></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-sign">
                                <div class="item-sign-edit">
                                    <span v-show="!citem.islogo">{{ citem.templateName }}</span>
                                    <el-input v-model="templatesName2" v-show="citem.islogo" @blur="editLogoNameBlur(citem)"
                                        class="inner"></el-input>
                                    <b class="icon-edit" @click="logoNameClick($event, citem)"></b>
                                </div>
                                <div>{{ citem.updateTime }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="myPic" scrollbar v-show="myCreationStatus === 2">
                        <div class="myUpload" scrollbar>
                            <div class="upload-area" @click="uploadDialog = true" >
                                <div class="upload-icon">
                                    <img loading="lazy"
                                        src="https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221129/2062bkrBZJcG.png"
                                        alt="" />
                                </div>
                                <div class="upload-text">
                                    {{ lang.upload }}
                                </div>
                            </div>
                            <div class="upload-item" v-for="(item, index) in userArtCategoryList" :key="index">
                                <div class="top" @click="viewMyUpload(item)"
                                    :class="{ noImg: item.clipartList.length < 4 }">
                                    <template v-if="item.clipartList.length > 3">
                                        <div class="imgWrap linear-gradient-1" v-show="index < 4"
                                            v-for="(citem, index) in item.clipartList" :key="index">
                                            <img loading="lazy" :src="citem.clipartUrl" alt="" />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="imgWrap linear-gradient-1">
                                            <img src="https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20221209/203409BDN6TfQ4.png"
                                                alt="" />
                                        </div>
                                    </template>
                                </div>
                                <div class="bottom">
                                    <div class="folder-name" :title="item.categoryName">
                                        <div v-show="fileId !== item.id || firstMatchId === item.id">
                                            {{ item.categoryName }}
                                        </div>
                                        <el-input v-model="editFileName"
                                            v-show="fileId === item.id && firstMatchId !== item.id" @blur="filenameBlur"
                                            :class="'renameFocus' + index">
                                        </el-input>
                                    </div>
                                    <div class="more" @click.stop="gengduoClick(index)" v-show="index !== -1 && index !== 0">
                                        <el-popover popper-class="more-popover" placement="bottom" trigger="click">
                                            <b class="icon-gengduo" slot="reference" :style="showGengduo === index ? 'color:#2A96FA' : '#999999'"></b>
                                            <div>
                                                <div class="more-popover-item" @click="renameFolder(item, index)">
                                                    <a href="javascript:;"><b class="icon-a-T-editzhuanhuan"></b>{{
                                                        lang.rename }}</a>
                                                </div>
                                                <div class="more-popover-item" @click="deleteFolder(item)">
                                                    <a href="javascript:;"><b class="icon-a-icon-deletezhuanhuan"></b>{{
                                                        lang.delete }}</a>
                                                </div>
                                            </div>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="myCustomUploadBox" :class="{ isFavorite: isFavorite }" v-show="myCustomUploadStatus === 1">
                        <div class="topBar">
                            <strong class="title">{{ userArtFolder.categoryName }}</strong>
                            <div class="back-icon" @click="uploadStatus">
                                <b class="icon-back"></b>
                            </div>
                        </div>
                        <div class="con">
                            <div class="controlTab">
                                <div class="left">
                                    <el-checkbox v-model="checkedAll" @change="toggleSelectAllUserPic">{{ lang.all
                                    }}</el-checkbox>
                                </div>
                                <div class="right">
                                    <div class="right-del" @click="delElement" v-show="showDel">
                                        <b class="icon-shanchu"></b>
                                    </div>
                                    <span>{{ lang.moveTo }} &nbsp;</span>
                                    <el-dropdown placement="bottom" @visible-change="changeDropDown" trigger="click"
                                        @command="handleFolderCommand">
                                        <div class="el-dropdown-link" :class="{ 'showExtend': showExtend }">
                                            <el-input readonly v-model="selectCategoryName" :placeholder="lang.pubilc"
                                                suffix-icon="el-icon-arrow-down">
                                            </el-input>
                                        </div>
                                        <el-dropdown-menu slot="dropdown" class="myDropDown1 myDropDown2">
                                            <el-dropdown-item :command="{ name: 'Create', id: -10 }">
                                                <div class="select-item isCreate">
                                                    <div class="circle">
                                                        <b class="icon-plus"></b>
                                                    </div>
                                                    <span>{{ lang.create }}</span>
                                                </div>
                                            </el-dropdown-item>
                                            <el-dropdown-item :command="item" v-for="item in userArtCategoryList"
                                                :key="item.id" v-show="userArtFolder.userCateId !== item.id">
                                                <div class="select-item" :class="{ selected: selectCategory === item.id }"
                                                    @click="moveClick(item)">
                                                    <div class="circle"></div>
                                                    <span>{{ item.categoryName }}</span>
                                                </div>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                            <div class="boxWrap" scrollbar>
                                <div class="box-item linear-gradient-1" v-for="(citem, index) in  myUploadList"
                                    :key="index">
                                    <cliparts
                                        :item="citem"
                                        :clipartId="citem.clipartId"
                                        :clipartImage="citem.clipartUrl"
                                        :checked="1"
                                        @addImg="editImg"
                                        @clipartChecked="clipartChecked"
                                    ></cliparts>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- 上传弹窗 -->
                <base-dialog v-model="uploadDialog" :width="device !== 'mb' ? '710px' : '92%'">
                    <div class="uploadDialogCon">
                        <div>
                            <div class="top">
                                <div>{{ lang.uploadCliparts }}</div>
                            </div>
                            <div class="left">
                                <div class="des">{{ lang.onlyUploaded }}</div>
                            </div>
                        </div>
                        <div class="con" scrollbar>
                            <div class="upload-box">
                                <div class="plus">
                                    <b class="icon-a-icon-addzhuanhuan"></b>
                                </div>
                                <span>{{ lang.upload }}</span>
                                <input type="file" ref="userFileInput" multiple accept="image/jpeg,image/png"
                                    @change="handleUserFileInput" />
                            </div>
                            <div class="upload-item" v-for="(item, index) in waitUploadFileList" :key="index">
                                <div class="imgWrap linear-gradient-1">
                                    <img :src="item.imgUrl" alt="" />
                                </div>
                                <div class="close-icon" @click="deleteWaitUploadFile(index)">
                                    <b class="icon-a-icon-qxzhuanhuan"></b>
                                </div>
                                <div class="nameWrap">
                                    <span class="name" :title="item.imgName" v-show="!item.isImgName">{{
                                        item.imgName
                                    }}</span>
                                    <el-input v-model="imgName2" v-show="item.isImgName" @blur="editImgNameBlur(item)"
                                        class="inputFocus"></el-input>
                                    <span class="edit" @click="editImgName($event, item)"><b class="icon-edit"></b></span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="category">
                            <div class="left">{{ lang.category }}</div>
                            <div class="right">
                                <el-dropdown placement="bottom-start" trigger="click" @command="handleFolderCategory"
                                    :hide-on-click="hideOnClick">
                                    <div class="el-dropdown-link">
                                        <el-input readonly v-model="selectCategoryName"
                                            :placeholder="lang.saveCategory"></el-input>
                                    </div>
                                    <el-dropdown-menu class="myDropDown1">
                                        <el-dropdown-item :command="{ name: lang.create, id: -10 }">
                                            <div class="select-item isCreate" v-show="isCreate === false"
                                                @click="createClick">
                                                <div class="circle">
                                                    <b class="icon-plus"></b>
                                                </div>
                                                <span>{{ lang.create }}</span>
                                            </div>
                                            <div class="select-item" v-show="isCreate === true">
                                                <el-input v-model="filename"></el-input>
                                                <el-button type="text" @click="confirmCreateFolder">{{ lang.ok
                                                }}</el-button>
                                                <el-button type="text" @click="cancelCreateFolder">{{ lang.cancel
                                                }}</el-button>
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item :command="item" v-for="item in userArtCategoryList"
                                            :key="item.id">
                                            <div class="select-item" :class="{ selected: selectCategory === item.id }"
                                                @click="categoryNameClick">
                                                <div class="circle"></div>
                                                <span>{{ item.categoryName }}</span>
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div> -->
                        <div class="btnGroup">
                            <el-button @click="resetUserUploadForm">{{ lang.cancel }}</el-button>
                            <el-button type="primary" @click="confirmUploadUserFile">{{ lang.comfirm }}</el-button>
                        </div>
                    </div>
                </base-dialog>

                <!--创建文件夹-->
                <base-dialog v-model="showCreateFolder" :width="device !== 'mb' ? '710px' : '92%'">
                    <div class="createUploadFolderCon">
                        <div>
                            <div>{{ lang.categoryText }}</div>
                            <input type="text" v-model="filename" />
                        </div>
                        <div class="btnGroup">
                            <el-button @click="cancelCreateFolder">{{ lang.cancel }}</el-button>
                            <el-button type="primary" @click="confirmCreateFolder" v-throttle>{{ lang.comfirm }}</el-button>
                        </div>
                    </div>
                </base-dialog>
            </div>
        </base-dialog>
    </div>
</template>
<script>
import cliparts from "@/components/MyDzxt/cliparts.vue"
import templates from "@/components/MyDzxt/templates.vue"
import BaseDialog from "../Quote/BaseDialog.vue";
import { uploadFile } from "@/utils/oss";
import { dateFormat,getFilePrefix } from "@/utils/utils";
import {
    updateNameById,
    getList,
    getUserClipartTemplatesByEmail,
    getFavoriteTemplateList,
    getDefaultTemplateList,
    uploadUserClipart,
    editUserClipartCategory,
    getClipartCategoryList,
    getUserUploadClipartList,
    getDefaultClipartList,
    getFavoriteClipartList,
    moveUploadClipart,
    favoriteTemplate,
    favoriteClipart,
    editUserTemplates,
    deleteUserTemplates,
    getTemplateById,
    deleteUserClipart
} from "@/api/newDzxt"
import dzMixin from "@/mixins/dzMixin";
export default {
    props: ['myDesignDialog'],
    mixins: [dzMixin],
    data() {
        return {
            showOopsTemplate:false,
            showOopsCliparts:false,
            proId:'',
            inputValue:'',
            options:[],
            showGengduo:0,
            showDel:false,
            firstMatchId: null,
            isImgName: 0,
            imgName2: "",
            loveId: null,
            likesStatus: 1,
            showCreateFolder: false,
            templatesName2: "",
            fileId: null,
            checkedAll: false,
            myCustomUploadStatus: 0,
            isFavorite: false,
            hideOnClick: true,
            isCreate: false,
            selectCategoryName: "",
            filename: "",
            editFileName: "",
            selectCategory: 0,
            uploadDialog: false,
            myCreationStatus: 1,
            name: 0,
            isActive: 0,
            subTitleActive: 0,
            userArtCategoryList: [],
            waitUploadFileList: [],
            userArtFolder: [],
            templatesList: [],
            cateId: 0,
            fileListId: 0, //文件id
            elementListId: [], //元素id
            favoriteTemplatesList: [], //收藏模版列表
            creationTemplatesList: [], //Creation 模版列表
            uploadElementFile: [],//上传元素新建文件夹
            favoriteElementList: [],//收藏元素列表
            myUploadList: [],//获取上传元素列表
            editCitem: {},
            showExtend: false
        };
    },
    components: {
        BaseDialog,
        templates,
        cliparts
    },
    computed: {
        updateTime(){
            return function(value){
                let res=value?value.slice(0,10):'';
                return res;
            }
        },
        lang() {
            return this.$store.getters.lang?.design;
        },
        titleList() {
            return [
                {
                    id: 0,
                    name: this.lang.favorites,
                },
                {
                    id: 1,
                    name: this.lang.myCreation,
                }
            ]
        },
        subTitleList() {
            return [
                {
                    id: 0,
                    name: this.lang.templates,
                },
                {
                    id: 1,
                    name: this.lang.cliparts,
                }
            ]
        },
        device() {
            return this.$store.state.device;
        },
    },
    methods: {
        getMyCreations(){
            this.showOopsTemplate=false;
            this.showOopsCliparts=false;
            if(this.proId&&this.inputValue){ 
            getUserClipartTemplatesByEmail({
            userEmail:this.inputValue,
            proId: this.proId,
			categoryProId: this.$store.state.design?.pageInfo?.id,
            }).then(res=>{
                if(res.code=='200'){
                    this.favoriteTemplatesList=res.data.userTemplatesList;
                    this.favoriteElementList=res.data.userClipartList;
                    this.showOopsTemplate=this.favoriteTemplatesList.length==0?true:false;
                    this.showOopsCliparts=this.favoriteElementList.length==0?true:false;
                    this.favoriteTemplatesList.forEach((item) => {
                        item.islogo = false;
                })
                }
            }).catch(error=>{
                this.favoriteTemplatesList=[];
                this.favoriteElementList=[];
                this.showOopsTemplate=true;
                this.showOopsCliparts=true;
            })
            }else{
                this.$message.error('邮箱和平台皆不能为空！')
            }
        },
        gengduoClick(index){
            this.showGengduo = index;
        },
        //删除上传元素
        // delElement() {
        //     this.$confirm(this.lang.delUploadedElement, this.lang.tips, {
        //         confirmButtonText: this.lang.comfirm,
        //         cancelButtonText: this.lang.cancel,
        //         type: this.lang.warning,
        //     })
        //         .then(() => {
        //             this.myUploadList.forEach((item) => {
        //                 if (item.checked === true) {
        //                     this.elementListId.push(item.id);
        //                 }
        //             })
        //             deleteUserClipart({
        //                 idList: this.elementListId
        //             }).then((res) => {
        //                 this.checkedAll=false;
        //                 this.showDel = false;
        //                 this.getUserArtList();
        //             })
        //         })
        //         .catch(() => {
        //         });
        // },
        //回退箭头
        uploadStatus() {
            this.selectCategory=0;
            this.selectCategoryName = "";
            this.myCustomUploadStatus = 0;
            this.getClipartList();
        },
        changeDropDown(val) {
            this.showExtend = val
        },
        //My Creation 删除模板
        deleteUserUploadPic(citem) {
            this.$confirm(this.lang.delTemplate, this.lang.tips, {
                confirmButtonText: this.lang.comfirm,
                cancelButtonText: this.lang.cancel,
                type: this.lang.warning,
            })
                .then(() => {
                    deleteUserTemplates({
                        id: citem.id
                    }).then((res) => {
                        this.getDefaultList();
                    })
                })
                .catch(() => {
                });
        },
        //My Creation 编辑模版文件名
        editLogoNameBlur(citem) {
            citem.islogo = !citem.islogo;
            this.editTemplates();
        },
        editTemplates() {
            updateNameById({
                id: this.editCitem.id,
                templateName: this.templatesName2,
            }).then((res) => {
                this.getMyCreations();
                this.editCitem={};
            })
        },
        logoNameClick(e, citem) {
            this.editCitem = citem;
            this.templatesName2 = citem.templateName;
            citem.islogo = !citem.islogo;

            //获取焦点
            this.$nextTick(() => {
                let findParent = this.getCustomParentNode(e.target, 'item-sign');
                let input = findParent.querySelector('.inner').querySelector('input');
                if (input) {
                    input.focus();
                }
            })
        },

        //上传文件夹元素全选
        toggleSelectAllUserPic(val) {
            this.myUploadList.forEach((item) => {
                item.checked = val;
            });
            if(val){
				this.showDel=true;
			}else{
				this.showDel=false;
			}
        },
        clipartChecked() {
            this.checkedAll = this.myUploadList.every(checkbox => checkbox.checked);
            let find = this.myUploadList.find((item)=>item.checked === true);
            if(find){
               this.showDel= true;
            }else{
                this.showDel= false;
            }
        },

        discardActiveObject() {
            if (!this.fabricStage) {
                return false;
            }
            this.selectedImgColor = "";
            this.selectedImgReplaceColor = "";
            this.selectedElement = "";
            this.showImgColorPopover = false;
            this.fabricStage.discardActiveObject();
            this.fabricStage.requestRenderAll();
        },
        //点元素文件夹
        viewMyUpload(item) {
            this.userArtFolder = item;
            this.myUploadList = [];
            this.checkedAll = false;
            this.cateId = item.id;
            this.getUserArtList();
            this.myCustomUploadStatus = 1;
        },
        //上传文件夹里面的元素
        getUserArtList() {
            if (this.cateId == null) {
                getDefaultClipartList({
                    page: 1,
                    pageSize: 99999,
                }).then((res) => {
                    let uploadList = res.data.records;
                    uploadList.forEach((item) => {
                        item.checked = false;
                    });
                    this.myUploadList = uploadList;
                })
            } else {
                getUserUploadClipartList({
                    page: 1,
                    pageSize: 99999,
                    cateId: this.cateId,
                }).then((res) => {
                    let uploadList = res.data.records;
                    uploadList.forEach((item) => {
                        item.checked = false;
                    });
                    this.myUploadList = uploadList;
                });
            }
        },
        //rename
        renameFolder(item, index) {
            this.fileId = item.id;
            this.editFileName = item.categoryName;
            this.$nextTick(() => {
                const inputElement = document.querySelector('.renameFocus' + index).querySelector('input');
                if (inputElement) {
                    inputElement.focus();
                }
            });
        },
        //修改文件名
        filenameBlur() {
            if (this.editFileName === undefined) {
                this.fileId = -1;
                return
            }
            editUserClipartCategory({
                id: this.fileId,
                categoryName: this.editFileName
            }).then((res) => {
                this.fileId = -1;
                this.getClipartList();
            })
        },
        //My Creation 删除文件夹
        // deleteFolder(item) {
        //     this.$confirm(this.lang.delFolder, this.lang.tips, {
        //         confirmButtonText: this.lang.comfirm,
        //         cancelButtonText: this.lang.cancel,
        //         type: this.lang.warning,
        //     })
        //         .then(() => {
        //             deleteUserClipartCategory({
        //                 id: item.id
        //             }).then((res) => {
        //                 this.getClipartList();
        //             });
        //         })
        //         .catch(() => {
        //         });
        // },
        //创建文件夹
        confirmCreateFolder() {
            editUserClipartCategory({
                categoryName: this.filename
            }).then((res) => {
                this.showCreateFolder = false;
                this.selectCategoryName=this.filename;
                this.getClipartList();
                //获取选中元素
                let checkedList = [];
                this.myUploadList.forEach(item=>{
                    if(item.checked){
                        checkedList.push(item.id)
                    }
                })
                if(checkedList.length>0){
                    this.moveUploadClipart(checkedList,res.data)
                }
            })
            this.hideOnClick = !this.hideOnClick;
            this.isCreate = false;
        },
        //取消
        cancelCreateFolder() {
            this.showCreateFolder = false;
            this.isCreate = false;
            this.hideOnClick = true;
            this.filename = "";
        },
        createClick() {
            this.isCreate = !this.isCreate;
            this.hideOnClick = false;
        },
        //重置
        resetUserUploadForm() {
            this.waitUploadFileList = [];
            this.selectCategoryName = "";
            this.selectCategory = "";
            this.uploadDialog=false;
        },
        getCustomParentNode(node, parentClass) {
            let parentNode = node.parentNode;
            if (parentNode.nodeName.toLowerCase() === 'body') {
                return false;
            }
            if (parentNode.className.indexOf(parentClass) > -1) {
                return parentNode
            } else {
                return this.getCustomParentNode(parentNode, parentClass)
            }
        },

        //上传弹窗编辑元素名
        editImgName(e, item) {
            this.imgName2 = item.imgName;
            item.isImgName = !item.isImgName;
            this.$nextTick(() => {
                let findParent = this.getCustomParentNode(e.target, 'nameWrap');
                let input = findParent.querySelector('.inputFocus').querySelector('input');
                if (input) {
                    input.focus();
                }
            })
        },
        editImgNameBlur(item) {
            item.isImgName = !item.isImgName;
            if (!this.imgName2) {
                return item.imgName
            }
            item.imgName = this.imgName2;
        },
        //刪除上传的元素
        deleteWaitUploadFile(index) {
            this.waitUploadFileList.splice(index, 1);
        },
        //上传更新用户元素
        confirmUploadUserFile() {
            if(this.waitUploadFileList.length === 0){
                this.$toast.info('Unuploaded file')
                return
            }
            // if(this.selectCategoryName === ""){
            //     this.$toast.info('No folder selected')
            //     return
            // }
            let categoryId
            let find = this.userArtCategoryList.find((i) => i.categoryName === this.selectCategoryName)
            if (find) {
                categoryId = find.id;
            }
            this.concurRequest(
                this.waitUploadFileList.map((item) => item.imgFile),
                10
            ).then((res) => {
                let list = [];
                res.forEach((item, index) => {
                    list.push({
                        clipartName: this.waitUploadFileList[index].imgName,
                        clipartUrl: item
                    })
                })
                return uploadUserClipart({
                    clipartId: 0,
                    cliparts: list,
                    categoryId: categoryId ? categoryId : null
                })
            }).then((res) => {
                this.uploadDialog = false;
                this.resetUserUploadForm();
                this.getClipartList();
            })
        },
        beforeUpload(file) {
			const isLt10M = file.size / 1024 / 1024 < 10;
			const isUploadType = /\.(jpg|jpeg|png)$/.test(file.name.toLowerCase());
			if (!isLt10M) {
				this.$toast.error(this.lang.sizeError);
				return isLt10M;
			}
			if (!isUploadType) {
				this.$toast.error(this.lang.imgErrorDesign);
				return isUploadType;
			}
			return true;
		},
        handleUserFileInput(event) {
            let fileArr = Array.from(event.target.files);
            let checkFormat = [];
            //校验每一个文件的格式和大小
            fileArr.forEach(item=>{
                checkFormat.push(this.beforeUpload(item))
            })
            //判断校验是否全部通过，不通过返回false
            if(!checkFormat.every(item=>item)){
                this.$refs.userFileInput.value = "";
                return false;
            }
            let arr = this.waitUploadFileList;
            for (let i = 0; i < fileArr.length; i++) {
                let imgUrl = this.getObjectURL(fileArr[i]),
                    imgName = getFilePrefix(fileArr[i].name),
                    imgFile = fileArr[i];
                arr.push({
                    imgUrl,
                    imgName,
                    imgFile,
                    isImgName: false
                });
            }
            this.$refs.userFileInput.value = "";
        },
        getObjectURL(file) {
            let url = null;
            if (window.createObjectURL !== undefined) {
                // basic
                url = window.createObjectURL(file);
            } else if (window.URL !== undefined) {
                // mozilla(firefox)
                url = window.URL.createObjectURL(file);
            } else if (window.webkitURL !== undefined) {
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file);
            }
            return url;
        },
        concurRequest(urls, maxNum) {
            return new Promise((resolve) => {
                if (urls.length === 0) {
                    resolve([])
                    return [];
                }
                const results = [];
                let index = 0;
                let count = 0;

                async function request() {
                    if (index === urls.length) {
                        return;
                    }
                    const i = index;
                    const url = urls[index];
                    index++;
                    try {
                        results[i] = await uploadFile(url,{
                            systemName:'customOss'
                        });
                    } catch (err) {
                        results[i] = err;
                    } finally {
                        //判断所有请求是否已经完成
                        count++;
                        if (count === urls.length) {
                            resolve(results);
                        }
                        request();
                    }
                }
                const times = Math.min(maxNum, urls.length);

                for (let i = 0; i < times; i++) {
                    request();
                }
            });
        },
        //上传Category
        handleFolderCategory(item) {
            this.selectCategoryName = item.categoryName;
            this.selectCategory = item.id;
            this.hideOnClick = false;
        },
        categoryNameClick(item) {
            console.log(item,"item");
            this.hideOnClick = true;
        },

        //元素文件夹Move to
        handleFolderCommand(item) {
            if (item.id === -10) {
                this.filename = "";
                this.showCreateFolder = true;
                return false;
            }
            this.selectCategory = item.id;
        },
        moveUploadClipart(idList,cateId){
            moveUploadClipart({
                idList,//选择的元素id
                cateId,//移动的文件夹id
            }).then(()=>{
                this.getUserArtList();
                this.fileId = -1;
                this.checkedAll = false;
                this.elementListId=[];
            })
        },
        //移动文件夹
        moveClick(item) {
            this.fileListId = item.id || null;
            this.myUploadList.forEach((item) => {
                if (item.checked === true) {
                    this.elementListId.push(item.id);
                }
            })
            if (!this.elementListId.length) {
                alert(this.lang.please);
                return
            }
            this.selectCategoryName = item.categoryName;
            this.moveUploadClipart(this.elementListId,this.fileListId)
        },
         //编辑收藏模版画布
        editFavoritesTemplate(item) {
            this.canvas.templateName = item.templateName;
            let loadData = () => {
				this.$store.commit('design/set_loading', true);
				getTemplateById({
					id: item.templatesId
				}).then(async res => {
					//判断是否是光板
					if (res.data.isPaintless === 1) {
						await this.canvas.replaceBase(res.data.templateFile);
						this.canvas.c.requestRenderAll();
					} else {
						window.removeEventListener('beforeunload', this.canvas.confirmExit);
						let name = res.data.templateName.toLowerCase().replace(/\s+/g, '-')
						window.location.href = `/design/${this.$route.params.designName}/${name}`
					}
					this.$store.commit('design/set_loading', false);
					this.$emit('update:myDesignDialog', false);
				}).catch(err => {
					this.$store.commit('design/set_loading', false);
				})
			}
			if (this.canvas.isEmptyCanvas()) {
				loadData()
			} else {
				this.$confirm(this.lang.areTemplate, this.lang.hint, {
					confirmButtonText: this.lang.comfirm,
					cancelButtonText: this.lang.cancel,
					type: this.lang.warning
				}).then(() => {
					loadData()
				}).catch(() => {

				});
			}
        },

       //编辑上传模版画布
        async editCreationTemplate(item) {
            this.canvas.templateName = item.templateName;
            this.canvas.loadTemplate(item.templateFile);
            this.$emit('update:myDesignDialog', false);
		},

        //编辑收藏元素画布
        async editImg({src,property}) {
            this.canvas.addImg(src,{artId:property});
            this.$emit('update:myDesignDialog', false);
        },
        update(val) {
            this.$emit('update:myDesignDialog', val);
        },

       
        myCreationClick(item) {
            this.subTitleActive = item.id;
            if (item.name === 'Templates') {
                this.myCreationStatus = 1;
            } else {
                this.myCreationStatus = 2;
            }
        },
        likesClick(item) {
            this.subTitleActive = item.id;
            if (item.name === 'Templates') {
                this.likesStatus = 1;
            } else {
                this.likesStatus = 2;
            }
        },

        //获取收藏元素列表
        getFavoriteClipartList() {
            getFavoriteClipartList({
                page: 1,
                pageSize: 99999
            }).then((res) => {
                let favoriteElementList = res.data.records;
                favoriteElementList.forEach((item) => {
                    item.clipartUrl = item.clipartUrl ? item.clipartUrl : ''
                })
                this.favoriteElementList = favoriteElementList
            })
        },

        //获取所有元素文件夹
        getClipartList() {
            getClipartCategoryList().then((res) => {
                this.userArtCategoryList = res.data;
            })
        },

        //获取My Creation上传模版
        getDefaultList() {
            getDefaultTemplateList({
                categoryId: this.$store.state.design?.pageInfo?.id,
                page: 1,
                pageSize: 99999
            }).then((res) => {
                let creationTemplatesList = res.data.records
                creationTemplatesList.forEach((item) => {
                    item.islogo = false;
                    item.updateTime = dateFormat("YYYY-mm-dd", new Date(item.updateTime))
                })
                this.creationTemplatesList = creationTemplatesList;
            })
        },

        //获取收藏模版
        getFavoriteList() {
            getFavoriteTemplateList({
                categoryId: this.$store.state.design?.pageInfo?.id,
                page: 1,
                pageSize: 99999
            }).then((res) => {
                this.favoriteTemplatesList = res.data.records;
            })
        }
    },
    created(){
    },
    mounted() {
        getList({
            pageSize:9999,
            type:0
        }).then(res=>{
            if(res.code=='200'){
                let data=Object.values(res.data);
                if(data.length>3){
                    data.pop();
                    this.options=[{
                        id:'forUs',
                        proName:'自营平台',
                    },{
                        id:'o2o',
                        proName:'O2O经销商',
                    },{
                        id:'uspp',
                        proName:'USPP加盟商',
                    }];
                    for(let i=0;i<3;i++){
	                    this.$set(this.options[i],'children',data[i]);
                    }
                }
            }
        })
        this.$Bus.$on('getMyCreations',this.getMyCreations);
    },
    beforeDestroy() {
        this.$Bus.$off('getMyCreations',this.getMyCreations);
    }
};
</script>
<style scoped lang="scss">
.showExtend ::v-deep {
    .el-input__suffix .el-icon-arrow-down {
        transform: rotate(180deg);
    }
}
::v-deep .base-dialog .base-dialog-model-con {
    min-width: 760px;
}

.wrap {
    cursor: pointer;
    min-height: 500px;
    padding: 10px;

    .wrap_title {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 49px;
        border-bottom: 1px solid #ccc;
        font-size: 18px;
        .inputSearch{
            position: absolute;
            left: 0;
        }
        .block {
            position: absolute;
            left: 280px;
            width:250px;
            ::v-deep .el-cascader {
                width: 100%;
            }
        }
        .search {
            position: absolute;
            left:560px;
        }

        @include respond-to(mb) {
            font-size: 14px;

            .titleName {
                width: 100px;
                color:#2A96FA;
                font-weight: bold;
                border-bottom: 2px solid #2A96FA;
            }
        }

        div {
            height: 50px;
            line-height: 50px;
            text-align: center;
            margin-right: 10px;
            width: 85px;
        }

        .titleName {
            width: 100px;
            margin-right: 20px;
            font-weight: bold;
            border-bottom: 2px solid #2A96FA;
        }

        .titleName2 {
            font-weight: 400;
            border-bottom: 0;
        }

        // div:nth-of-type(2) {
        //     width: 110px;
        // }
    }

    .wrap_Likes {
        text-align: center;
        font-size: 18px;

        .div {
            height: 30px;
            display: flex;
            justify-content: center;
            margin: 15px 0;
        }
        .imgWrapOops {
		margin-top: 25px;
		display: inline-block;
		width: 500px;
		height: 100px;

		img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		}
		}
		.tip {
		margin:10px 50px 15px;
		color: #999999;
		font-size: 16px;
		}

        .wrap_Likes_subTitle {
            border-radius: 20px;
            background: #E6E6E6;
            display: flex;
            width: 240px;
            color: #999999;
            font-size: 16px;
        }

        .subTitle_div {
            padding-top: 2px;
            border-radius: 20px;
            color: #fff;
            background: #2A96FA;
            font-weight: bold;
            width: 50%;
        }

        .subTitle_div2 {
            padding-top: 2px;
            font-size: 16px;
            font-family: Calibri;
            width: 50%;
        }

        .templates_div {
            max-height: 550px;
            overflow: auto;
            padding: 0 10px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 19px;
            grid-row-gap: 20px;
            .upload-area {
                    height: 16.7vw;
                    border: 1px dashed #DADCDF;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .upload-icon {
                        img {
                            width: 6.6667vw;
                            height: 5.2083vw;
                            object-fit: cover;
                        }
                    }

                    .upload-text {
                        font-size: 20px;
                    }
                }

            .box-item {
                position: relative;

                .item-name {
                    object-fit: contain;
                    border: 1px solid #E0E0E0;
                    padding: 5px;
                }

                .list-item-btn {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    &:hover {
                        background-color: rgba(0, 0, 0, .25);

                        .item-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 9px;

                            .edit {
                                background: #2A96FA;
                                color: #fff;
                                padding: .5208vw .625vw;
                                border-radius: 50%;
                                margin-right: .5208vw;
                            }

                            .del {
                                font-size: 20px;
                                background: #fff;
                                padding: .5208vw .625vw;
                                border-radius: 50%;
                            }
                        }
                    }

                    .item-btn {
                        display: none;
                    }
                }

                .item-sign {
                    text-align: center;
                    background-color: #fff;

                    .item-sign-edit {
                        display: flex;
                        height: 30px;
                        justify-content: center;
                        align-items: center;
                        margin-top: 5px;

                        .el-input {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

        .myPic {
            padding: 0 10px;
            max-height: 550px;
            overflow: auto;

            .myUpload {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 24px;
                grid-row-gap: 25px;

                .upload-area {
                    height: 16.7vw;
                    border: 1px dashed #DADCDF;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .upload-icon {
                        img {
                            width: 6.6667vw;
                            height: 5.2083vw;
                            object-fit: cover;
                        }
                    }

                    .upload-text {
                        font-size: 20px;
                    }
                }
                @media screen and (max-width: 1669px) {
                    .upload-area {
                        height: 16.4vw;
                    }
                }
                @media screen and (max-width: 1430px) {
                    .upload-area {
                        height: 16.1vw;
                    }
                }

                .upload-item {
                    min-width: 0;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;

                    .top {
                        flex: 1;
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        aspect-ratio: 1;
                        border: 1px solid #e6e6e6;
                        border-radius: 6px;
                        padding: 5px;

                        .imgWrap {
                            flex-basis: calc(50% - 4px);
                            flex-shrink: 0;
                            flex-grow: 0;
                            margin: 2px;
                            aspect-ratio: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                // object-fit: cover;
                                max-height: 7.4667vw;
                                 object-fit: contain;
                            }
                        }
                    }

                    .top.noImg {
                        .imgWrap {
                            width: 100%;
                            height: 100%;

                            img {
                                width: 100px;
                                height: 100px;
                            }
                        }
                    }

                    .bottom {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 40px;

                        .folder-name {
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .more {
                            flex-shrink: 0;
                            padding: 4px;
                            margin-left: 10px;
                            background: #edf1f5;
                            border-radius: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .templates_Cliparts {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 14px;
            grid-row-gap: 15px;
        }
    }
}

::v-deep .el-input__inner {
    height: 30px;
}

.uploadDialogCon {
    padding: 15px 30px 30px;

    .top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 20px;

        b {
            flex: 0.7;
        }

        div {
            font-size: 18px;
            font-family: Calibri;
            font-weight: bold;
            color: #333333;
        }
    }

    .left {
        color: #666666;
    }

    .con {
        overflow: hidden auto;
        display: grid;
        align-items: flex-start;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        margin: 20px 0;

        .upload-box {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1;
            background: #ffffff;
            border: 1px dashed #cccccc;

            &:hover {
                border-color: #2A96FA;
            }

            input {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                cursor: pointer;
            }

            .plus {
                font-size: 38px;
            }
        }

        .upload-item {
            overflow: hidden;
            position: relative;

            .imgWrap {
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1;

                img {
                    max-height: 108px;
                    object-fit: contain;
                }
            }
            .nameWrap {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .name {
                    overflow: hidden;
                    flex: 1;
                    margin-right: 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .edit {
                    flex-shrink: 0;
                    color: #666666;
                    cursor: pointer;
                }
            }

            .close-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
                height: 24px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 0px 0px 0px 10px;
                cursor: pointer;

                b {
                    color: #ffffff;
                }
            }
        }
    }

    .category {
        display: flex;
        align-items: center;

        .left {
            margin-right: 10px;
            font-weight: 700;
            font-size: 18px;
        }

        .right {
            flex: 1;

            .el-dropdown {
                width: 100%;

                ::v-deep .el-input__inner {
                    height: 40px;
                }
            }
        }
    }

    .btnGroup {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0 0;

        button {
            width: 130px;
        }
    }

    @include respond-to(mb) {
        padding: 17px 15px 20px;
        font-size: 12px;
        font-family: Arial;

        .top {
            padding-bottom: 0;
            border-bottom: 0;
            margin-bottom: 11px;
        }

        .con {
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 6px;
            grid-row-gap: 21px;

            .upload-box {
                span {
                    font-size: 15px;
                    margin-top: 5px;
                }

                .plus {
                    font-size: 24px;
                }
            }

            .upload-item {
                overflow: hidden;
                position: relative;

                .imgWrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    aspect-ratio: 1;
                }

                .nameWrap {
                    align-items: center;
                    margin-top: 6px;

                    .edit {
                        font-size: 15px;
                    }
                }

                .close-icon {
                    width: 14px;
                    height: 14px;
                    font-size: 12px;
                    border-radius: 0px 0px 0px 5px;

                    b {
                        transform: scale(0.5);
                    }
                }
            }
        }

        .category {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .left {
                margin-bottom: 7px;
                font-weight: 700;
                font-size: 12px;
            }

            .right {
                width: 95%;
                margin-left: 0;

                .el-input {
                    font-size: 12px;
                }

                .el-dropdown-link {
                    // width: 100%;
                    border-radius: 5px;

                    ::v-deep .el-input__inner {
                        border-radius: 5px;
                    }
                }
            }
        }

        .btnGroup {
            margin: 15px 0 0;
        }
    }
}

.myDropDown2 {
    width: 9% !important;
}

.myDropDown1 {
    width: 23%;

    .select-item {
        display: flex;
        align-items: center;

        &>span {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .el-button:nth-of-type(2) {
            color: #666666;
        }

        .el-input {
            margin-right: 10px;
        }
    }

    .select-item.isCreate {
        .circle {
            background: #2A96FA;
            border-color: #2A96FA;
            color: #ffffff;
        }
    }

    .select-item.selected:not(.isCreate) .circle {
        background: #2A96FA;
        border-color: #2A96FA;

        &::after {
            content: "";
            width: 6px;
            height: 6px;
            background: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }
    }

    .circle {
        flex-shrink: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 12px;
    }
}

.createUploadFolderCon {
    padding: 1rem;

    input {
        width: 100%;
        height: 40px;
        padding: 0 1rem;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        margin: 10px 0;
    }

    .btnGroup {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.myCustomUploadBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 2;

    .topBar {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 18px;
        height: 50px;
        border-bottom: 1px solid #ccc;

        .back-icon {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    .con {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
        padding: 10px;

        .controlTab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            margin-bottom: 10px;

            ::v-deep .el-input__inner {
                height: 40px;
            }
            .right{
                display: flex;
                align-items: center;

                .right-del{
                    border-right: 1px solid #ADADAD;
                    margin-right: 21px;
                    padding-right: 19px;
                    b{
                        color: #ADADAD;
                    }
                }
            }
        }

        .boxWrap {
            overflow: hidden auto;
            flex: 1;
            display: grid;
            align-content: flex-start;
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 10px;

            .box-item {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;
                aspect-ratio: 1;
            }
        }
    }
}

::v-deep .drawerWrap {
    height: auto !important;
    max-height: 90%;
    font-size: 14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

::v-deep .el-drawer__header {
    margin-bottom: 0;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 14px;
}

@media screen and (max-width: 450px) {
    .wrap_Likes {
        .wrap_Likes_subTitle {
            // max-width: 70vw !important;
        }
    }
}

@include respond-to(mb) {
    .status-div {
        max-height: 550px;
        overflow: auto;
    }

    .myDropDown2 {
        width: 40% !important;
    }

    ::v-deep .el-input__icon {
        line-height: 33px;
    }

    .wrap {
        padding: 0;

        .wrap_Likes {
            .wrap_Likes_subTitle {
                max-width: 100%;
            }

            .templates_div {
                padding: 0 13px 13px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 10px;
                grid-row-gap: 10px;

                .box-item {

                    .item-sign {

                        div:nth-of-type(2) {
                            color: #999999;
                        }

                        .item-sign-edit {

                            .el-input {
                                border-bottom: 0;
                                margin-bottom: 5px;
                                margin-right: 6px;

                                ::v-deep .el-input__inner {
                                    border: 1px solid #ccc;
                                }
                            }
                        }
                    }

                    .del {
                        position: absolute;
                        right: 0;
                        top: 0;
                        margin-right: 5px;
                        margin-top: 5px;
                        color: #B3B3B3;
                    }
                }
            }

            .templates_div2 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-row-gap: 13px;
            }

            .myPic {
                padding: 0 10px;

                .myUpload {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 10px;
                    grid-row-gap: 15px;

                    .upload-area {
                        height: 47.2vw;

                        .upload-text {
                            font-size: 3.6vw;
                        }

                        .upload-icon {
                            color: #999999;
                            font-size: 30px;
                        }
                    }

                    .upload-item {
                        min-width: 0;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;

                        .top {
                            flex: 1;
                            position: relative;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            aspect-ratio: 1;
                            border: 1px solid #e6e6e6;
                            border-radius: 6px;
                            padding: 5px;

                            .imgWrap {
                                flex-basis: calc(50% - 4px);
                                flex-shrink: 0;
                                flex-grow: 0;
                                margin: 2px;
                                aspect-ratio: 1;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    max-height: 19.4667vw;
                                    object-fit: contain;
                                }
                            }
                        }

                        .top.noImg {
                            .imgWrap {
                                width: 100%;
                                height: 100%;

                                img {
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                        }

                        .bottom {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 40px;

                            .folder-name {
                                flex: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                                .el-input {
                                    margin-top: 1px;
                                }
                            }

                            .more {
                                flex-shrink: 0;
                                padding: 4px;
                                margin-left: 10px;
                                background: #edf1f5;
                                border-radius: 8px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .templates_Cliparts {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .myCustomUploadBox {
            .boxWrap {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 5px;
                grid-row-gap: 5px;
            }

            b {
                font-size: 12px;
            }
            .controlTab {

                .el-dropdown{
                    width: 50%;
                }
                ::v-deep .el-input__inner {
                    height: 30px;
                }
            .right{
                display: flex;
                align-items: center;
                justify-content: right;

                .right-del{
                    border-right: 1px solid #ADADAD;
                    margin-right: 10px;
                    padding-right: 15px;
                    height: 18px;
                    line-height: 18px;
                    b{
                        font-size: 16px;
                        color: #ADADAD;
                    }
                }
            }
        }
        }

        ::v-deep .base-dialog .base-dialog-model-con {
            border-radius: 10px;

            .createUploadFolderCon {
                .categoryFolderCon {
                    display: flex;
                    justify-content: space-between;

                    div:nth-of-type(1) {
                        font-size: 20px;
                        font-family: Arial;
                        font-weight: bold;
                        margin-bottom: 18px;
                    }

                    b {
                        font-size: 10px;
                    }
                }

                .el-button {
                    width: 110px;
                    margin-top: 5px;
                }
            }
        }
    }

    .myDropDown1 {
        width: 84%;
    }
}
</style>
