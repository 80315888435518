// // 根据script src的hash值 实现前端更新后，单页面的地址部署刷新
// let prevSrcs; // 上一次获取到的script链接地址
// // 匹配过滤script标签
// const scriptReg = /<script.*?src=["']([^"']+)/gm;

// /**
//  * 获取最新页面中的 script 链接地址
//  */
// async function extractNewScript() {
// 	// 请求服务器
// 	const html = await fetch("/?_timestamp=" + Date.now()).then((resp) => resp.text());
// 	let result = [];
// 	let match;
// 	while ((match = scriptReg.exec(html))) {
// 		result.push(match[1]); // 使用 match[1] 获取匹配到的 src 属性值
// 	}
// 	// 记录这一次请求首页中所有的 JS 地址
// 	return result;
// }

// // 调用 extractNewScript 函数并处理更新逻辑
// async function handleUpdate() {
// 	const nextScrs = await extractNewScript();
// 	if (!prevSrcs) {
// 		prevSrcs = nextScrs;
// 		return;
// 	}
// 	let hasChanged = false;
// 	if (prevSrcs.length !== nextScrs.length) {
// 		hasChanged = true;
// 	} else {
// 		for (let i = 0; i < prevSrcs.length; i++) {
// 			if (prevSrcs[i] !== nextScrs[i]) {
// 				hasChanged = true;
// 				break;
// 			}
// 		}
// 	}
// 	prevSrcs = nextScrs;

// 	if (hasChanged) {
// 		const confirmResult = confirm("页面有更新，点击确定刷新页面");
// 		if (confirmResult) {
// 			location.reload();
// 		}
// 	}
// }

// //生产环境执行
// if (process.env.NODE_ENV === "production") {
// 	setInterval(() => {
// 		handleUpdate();
// 	}, 30000);
// }

let prevSrcs = null; // 上一次获取的 script 地址

async function extractNewScript() {
	// 获取最新 HTML
	const html = await fetch(`/?_=${Date.now()}`).then((resp) => resp.text());

	// 使用 DOM 解析而不是正则
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, "text/html");

	// 获取所有 script[src] 地址
	return Array.from(doc.querySelectorAll("script[src]")).map((script) => script.src);
}

async function handleUpdate() {
	const nextSrcs = await extractNewScript();
	if (!prevSrcs) {
		prevSrcs = nextSrcs;
		return;
	}

	// 检测 JS 资源是否变更
	let hasChanged = prevSrcs.length !== nextSrcs.length || prevSrcs.some((src, i) => src !== nextSrcs[i]);

	prevSrcs = nextSrcs; // 更新缓存

	if (hasChanged) {
		//特殊场景。 工厂大屏自动更新弹框取消
		if (
			window.location.href.indexOf("factoryScreen/home") > -1 ||
			window.location.href.indexOf("factoryScreen/homeiframe") > -1
		) {
			console.log("工厂大屏不需要更新弹框");
			localStorage.setItem("factoryScreen", 1);
		} else {
			if (confirm("页面有更新，点击确定刷新页面")) {
				location.reload();
			}
		}
	}
}

// 生产环境执行
let intervalId;
function startUpdateCheck() {
	intervalId = setInterval(() => {
		handleUpdate();
	}, 30000);
}

function stopUpdateCheck() {
	clearInterval(intervalId);
}

// if (typeof process !== "undefined" && process.env.NODE_ENV === "production") {
startUpdateCheck();
window.addEventListener("beforeunload", stopUpdateCheck);
// }
