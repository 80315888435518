import layout from "@/views/layout/index.vue";
const customIndex = () => import("@/views/index.vue");
//uspp管理
const agentManagement = () => import("@/views/FranchiseeManagement/uspp/agentManagement.vue");
const usppProjectManage = () => import("@/views/FranchiseeManagement/uspp/projectManage.vue");
const usppPage = () => import("@/views/FranchiseeManagement/uspp/usppPage.vue");
const crowdManage = () => import("@/views/FranchiseeManagement/uspp/crowdManage.vue");
const contactManage = () => import("@/views/FranchiseeManagement/uspp/contactManage.vue");
const FAQManage = () => import("@/views/FranchiseeManagement/uspp/FAQManage.vue");
const resourceManage = () => import("@/views/FranchiseeManagement/uspp/resourceManage.vue");
const agentManage = () => import("@/views/FranchiseeManagement/uspp/agentManage.vue");
const subQueryManage = () => import("@/views/FranchiseeManagement/uspp/subQueryManage.vue");
const agentUserClause = () => import("@/views/FranchiseeManagement/uspp/agentUserClause.vue");
const agentwebsiteManagement = () => import("@/views/FranchiseeManagement/uspp/agentwebsiteManagement.vue");
export default {
    path: "/FranchiseeManagement",
    name: "FranchiseeManagement",
    component: layout,
    meta: {
        title: "加盟商管理",
        icon: "el-icon-s-management"
    },
    children: [
        {
            path: "uspp",
            name: "uspp",
            component: customIndex,
            meta: {
                title: "USPP管理"
            },
            children: [
                {
                    path: "projectManage",
                    component: usppProjectManage,
                    name: "projectManage",
                    meta: {
                        title: "网站项目管理"
                    }
                },
                {
                    path: "usppPage",
                    name: "usppPage",
                    component: usppPage,
                    meta: {
                        title: "页面管理",
                        type: 0
                    }
                },
                {
                    path: "crowdManage",
                    name: "crowdManage",
                    component: crowdManage,
                    meta: {
                        title: "人群管理",
                        type: 1
                    }
                },
                {
                    path: "contactManage",
                    name: "contactManage",
                    component: contactManage,
                    meta: {
                        title: "联系管理",
                        type: 2
                    }
                },
                {
                    path: "FAQManage",
                    name: "FAQManage",
                    component: FAQManage,
                    meta: {
                        title: "FAQ管理",
                        type: 3
                    }
                },
                {
                    path: "resourceManage",
                    name: "resourceManage",
                    component: resourceManage,
                    meta: {
                        title: "资源管理",
                        type: 4
                    }
                },

                {
                    path: "agentManage",
                    name: "agentManage",
                    component: agentManage,
                    meta: {
                        title: "Agent管理",
                        type: 6
                    }
                },
                {
                    path: "subQueryManage",
                    name: "subQueryManage",
                    component: subQueryManage,
                    meta: {
                        title: "订阅查询管理",
                        type: 7
                    }
                },
                {
                    path: "agentUserClause",
                    name: "agentUserClause",
                    component: agentUserClause,
                    meta: {
                        title: "代理商条款",
                        type: 0
                    }
                },
                {
                    path: "agentManagement",
                    name: "agentManagement",
                    component: agentManagement,
                    meta: {
                        title: "代理商管理",
                        type: 0
                    }
                },
                {
                    path: "agentwebsiteManagement",
                    component: agentwebsiteManagement,
                    name: "agentwebsiteManagement",
                    meta: {
                        title: "代理商网站管理"
                    }
                }
            ]
        }
    ]
}