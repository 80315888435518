/**
 * 保存json为Excel文件
 * @param {*} data
 * @param {*} filename  文件名后缀为.xlsx
 */
export function saveJsonToExcel(data, filename) {
	let sheet = XLSX.utils.json_to_sheet(data);
	sheet["!cols"] = [{ wch: 20 }, { wch: 10 }, { wch: 35 }, { wch: 10 }, { wch: 15 }, { wch: 35 }];
	let workbook = {
		SheetNames: ["sheet1"],
		Sheets: {
			sheet1: sheet,
		},
	};
	XLSX.writeFileXLSX(workbook, filename, {
		bookType: "xlsx",
		bookSST: true,
		type: "array",
	});
}

// 多个工作区（sheet）
export function saveJsonToExcelMulti(dataArray, filename) {
	let workbook = {
		SheetNames: [],
		Sheets: {},
	};

	dataArray.forEach((data, index) => {
		let sheetName = "sheet" + (index + 1);
		let sheet = XLSX.utils.json_to_sheet(data);

		//设置导出列宽
		const firstItem = data[0];
		const columnWidth = Object.keys(firstItem).length;
		const columnWidthSpec = { wch: 15 };
		const columnWidths = new Array(columnWidth).fill(columnWidthSpec);
		sheet["!cols"] = [{ wch: 20 }, ...columnWidths]; //第一列20 后面的15

		workbook.SheetNames.push(sheetName);
		workbook.Sheets[sheetName] = sheet;
	});

	XLSX.writeFileXLSX(workbook, filename, {
		bookType: "xlsx",
		bookSST: true,
		type: "array",
	});
}

export function downloadFile(blob, name) {
	let reader = new FileReader();
	reader.readAsDataURL(blob);
	reader.onload = (e) => {
		let a = document.createElement("a");
		a.download = name;
		a.href = e.target.result;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
}

export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path);
}

export const deviceType = () => {
	/*
      $breakpoint-sm: 375px !default;
      $breakpoint-md: 768px !default;
      $breakpoint-lg: 1024px !default;
      $breakpoint-xl: 1500px !default;
       */
	let bodyWidth = document.body.clientWidth;
	if (bodyWidth >= 1500) {
		return {
			type: "pc",
		};
	} else if (bodyWidth >= 768 && bodyWidth < 1499) {
		return {
			type: "ipad",
		};
	} else {
		return {
			type: "mb",
		};
	}
};

export function dateFormat(fmt, date) {
	let ret;
	const opt = {
		"Y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"H+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"S+": date.getSeconds().toString(), // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
		}
	}
	return fmt;
}

export const formatDate = (date) => {
	if (!date) return null;

	const [year, month, day] = date.split("-");
	return `${month}/${day}/${year}`;
};

export const copyContent = (val) => {
	if (navigator.clipboard) {
		// clipboard api 复制
		navigator.clipboard.writeText(val);
	} else {
		var textarea = document.createElement("textarea");
		textarea.addEventListener("focusin", (e) => e.stopPropagation());
		textarea.setAttribute("readonly", "readonly");
		document.body.appendChild(textarea);
		// 隐藏此输入框
		textarea.style.position = "fixed";
		textarea.style.clip = "rect(0 0 0 0)";
		textarea.style.top = "10px";
		// 赋值
		textarea.value = val;
		// 选中
		textarea.select();
		// 复制
		document.execCommand("copy", true);
		// 移除输入框
		document.body.removeChild(textarea);
	}
};

// 一键复制 type对应textarea的id, val需要拼接复制的值
export const oneClickCopy = (val, type) => {
	if (navigator.clipboard) {
		// clipboard api 复制
		navigator.clipboard.writeText(val);
	} else {
		let textarea = document.getElementById(type);
		textarea.addEventListener("focusin", (e) => e.stopPropagation());
		textarea.setAttribute("readonly", "readonly");
		document.body.appendChild(textarea);
		textarea.value = val;
		// 选中
		textarea.select();
		// 复制
		document.execCommand("copy", true);
		// 移除输入框
		// document.body.removeChild(textarea);
	}
};

export const formatTime = (fmt) => {
	var o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		S: this.getMilliseconds(), //毫秒
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (var k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
		}
	}
	return fmt;
};

export const getTotalPageNum = (totalRecord, pageSize) => {
	return parseInt((totalRecord + pageSize - 1) / pageSize);
};

//获取随机字符串
export const getRandomString = (len) => {
	const date = new Date();
	let month = date.getMonth() + 1;
	if (month >= 1 && month <= 9) {
		month = "0" + month;
	}
	let strDate = date.getDate();
	if (strDate >= 0 && strDate <= 9) {
		strDate = "0" + strDate;
	}
	const currentDate = date.getFullYear() + month + strDate;

	len = len || 32;
	const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
	const maxPos = chars.length;
	let pwd = "";
	for (let i = 0; i < len; i++) {
		pwd += chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return currentDate + pwd;
};

/**
 * 获取文件后缀名
 * @return {string}   文件后缀名
 * @param filePath
 */
export const getFileSuffix = (filePath) => {
	// 截取.和文件类型后面的字符之间的文件类型
	const pos = filePath.lastIndexOf(".");
	let suffix = "";
	if (pos !== -1) {
		suffix = filePath.substring(pos);
		const end = suffix.indexOf("?");
		if (end !== -1) {
			let result = "";
			result = suffix.substring(0, end);
			return result.toLowerCase();
		} else {
			return suffix.toLowerCase();
		}
	}
};

/**
 * 获取文件名称前缀
 * @param filePath
 * @returns {string}
 */
export const getFilePrefix = (filePath) => {
	const pos = filePath.lastIndexOf(".");
	let prefix = "";
	if (pos !== -1) {
		prefix = filePath.substring(0, pos);
	}
	return prefix;
};

/**
 * 根据文件类型生成随机文件名
 * @param file
 */
export const generateRandomFileName = (file) => {
	let randomName = getRandomString(6);
	randomName += getFileSuffix(file.name);
	return randomName;
};

//获取文件名称
export const getFileName = (filePath) => {
	const pos = filePath.lastIndexOf("/");
	let filename = "";
	if (pos !== -1) {
		filename = filePath.substring(pos + 1);
	}
	return filename.split("?")[0];
};

// 合成版
/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export const debounce = (func, wait, immediate) => {
	let timer;
	return function () {
		let context = this,
			args = arguments;
		if (timer) clearTimeout(timer);
		if (immediate) {
			let callNow = !timer;
			timer = setTimeout(() => {
				timer = null;
			}, wait);
			if (callNow) {
				func.apply(context, args);
			}
		} else {
			timer = setTimeout(() => {
				func.apply(context, args);
			}, wait);
		}
	};
};
export const mergeImg = (picArr) => {
	return new Promise(async (resolve) => {
		let canvas = document.createElement("canvas");
		let context = canvas.getContext("2d");
		let positionX = 0,
			canvasWidth = 0,
			canvasHeight = 0,
			imgArr = [];
		//先加载完图片，定好canvas的宽高
		for (let i = 0; i < picArr.length; i++) {
			const pic = picArr[i];
			let img = await loadImage(pic);
			// 绘制
			canvasWidth += img.width;
			canvasHeight = Math.max(canvasHeight, img.height);
			imgArr.push(img);
		}
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;
		//画图
		for (let i = 0; i < imgArr.length; i++) {
			const img = imgArr[i];
			context.drawImage(img, positionX, 0, img.width, img.height);
			positionX += img.width;
		}
		resolve(canvas.toDataURL("image/png"));
	});
};

export const isBase64 = (url) => {
	return url.indexOf("data:image") > -1 ? true : false;
};

export const urlAddVersion = (url) => {
	if (isBase64(url)) {
		return url;
	}
	const urlObject = new URL(url);
	const searchParams = new URLSearchParams(urlObject.search);
	// 添加版本号参数
	searchParams.set("versions", generateUUID());
	// 重新构建 URL
	urlObject.search = searchParams.toString();
	console.log(urlObject.toString());
	return urlObject.toString();
};

export const generateUUID = () => {
	let d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now(); //use high-precision timer if available
	}
	let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
};
export const loadImage = (url) => {
	return new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = () => resolve(img);
		img.onerror = reject;
		img.src = url;
		img.crossOrigin = "Anonymous";
	});
};
export const dataURLtoFile = (dataurl, filename) => {
	let arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename + ".png", {
		type: "image/png",
	});
};
export const changeImgColor = (url, colorObj, callback) => {
	loadImage(url).then((myImage) => {
		let oldColor = colorRgb(colorObj.oldColor);
		oldColor = oldColor.substring(4, oldColor.length - 1).split(",");
		let newColor = colorRgb(colorObj.newColor);
		newColor = newColor.substring(4, newColor.length - 1).split(",");
		let canvas = document.createElement("canvas");
		let ctx = canvas.getContext("2d");
		let originW = myImage.width;
		let originH = myImage.height;
		canvas.width = originW;
		canvas.height = originH;
		ctx.drawImage(myImage, 0, 0);
		let myImageData = ctx.getImageData(0, 0, originW, originH);
		let picLength = myImageData.data.length;
		for (let i = 0; i < picLength; i += 4) {
			let juli = Math.sqrt(
				Math.pow(myImageData.data[i] - oldColor[0], 2) +
					Math.pow(myImageData.data[i + 1] - oldColor[1], 2) +
					Math.pow(myImageData.data[i + 2] - oldColor[2], 2),
			);
			if (juli === 0) {
				myImageData.data[i] = newColor[0];
				myImageData.data[i + 1] = newColor[1];
				myImageData.data[i + 2] = newColor[2];
			}
		}
		ctx.putImageData(myImageData, 0, 0);
		let dataUrl = canvas.toDataURL();
		if (typeof callback === "function") {
			callback(dataUrl);
		}
	});
};
export const colorRgb = (sColor) => {
	if (!sColor) {
		return false;
	}
	sColor = sColor.toLowerCase();
	//十六进制颜色值的正则表达式
	let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
	// 如果是16进制颜色
	if (sColor && reg.test(sColor)) {
		if (sColor.length === 4) {
			let sColorNew = "#";
			for (let i = 1; i < 4; i += 1) {
				sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
			}
			sColor = sColorNew;
		}
		//处理六位的颜色值
		let sColorChange = [];
		for (let i = 1; i < 7; i += 2) {
			sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
		}
		return "rgb(" + sColorChange.join(",") + ")";
	}
	return sColor;
};
export const pdfToImg = (src) => {
	return new Promise(function (resolve) {
		let pdfjsLib = window["pdfjsLib"];
		pdfjsLib.GlobalWorkerOptions.workerSrc =
			"https://cdn.jsdelivr.net/npm/pdfjs-dist@3.1.81/build/pdf.worker.min.js";
		let loadingPdf = pdfjsLib.getDocument(src);
		loadingPdf.promise.then((pdf) => {
			let pageNumber = 1;
			pdf.getPage(pageNumber)
				.then((page) => {
					let scale = 1; //缩放倍数，1表示原始大小
					let viewport = page.getViewport({
						scale: scale,
					});
					let canvas = document.createElement("canvas");
					let context = canvas.getContext("2d"); //创建绘制canvas的对象
					canvas.height = viewport.height; //定义canvas高和宽
					canvas.width = viewport.width;
					let renderContext = {
						canvasContext: context,
						viewport: viewport,
					};
					let renderTask = page.render(renderContext);
					renderTask.promise.then(function () {
						resolve(canvas.toDataURL());
					});
				})
				.catch((err) => {});
		});
	});
};

export const deepClone = (obj, clonedObjects = new WeakMap()) => {
	if (obj === null || typeof obj !== "object") {
		return obj;
	}

	if (clonedObjects.has(obj)) {
		return clonedObjects.get(obj);
	}

	const clonedObj = Array.isArray(obj) ? [] : {};

	clonedObjects.set(obj, clonedObj);

	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			clonedObj[key] = deepClone(obj[key], clonedObjects);
		}
	}
	return clonedObj;
};

// 在template中使用中使用可选链式调用
export function variableJudge(obj, keyName) {
	if (!obj) return null;
	let keys = (keyName + "").split(".");
	let tempObj = obj;
	for (let i = 0; i < keys.length; i++) {
		if (!tempObj) return;
		if (keys[i] !== "") tempObj = tempObj?.[keys[i]];
	}
	return tempObj;
}

export const isImageType = (str) => {
	let newStr = getFileSuffix(str);
	return [".png", ".jpg", ".jpeg", ".bmp", ".gif", ".webp", ".psd", ".svg", ".tiff", ".jfif"].indexOf(newStr) !== -1;
};

export const isVideoType = (str) => {
	let newStr = getFileSuffix(str);
	return [".avi", ".mp4", ".rmvb"].indexOf(newStr) !== -1;
};

export const urlToBase64 = (url) => {
	return new Promise((resolve, reject) => {
		let image = new Image();
		image.onload = function () {
			let canvas = document.createElement("canvas");
			canvas.width = this.naturalWidth;
			canvas.height = this.naturalHeight;
			// 将图片插入画布并开始绘制
			canvas.getContext("2d").drawImage(image, 0, 0);
			// result
			let result = canvas.toDataURL("image/png");
			resolve(result);
		};
		image.setAttribute("crossOrigin", "Anonymous");
		image.src = url;
		// 图片加载失败的错误处理
		image.onerror = () => {
			reject(new Error("urlToBase64 error"));
		};
	});
};

export const fileToBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsDataURL(file);
	});
};

export const base64toBlob = (dataurl) => {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
};

export const downFile = async (fileStr, fileName = "download") => {
	if (!fileStr) {
		return "";
	}
	const anchorEl = document.createElement("a");
	anchorEl.href = fileStr;
	anchorEl.download = fileName;
	document.body.appendChild(anchorEl); // required for firefox
	anchorEl.click();
	anchorEl.remove();
};

export function newDownFile(fileStr, fileName = "download") {
	return new Promise((resolve) => {
		fetch(fileStr).then((res) =>
			res.blob().then((blob) => {
				const a = document.createElement("a"), // 动态创建a标签，防止下载大文件时，用户没看到下载提示连续点击
					url = window.URL.createObjectURL(blob),
					filename = fileName;
				a.href = url;
				a.download = filename;
				a.click();
				window.URL.revokeObjectURL(url);
				resolve();
			}),
		);
	});
}

// 兼容写法，获取滚动条的高度
export const getWindowScrollTop = () => {
	let scroll_top = 0;
	if (document.documentElement && document.documentElement.scrollTop) {
		scroll_top = document.documentElement.scrollTop;
	} else if (document.body) {
		scroll_top = document.body.scrollTop;
	}
	return scroll_top;
};

export const scrollToViewCenter = (el, offset = 0) => {
	const { top, height } = el.getBoundingClientRect();
	// 元素的中心高度
	const elCenter = top + height / 2;
	// 窗口的中心高度
	const center = window.innerHeight / 2;
	window.scrollTo({
		top: getWindowScrollTop() - (center - elCenter) - offset,
		behavior: "smooth",
	});
};

export const scrollToViewTop = (el, offset = 0) => {
	if (!el) {
		return;
	}
	const { top, height } = el.getBoundingClientRect();
	window.scrollTo({
		top: getWindowScrollTop() + top - 100 - offset,
		behavior: "smooth",
	});
};

export const getYxUrl = (url) => {
	let regex = /^(https?:\/\/)/i;
	if (regex.test(url)) {
		return url;
	} else {
		return "https://" + url;
	}
};

// Math.round()四舍五入,保留小数点两位后, 增加精度解决toFixed小数点第3个5不进位
export const getFixed = (number) => {
	let decimalPlaces = 2;
	const factor = Math.pow(10, decimalPlaces + 1); // 增加一位精度
	return (Math.round((number * factor) / 10) / (factor / 10)).toFixed(decimalPlaces);
};

export const hasRoute = (name, routeList) => {
	for (let i = 0; i < routeList.length; i++) {
		if (routeList[i].name === name) {
			return true;
		}
		if (routeList[i].children) {
			let flag = hasRoute(name, routeList[i].children);
			if (flag) {
				return flag;
			}
		}
	}
	return false;
};

export const replacePicPrefix = (str) => {
	if (!str) {
		return "";
	}
	return str.replace(/static-oss.gs-souvenir.com/i, "oss-static-cn.liyi.co");
};

export const loadCss = (href) => {
	let linkElement = document.createElement("link");
	linkElement.rel = "stylesheet";
	linkElement.href = href;
	document.head.appendChild(linkElement);
};

/**
 *
 * @param link 文件链接
 * @returns {string}
 */
export const getFileType = (link) => {
	// 从链接中提取文件名和扩展名
	const filename = link.substring(link.lastIndexOf("/") + 1);
	const extension = filename.substring(filename.lastIndexOf(".") + 1);

	// 根据扩展名判断文件类型
	const imageExtensions = ["jpg", "jpeg", "png", "gif"];
	const videoExtensions = ["mp4", "avi", "mov"];
	const audioExtensions = ["mp3", "wav", "ogg"];

	if (imageExtensions.includes(extension)) {
		return "image";
	} else if (videoExtensions.includes(extension)) {
		return "video";
	} else if (audioExtensions.includes(extension)) {
		return "audio";
	} else {
		return "unknown";
	}
};

export const smoothScrollToTop = (options) => {
	const container = options.container;
	const content = options.content;
	const targetTop = content.offsetTop; // 目标滚动位置的顶部偏移量
	const duration = options.duration || 300; // 滚动持续时间（毫秒）
	const easing = easeInOutQuad; // 缓动函数

	const start = container.scrollTop; // 获取起始滚动位置
	const startTime = "now" in window.performance ? performance.now() : new Date().getTime(); // 获取起始时间

	function scroll() {
		const currentTime = "now" in window.performance ? performance.now() : new Date().getTime(); // 获取当前时间
		const elapsed = currentTime - startTime; // 计算已经过去的时间
		// 根据缓动函数计算当前滚动位置
		container.scrollTop = easing(elapsed, start, targetTop - start, duration);
		// 继续滚动直到达到目标位置
		if (elapsed < duration) {
			requestAnimationFrame(scroll);
		}
	}

	// 缓动函数（这是一个简单的缓动函数示例）
	function easeInOutQuad(t, b, c, d) {
		t /= d / 2;
		if (t < 1) return (c / 2) * t * t + b;
		t--;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	}

	// 开始滚动
	scroll();
};

export const analyzeImageColor = (url, options) => {
	return new Promise((resolve) => {
		const colorWorker = new Worker("/worker/getImageColor.worker.js");
		colorWorker.onmessage = (event) => {
			resolve(event.data);
		};
		colorWorker.postMessage({
			url,
			...options,
		});
	});
};

/**
 * 获取浏览器默认语言
 * @returns {String}
 */
export const getBrowserLang = () => {
	const browserLang = navigator.language || navigator.browserLanguage;
	return ["cn", "zh", "zh-cn"].includes(browserLang.toLowerCase()) ? "zh" : "en";
};

/**
 * 使用递归过滤出需要渲染在左侧菜单的列表
 * 		1. 剔除 hidden === true 或者 没有 meta 字段的
 * 		2. 如果一个菜单下有且仅有一个展示的子菜单则直接将该子菜单用于展示
 * @param menuList
 * @returns {*}
 */
export const getShowMenuList = (menuList) => {
	let newMenuList = JSON.parse(JSON.stringify(menuList));
	return newMenuList.filter((item) => {
		item.children?.length && (item.children = getShowMenuList(item.children));
		if (item.children?.length === 1) {
			const child = item.children[0];
			if (!child.children || child.children.length === 0) {
				Object.assign(item, child, {
					meta: {
						...item?.meta,
						...child?.meta,
					},
				});
				delete item.children;
			}
		}
		return !item.hidden && item.meta;
	});
};

// 驼峰转下划线
export const camelToUnderscore = (input) => {
	return input.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
};

export function checkFile(files, acceptFileType, acceptFileSize = 80) {
	let acceptFileTypeArr = acceptFileType.split(",").map((item) => item.toLowerCase()),
		checkFileType = true;
	files.forEach((item) => {
		let suffix = getFileSuffix(item.name).toLowerCase();
		if (!acceptFileTypeArr.includes(suffix)) {
			checkFileType = false;
		}
	});
	if (!checkFileType) {
		return false;
	}
	const result = files.filter((file) => file.size / 1024 / 1024 <= acceptFileSize);
	const fileName = files
		.filter((file) => file.size / 1024 / 1024 > acceptFileSize)
		.map((item) => ({ fileName: item.name }));
	let fileSize = [...new Map(fileName.map((item) => [item.fileName, item])).values()].map((item) => ({
		fileName: item.fileName,
	}));
	return {
		nomalSize: result,
		overSize: fileSize,
	};
}
