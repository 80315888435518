/**
 * @api:     阿里云OSS服务API
 * @author:  xec
 */
import axios from 'axios';
export let CancelToken = axios.CancelToken;//axios的config中提供了一个cancelToken属性，可以通过传递一个新的CancelToken对象来在请求的任何阶段关闭请求。
export let source;
// 创建axios实例
const request = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '' : '',
    // timeout: 10000
})

//oss获取签名
export function getSignature(url,data) {
    return request({
        url: url,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


//上传至oss
export function uploadToOss(url,data,cb) {
    source = CancelToken.source();
    return request({
        url: url,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        cancelToken: source.token,
        onUploadProgress: progressEvent => {
            if(typeof cb ==='function'){
                cb(progressEvent)
            }
        }
    })
}



