<template>
  <div class="wrap">
    <div class="left">
      <div class="left_title">
        全/半定制产品</br>
        类目模板配置
      </div>
      <div class="left_text">
        <div v-for="(item, index) in categoryTemplateList" :key="index"
          :class="isActive === item.id ? 'textT' : 'left_text_div'" @click="categoryTemplateClick(item)">
          {{ item.categoryName }}
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right_title">
        <div>{{ customName }} 类目的模板类型({{ tableData.length }})</div>
        <div class="btns">
          <el-button type="primary" @click="save" v-show="isTypeConfiguration">保存</el-button>
          <el-button type="primary" v-show="!isTypeConfiguration" @click="editTypeConfiguration">
            编辑模板类型配置
          </el-button>
          <el-button type="primary" v-show="isTypeConfiguration" @click="exitEditConfiguration">
            <b class="icon-back1"></b>&nbsp;退出
          </el-button>
        </div>
      </div>
      <div class="templateType">
        <div class="templateType_left">
          <div class="tt">查询并配置模板类型</div>
          <div class="tem">
            <div class="template_div">
              <span>待配置模板父类</span>
              <el-input placeholder="搜索ID或名称" suffix-icon="el-icon-search" v-model="input2"
                @input="getTemplateTypeList()">
              </el-input>
            </div>
            <div class="shade_group" style="overflow-y: scroll;border: 1px solid #ccc;">
              <el-tree default-expand-all style="max-height: 500px;" ref="tree" show-checkbox :data="templateList"
                node-key="id" :props="defaultProps" :default-expanded-keys="defaultId" :disabled="treeDisabled"
                @check="handleCheck" />
            </div>
            <div :class="isTypeConfiguration ? 'typeConfiguration2' : 'tem_btn'">
              <el-button @click="editTypeConfiguration">编辑模板类型配置</el-button>
            </div>
            <div class="shade_all">
              <el-checkbox label="全选" name="type" v-model="selectAll" @change="handleSelectAllChange"></el-checkbox>
              <div class="shade_btn" v-show="showSave">
                <el-button @click="templateConfirm">确定</el-button>
                <el-button @click="reset">重置</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="templateType_right">
          <div class="tt">已选模板类型及排序</div>
          <div class="table">
            <el-table :data="tableData" style="width: 100%"
              :header-cell-style="{ background: '#e1f1ff', color: '#3D3D3D' }" id="sonTable" row-key="id">
              <el-table-column prop="sort" label="排序" width="100" align="center">
                <template slot-scope="scope">
                  <div style="display: flex;width: 100%;height:100%; justify-content: center;">
                    <div v-if="isdrag === false">{{ (scope.row.sort) < 10 ? '0' : '' }}{{ scope.row.sort }}</div>
                        <div class="dragItem" v-else>
                          <span class="dragIcon">
                            <b class="icon-a-zhiding1" @click="tableToTop(scope.row, scope.$index)"
                              v-if="scope.$index !== 0"></b>
                          </span>
                          <span class="dragIcon">
                            <b class="moveDome icon-drag_indicator_black_24dp"></b>
                          </span>
                        </div>
                    </div>
                </template>
              </el-table-column>
              <el-table-column prop="templateTypePname" label="产品品类组" width="120" align="center">
              </el-table-column>
              <el-table-column label="分类级别" width="120" align="center">
                <template>
                  <div class="level_div">父分类</div>
                </template>
              </el-table-column>
              <el-table-column prop="templateTypeName" label="模版类型名称" align="center">
              </el-table-column>
              <el-table-column prop="updateTime" label="更新时间" align="center">
              </el-table-column>
              <el-table-column prop="address" label="操作" width="100" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="remove(scope.row)"
                    :disabled="isTypeConfiguration ? false : true">移除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="btns" v-show="isTypeConfiguration">
              <el-button type="primary" @click="save">保存</el-button>
              <el-button @click="exitEditConfiguration">取消</el-button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import {
  list,
  getCategoryTemplateConfigById,
  getTemplateTypeListWithLevelOne,
  updateCategoryTemplateConfig,
  delCategoryTemplateConfigById
} from "@/api/designSystem/cTConfiguration"
import { dateFormat } from "@/utils/utils";
export default {
  data() {
    return {
      dragNeedList: [],
      id: 0,
      input2: "",
      customName: "",
      treeDisabled: true,
      selectAll: false,
      isTypeConfiguration: false,
      isdrag: false,
      isActive: null,
      templateList: [],
      categoryTemplateList: [],
      tableData: [],
      defaultId: [],
      defaultProps: {
        children: 'children',
        label: 'templateTypeName',
      },
      showSave: false,
    };
  },
  methods: {
    //置頂
    tableToTop(row, index) {
      this.tableData.splice(0, 0, this.tableData.splice(index, 1)[0])
      // this.tableData.splice(index, 1)
      // this.tableData.unshift(row)
      this.dragNeedList = [].concat(this.tableData.map((item, index) => ({
        templateTypePid: item.templateTypePid,
        templateTypeId: item.templateTypeId,
        sort: index + 1,
      })));
    },

    //当节点全部选中的时候，全选框为选中状态
    handleCheck() {
      let tree = this.$refs.tree;
      let checkedNodes = tree.getCheckedNodes();
      let addNodes = this.flattenTree(this.templateList);
      console.log(checkedNodes.length, "checkedNodes.length");
      if (checkedNodes.length > 0) {
        this.showSave = true;
      } else {
        this.showSave = false;
      }

      if (checkedNodes.length === addNodes.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    flattenTree(nodes) {
      let flattened = [];
      nodes.forEach(node => {
        flattened.push(node)
        if (node.children && node.children.length > 0) {
          flattened.push(...this.flattenTree(node.children))
        }
      })
      return flattened
    },

    clearOption() {
      if (!this.templateList[0]) {
        return
      }
      //  获取根节点
      let rootNode = this.$refs.tree.getNode(this.templateList[0].id).parent;
      travelNodes(rootNode);

      function travelNodes(tmpRoot) {
        tmpRoot.checked = false;
        // 叶子节点
        if (tmpRoot.childNodes.length === 0) {
          return;
        }
        // 不是叶子节点,递归遍历
        else {
          let tmpChildNoes = tmpRoot.childNodes;
          for (let i = 0; i < tmpChildNoes.length; i++) {
            travelNodes(tmpChildNoes[i]);
          }
        }
      }
    },
    //全选
    handleSelectAllChange(e) {
      if (e) {
        this.showSave = true;
        this.$refs.tree.setCheckedNodes(this.templateList)
      } else {
        this.$refs.tree.setCheckedKeys([])
        this.clearOption();
        this.showSave = false;
      }
    },

    //确定
    templateConfirm() {
      //获取所有节点
      let childsIds = this.$refs.tree.getCheckedNodes(false, false);
      let list = [];
      childsIds.forEach((item) => {
        if (item.level === 1) {
          list.push({
            templateTypePid: item.parentId,
            templateTypeId: item.id
          })
        }
      })
      if (!list.length) {
        alert("没有配置模版")
        return false;
      }
      updateCategoryTemplateConfig({
        categoryId: this.isActive,
        list: list,
        isDelete: 0,
      }).then((res) => {
        this.selectAll = false;
        this.showSave = false;
        this.getCategoryTemplate();
        this.getTemplateTypeList();
      })
    },

    //重置
    reset() {
      this.$refs.tree.setCheckedKeys([]);
      this.clearOption()
      this.selectAll = false;
    },

    //取消
    cancel() {
      this.editTypeConfiguration();
    },
    //拖动排序
    drag() {
      const _this = this;
      const el = document.querySelector('#sonTable table tbody')
      Sortable.create(el, {
        disabled: false, // boolean 定义是否此sortable对象是否可用，为true时sortable对象不能拖放排序等功能，为false时为可以进行排序，相当于一个开关；
        handle: ".moveDome", // 格式为简单css选择器的字符串，使列表单元中符合选择器的元素成为拖动的手柄，只有按住拖动手柄才能使列表单元进行拖动
        animation: 150, // 拖拽延时
        onEnd({ newIndex, oldIndex } = {}) {
          if (newIndex == oldIndex) return;
          _this.tableData.splice(
            newIndex,
            0,
            _this.tableData.splice(oldIndex, 1)[0]
          );
          var newArray = JSON.parse(JSON.stringify(_this.tableData));
          _this.$nextTick(function () {
            let needList = newArray.map((item, index) => ({
              templateTypePid: item.templateTypePid,
              templateTypeId: item.templateTypeId,
              sort: index + 1,
            }))
            _this.dragNeedList = [].concat(needList);
          });
        },
      });
    },

    //保存
    save() {
      this.isdrag = false;
      let list = [];
      console.log(this.dragNeedList);
      this.dragNeedList.forEach((item) => {
        list.push({
          templateTypePid: item.templateTypePid,
          templateTypeId: item.templateTypeId
        })
      })
      updateCategoryTemplateConfig({
        categoryId: this.isActive,
        isDelete: 1,
        list: list,
      }).then((res) => {
        console.log(res);
        this.isTypeConfiguration = false;
        this.getCategoryTemplate();
      })
    },

    //退出
    exitEditConfiguration() {
      this.isdrag = false;
      this.isTypeConfiguration = false;
      this.tableData = [];
      this.getCategoryTemplate();
      this.getTemplateTypeList();
    },

    //编辑模版类型配置
    editTypeConfiguration() {
      this.treeDisabled = false;
      this.isdrag = true;
      this.isTypeConfiguration = true;
      this.drag();
    },
    //下拉箭头
    iconClick(item) {
      let find = this.templateList.find((i) => i.name === item.name);
      if (find) {
        find.upDown = !find.upDown;
      }
    },
    //移除
    remove(row) {
      this.id = row.id;
      this.delCategory();
    },

    delCategory() {
      delCategoryTemplateConfigById(this.id).then((res) => {
        console.log(res);
        this.getCategoryTemplate();
        this.getTemplateTypeList();
      })
    },

    categoryTemplateClick(item) {
      this.isActive = item.id;
      this.customName = item.categoryName;
      this.getTemplateTypeList();
      this.getCategoryTemplate();
    },

    //待配置模版父类
    getTemplateTypeList() {
      return new Promise(resolve => {
        getTemplateTypeListWithLevelOne({
          id: this.isActive,
          templateTypeName: this.input2,
          language: "en-us"
        }).then((res) => {
          let templateList = []
          templateList = res.data
          templateList.forEach((r) => {
            r.checked = false;
            r.upDown = false;
          })
          this.templateList = templateList;
          resolve();
        })
      })
    },

    //获取已选模版类型
    getCategoryTemplate() {
      return new Promise(resolve => {
        getCategoryTemplateConfigById({
          categoryId: this.isActive,
          language: "en-us"
        }).then((res) => {
          let data = res.data;
          data.forEach((item) => {
            item.updateTime = dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(item.updateTime)
            );
          })
          this.tableData = this.dragNeedList = data;
          resolve();
        })
      })
    },

    //获取左侧列表
    getList() {
      return new Promise(resolve => {
        list({
          language: "en-us",
        }).then((res) => {
          this.categoryTemplateList = res.data;
          resolve();
        })
      })
    }
  },
  async created() {
    await this.getList();
    if (this.categoryTemplateList.length > 0) {
      console.log(this.categoryTemplateList,"this.categoryTemplateList");
      this.isActive = this.categoryTemplateList[0].id; // 默认选中第一个元素
      this.customName = this.categoryTemplateList[0].categoryName;
    }
    await Promise.all([this.getCategoryTemplate(), this.getTemplateTypeList()]);
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-table__body{
  width: auto !important;
}
::v-deep .el-table__body-wrapper {
  height: 620px;
  overflow: auto;
}

::v-deep .el-table .cell {
  padding-left: 0;
  padding-right: 0;
}

::v-deep .el-dialog__body {
  padding: 3px 20px;
}

::v-deep .el-tree-node__expand-icon {
  position: absolute;
  right: 2%;
}

.item {
  font-size: 16px;
  padding: 6px 12px;
  margin: 0px 10px 0px 10px;
  border: solid 1px #e1f1ff;
  background-color: #e1f1ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.item+.item {
  border-top: none;
  margin-top: 6px;
}

.wrap {
  background: #f0f0f0;
  min-height: 100%;
  padding: 7px 0 0 10px;
  display: flex;
  cursor: pointer;

  .left {
    background: #fff;
    width: 259px;
    margin-right: 6px;
    padding: 15px 0 0;

    .left_title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 14px;
    }

    .left_text {
      font-size: 14px;
      font-weight: 500;
      line-height: 45px;
      max-height: 720px;
      overflow: auto;

      .left_text_div {
        padding-left: 25px;

        &:hover {
          background: #e1f1ff;
          color: #1c96f6;
        }
      }

      .textT {
        padding-left: 25px;
        background: #e1f1ff;
        color: #1c96f6;
        border-left: 3px solid #1c96f6;
      }
    }
  }

  .right {
    background: #fff;
    width: calc(100% - 259px);
    padding: 15px 24px 0 21px;

    .right_title {
      display: flex;
      justify-content: space-between;

      .btns {
        .el-button {
          width: 141px;
          height: 40px;
          font-size: 16px;
          background: #0066CC;
          border: 1px solid #0066CC;
        }

        .el-button:nth-of-type(2) {
          width: 160px;
        }
      }

      div:nth-of-type(1) {
        font-size: 24px;
        font-weight: bold;
        margin-right: 12px;
      }
    }

    .templateType {
      display: flex;

      .templateType_left {
        width: 523px;

        .tt {
          font-size: 18px;
          font-weight: normal;
          margin-bottom: 9px;
        }

        .tem {
          position: relative;
          width: 100%;
          border: 1px solid #c8c8c8;

          .template_div {
            display: flex;
            align-items: center;
            padding: 6px 16px 3px;
            background: #e1f1ff;

            span {
              font-size: 18px;
              font-weight: bold;
              margin-right: 12px;
              font-family: Source Han Sans CN;
            }

            .el-input {
              width: 344px;
            }
          }

          .shade_group {
            border: 1px solid #ccc;
            height: 569px;
            overflow: auto;
            margin: 7px 13px 0 16px;

            .el-tree {
              padding: 10px 10px 10px 20px;
            }

            ::v-deep .el-tree-node__content {
              height: 35px;

              .el-tree-node__label {
                font-size: 16px;
                color: #000;
                font-weight: bold;
              }
            }

            ::v-deep .el-tree-node__children {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              background: #f7f7f7;

              .el-tree-node__label {
                font-size: 16px;
                color: #3D3D3D;
                font-weight: 400;
              }
            }
          }

          .shade_all {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 16px;

            .el-button {
              width: 76px;
              height: 38px;
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              border-radius: 0;
            }

            .el-button:nth-of-type(1) {
              background: #0089F5;
              border: 1px solid #0089F5;
            }

            .el-button:nth-of-type(2) {
              background: #AD0000;
              border: 1px solid #AD0000;
            }
          }

          .tem_btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.3);

            .el-button {
              width: 190px;
              height: 60px;
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              border-radius: 5px;
              background: #0089F5;
              border: 1px solid #0089F5;
            }
          }

          .typeConfiguration2 {
            display: none;
          }
        }
      }

      .templateType_right {
        margin-left: 18px;
        min-height: 100%;
        width: calc(100% - 523px);

        .tt {
          font-size: 18px;
          font-weight: normal;
          margin-bottom: 9px;
        }

        .table {
          border: 1px solid #c8c8c8;

          .el-table {

            .el-button {
              text-decoration: underline;
            }
          }

          .el-table::before {
            background-color: #fff;
          }

          .dragIcon {
            display: inline-block;
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 18px;
            color: #000;
          }

          .dragItem {
            width: 50%;
            cursor: pointer;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .level_div {
            background: #c9ffde;
            color: #3db86d;
            border: 1px solid #5fc888;
            border-radius: 5px;
            margin: 0 22px;
          }

          ::v-deep .el-table__cell {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>