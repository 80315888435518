import layout from "@/views/layout/index.vue";
import fdUserModule from "@/views/FdInquiryOrderManage/userModule/index.vue";
const fdThreeDManage = () => import("@/views/FdInquiryOrderManage/threeDManage.vue");
const fdAllocationConfiguration = () => import("@/views/FdInquiryOrderManage/allocationConfiguration.vue");
const fdInquiryStatistics = () => import("@/views/FdInquiryOrderManage/inquiryStatistics.vue");
const fdOrderTimeliness = () => import("@/views/FdInquiryOrderManage/orderTimeliness.vue");
const fdMarketingordertracking = () => import("@/views/FdInquiryOrderManage/marketingordertracking.vue");
const fdInquiry = () => import("@/views/FdInquiryOrderManage/inquiry/index.vue");
const fdInquiryManage = () => import("@/views/FdInquiryOrderManage/inquiry/inquiryManage.vue");
const fdInquiryDetail = () => import("@/views/FdInquiryOrderManage/inquiry/inquiryDetail.vue");
const fdOrder = () => import("@/views/FdInquiryOrderManage/order/index.vue");
const fdLinkOrder = () => import("@/views/FdInquiryOrderManage/linkOrder/index.vue");
const fdOrderManage = () => import("@/views/FdInquiryOrderManage/order/orderManage.vue");
const fdLinkOrderManage = () => import("@/views/FdInquiryOrderManage/linkOrder/linkOrderManage.vue");
const fdRefundForm = () => import("@/views/FdInquiryOrderManage/order/refundForm.vue");
const fdOrderDetail = () => import("@/views/FdInquiryOrderManage/order/orderDetail.vue");
const fdUploadDraft = () => import("@/views/FdInquiryOrderManage/order/uploadDraft.vue");
const fdFinancialOffline = () => import("@/views/financial/financialOffline.vue");
const fdInquiryBacklogStatistics = () => import("@/views/FdInquiryOrderManage/inquiryBacklogStatistics.vue");
const contactUs = () => import("@/views/FdInquiryOrderManage/contactUs.vue");

const monthInvoice = () => import("@/views/FdInquiryOrderManage/monthInvoice/index.vue");
export default {
	path: "/FdInquiryOrderManage",
	component: layout,
	name: "FdInquiryOrderManage",
	meta: {
		title: "FD询盘订单管理",
		icon: "el-icon-tickets",
	},
	children: [
		{
			path: "inquiry",
			name: "fdInquiry",
			component: fdInquiry,
			meta: {
				title: "询盘",
			},
			children: [
				{
					path: "inquiryManage",
					name: "fdInquiryManage",
					component: fdInquiryManage,
					meta: {
						title: "询盘列表",
					},
				},
				{
					path: ":id",
					name: "fdInquiryDetail",
					component: fdInquiryDetail,
					hidden: true,
					meta: {
						title: "询盘详情",
					},
				},
			],
		},
		{
			path: "contactUs",
			name: "contactUs",
			component: contactUs,
			meta: {
				title: "Contact Us",
			},
		},
		{
			path: "linkOrder",
			name: "fdLinkOrder",
			component: fdLinkOrder,
			meta: {
				title: "链接订单",
			},
			children: [
				{
					path: "linkOrderManage",
					name: "fdLinkOrderManage",
					component: fdLinkOrderManage,
					meta: {
						title: "链接订单",
					},
				},
				{
					path: "uploadDraft",
					name: "fdLinkUploadDraft",
					component: fdUploadDraft,
					hidden: true,
					meta: {
						title: "图稿上传",
					},
				},
				{
					path: ":id",
					name: "FdLinkOrderDetail",
					component: fdOrderDetail,
					hidden: true,
					meta: {
						title: "订单详情",
					},
				},
			],
		},
		{
			path: "order",
			name: "fdOrder",
			component: fdOrder,
			meta: {
				title: "订单",
			},
			children: [
				{
					path: "orderManage",
					name: "fdOrderManage",
					component: fdOrderManage,
					meta: {
						title: "直接订单",
					},
				},
				{
					path: "uploadDraft",
					name: "fdUploadDraft",
					component: fdUploadDraft,
					hidden: true,
					meta: {
						title: "图稿上传",
					},
				},
				{
					path: ":id",
					name: "fdOrderDetail",
					component: fdOrderDetail,
					hidden: true,
					meta: {
						title: "订单详情",
					},
				},
			],
		},
		{
			path: "fdThreeDManage",
			name: "fdThreeDManage",
			component: fdThreeDManage,
			meta: {
				title: "3D订单管理",
			},
		},
		{
			path: "allocationConfiguration",
			name: "fdAllocationConfiguration",
			component: fdAllocationConfiguration,
			meta: {
				title: "分配配置",
			},
		},
		{
			path: "inquiryStatistics",
			name: "fdInquiryStatistics",
			component: fdInquiryStatistics,
			meta: {
				title: "询盘订单统计",
			},
		},
		//未成单
		{
			path: "inquiryBacklogStatistics",
			name: "fdInquiryBacklogStatistics",
			component: fdInquiryBacklogStatistics,
			meta: {
				title: "询盘未成单原因占比统计",
			},
		},
		{
			path: "",
			redirect: "userModule",
		},
		{
			path: "userModule",
			name: "fdUserModule",
			component: fdUserModule,
			meta: {
				title: "用户模块",
			},
		},
		{
			path: "orderTimeliness",
			name: "fdOrderTimeliness",
			component: fdOrderTimeliness,
			meta: {
				title: "订单时效管理",
			},
		},
		{
			path: "marketingordertracking",
			name: "fdMarketingordertracking",
			component: fdMarketingordertracking,
			meta: {
				title: "营销订单追踪",
			},
		},
		{
			path: "/financialOffline",
			name: "fdFinancialOffline",
			component: fdFinancialOffline,
			meta: {
				title: "财务线下收款",
			},
		},
		{
			path: "/monthInvoice",
			name: "monthInvoice",
			component: monthInvoice,
			meta: {
				title: "月结发票",
			},
		},
	],
};
