<template>
  <div class="contentBox">
    <div class="content">
      <div class="content_left" v-loading="loading1">
        <el-button class="CL_top_btn" type="primary" icon="el-icon-circle-plus"
          @click="btn_addFatherType('1')">新增标签分类</el-button>
        <div class="loop_box">
          <div class="loop_item" v-for="(item, index) in leftList" :key="index"
            @click="typeItemClick(item, index, item.id)" :class="{ active: item.id === DefaultId }">
            <span class="name" :title="item.name">{{ item.labelTypeName }}</span>
            <div class="more" style="display: flex;align-items: center;">
              <div @mouseenter="btnIsShoweditMenu1(item, index, item.id)" @mouseleave="btnIsShoweditMenu2"
                style="height: 48px;line-height: 48px;">
                <i class="el-icon-more" v-clickoutside="(e) => outside(e)">
                </i>
                <el-collapse-transition>
                  <div class="tooltop tooltop1" v-if="showTooltip && showTooltipIndex == index">
                    <el-card shadow="hover" class="tooltop-card">
                      <p class="menuBtn btn1" @click.stop="addSubClass(item, index, item.id)">编辑名称</p>
                      <p class="menuBtn" style="color:red;" @click.stop="del(item, index, item.id)">删除</p>
                    </el-card>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_right">
        <h2 class="regular">{{ copyCateList.labelTypeName }}标签管理</h2>
        <div style="margin: 18px 0 14px 0;display: flex;">
          <el-input placeholder="搜索标签名称" v-model="keyWord" clearable style="width: 300px;flex: none;margin:0 10px 0 12px;"
            :disabled="canOperation">
            <el-button slot="append" icon="el-icon-search" type="primary" @click="search_box"
              :disabled="canOperation"></el-button>
          </el-input>
          <el-button class="btn_margin" type="primary" icon="el-icon-circle-plus" @click="btn_OpenAddEditBox()"
            :disabled="canOperation">新增标签</el-button>
          <el-button v-if="!showSureSave" class="btn_margin" type="primary" @click="batchSort()"
            :disabled="canOperation">拖拽排序</el-button>
          <div v-if="showSureSave" style="flex: none;margin-left: 54.5%;">
            <el-button class="btn_margin" type="primary" @click="sortSave">
              <span>保存</span>
            </el-button>
            <el-button @click="sortCancellation" class="btn_margin">
              <span>取消</span>
            </el-button>
          </div>
        </div>
        <div class="table">
          <el-table ref="table2" height="630" :data="categoryList" row-key="id" :row-class-name="tableRowClassName"
            v-loading="loading" style="width: 100%">
            <el-table-column label="排序" align="center" width="200">
              <template slot-scope="scope">
                <template v-if="!sort">{{ scope.$index + 1 }}</template>
                <div v-else style="display: flex;justify-content: center;align-items: center;">
                  <!-- <el-tooltip effect="light" placement="none"> -->
                    <custom-image style="cursor: pointer;width: 30px;height: 30px;" @mousedown="dragMousedown(scope.row)"
                      src="https://oss-static-cn.liyi.co/web/quoteManage/20230810/拖拽_20230810WA43En.svg"
                      class="el-icon-sort showImg" alt="" />
                  <!-- </el-tooltip> -->
                  <custom-image style="cursor: pointer;width: 20px;height: 20px;" v-if="scope.$index != 0"
                    @click="toTop(scope.row, scope.$index)" class="showImg"
                    src="https://oss-static-cn.liyi.co/web/quoteManage/20230810/置顶1_202308106Xmmyh.svg" />
                  <div v-else style="width: 20px;height: 20px;"></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="labelName" align="center" label="标签名称">
            </el-table-column>
            <el-table-column align="center" label="标签分类">
              <template>
                {{ copyCateList.labelTypeName }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.state" active-text="启用" inactive-text="停用" :active-value='1'
                  :inactive-value='0' class="operation" @change="changeLabel(scope.row)"></el-switch>
                <el-button class="xiaHua operation" type="text" @click="editorClick(scope.row)">编辑</el-button>
                <el-button class="xiaHua operation" type="text" @click="delLabel(scope.row)"
                  style="color:red">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 新增标签分类 -->
      <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="分类标签名称" prop="name" label-width="120px">
            <el-input v-model="ruleForm.name" style="width: 65%;"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addClassification('ruleForm')">保 存</el-button>
          <!-- <el-button v-if="sync" @click="saveAndSync('ruleForm')">保存并同步多语言</el-button> -->
          <el-button @click="cellAdd('ruleForm')">取 消</el-button>
        </span>
      </el-dialog>
      <!-- 新增标签 -->
      <el-dialog :title="title1" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose1('labelForm')">
        <el-form :model="labelForm" :rules="labelRules" ref="labelForm" label-width="100px" class="demo-ruleForm">
          <!-- <el-form-item label="选择标签分类：" prop="type" label-width="120px">
            <selectCheckBox :list="options" :popoverWidth="310" @input="getVal" :checkboxWidth="120"></selectCheckBox>
          </el-form-item> -->
          <el-form-item label="标签名称：" prop="name" label-width="120px">
            <el-input v-model="labelForm.name" style="width: 65%;"></el-input>
          </el-form-item>
          <el-form-item label="分类状态：" prop="delivery" label-width="110px">
            <el-switch v-model="labelForm.switchValue" :active-value='1' :inactive-value='0' active-text="启用"
              inactive-text="停用" class="operation"></el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addLabel('labelForm')">保 存</el-button>
          <el-button @click="cell('labelForm')">取 消</el-button>
        </span>
      </el-dialog>
      <!-- 提示弹框 -->
      <messageBox :hidden="this.ishidden" :title="title" :content_text="content_text" @popup_sub="popup_submit"
        @popup_clo="popup_close" @clo_box="close_box" :sendBtnColor="sendBtnColor" :borderColor='borderColor'
        :textColor='contColor'>
      </messageBox>

    </div>
  </div>
</template>

<script>
import {
  editLabelType,
  getlabelsType,
  delLabelsType,
  editLabel,
  delLabels,
  sortLabels,
} from "@/api/systemManagement/templateManagement";
import Sortable from "sortablejs";
export default {
  name: "backstage",
  data() {
    return {
      DefaultId: '',
      loading1: false,
      loading2: true,
      title: '',
      title1: '',
      showTooltip: false,
      showTooltipIndex: null,
      dialogVisible: false,
      dialogVisible1: false,
      sync: false,
      leftList: [{
        name: 'test',
        id: 1
      }],
      ruleForm: {
        name: '',
        switchValue: 1
      },
      chooseId: null,
      nowClickParams: {},
      rules: {
        name: {
          required: true, message: '请输入活动名称', trigger: 'blur'
        }
      },
      keyWord: "",
      showSureSave: false,
      loading: false,
      categoryList: [],
      labelForm: {
        name: '',
        switchValue: 1
      },
      options: [],
      labelRules: {
        name: { required: true, message: '请输入标签名称', trigger: 'blur' },
      },
      value1: [],
      isIndeterminate: true,
      checkedCities: [],
      checkAll: false,
      sort: false,
      newTabelList: [],
      ishidden: false,
      title: '',
      content_text: '',
      sendBtnColor: '',
      borderColor: '',
      delType: '',
      contColor: '#acacac',
      copyCateList: {},
      labelId: null,
      canOperation: false,
    };
  },
  components: {
    selectCheckBox: () => import('../components/selectCheckBox.vue'),
    messageBox: () => import('../components/messageBox.vue')
  },
  watch: {
    isEditInfo(newVal) {
      if (!newVal) this.ruleForm = this.$options.data().ruleForm
      //  else 
    },

  },
  computed: {

  },
  mounted() {
    this.setRowSort();
  },
  created() {
    this.getLeftList(1)
  },
  methods: {
    // 查询左侧列表
    getLeftList(num) {
      let params = {
        language: 'en-us',
        labelName: this.keyWord
      }
      getlabelsType(params).then(res => {
        this.leftList = res.data
        if (num == 1) {
          if (res.data && res.data.length > 0) {
            this.DefaultId = res.data[0].id
            this.categoryList = res.data[0].labelsList
            this.copyCateList = res.data[0]
          } else {
            this.canOperation = true
            this.categoryList = []
          }
        } else {
          this.categoryList = res.data.filter(x => x.id == this.copyCateList.id)[0].labelsList
        }
      })
    },
    // 提示弹框方法
    popup_submit() {
      if (this.delType == 'classification') {
        console.log(333333);
        this.ishidden = false
        delLabelsType({ id: this.deleteItem.id }).then(res => {
          if (res.code == "200") {
            this.$message.success('删除成功!')
            this.getLeftList(1)
          }
        })
      } else if (this.delType == 'label') {
        delLabels({ id: this.deleteItem.id }).then(res => {
          this.ishidden = false
          if (res.code == "200") {
            this.$message.success('删除成功!')
            this.getLeftList()
          }
        })
      }
    },
    popup_close() {
      this.ishidden = false
    },
    close_box() {
      this.ishidden = false
    },
    // 查看编辑
    addSubClass(item, index, id) {
      this.title = '编辑标签类别'
      this.dialogVisible = true
      this.sync = true
      this.ruleForm.name = item.labelTypeName
      this.chooseId = item.id
    },
    // 删除
    del(item, index, id) {
      this.delType = 'classification'
      this.content_text = '注：若类别下已有标签，则无法删除'
      this.ishidden = true;
      this.deleteItem = item
      this.title = '确认删除此标签分类？'
      this.sendBtnColor = '#D61B3F'
      this.borderColor = 'transparent'
    },
    //新增标签分类
    addClassification(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.$store.state);
          let data = {
            id: this.chooseId ? this.chooseId : null,
            labelTypeName: this.ruleForm.name,
            language: 'en-us'
          }
          editLabelType(data).then(res => {
            console.log(res);
            if (res.code == '200') {
              this.$message.success(this.chooseId ? "编辑成功！" : '新增成功！')
              this.dialogVisible = false
              this.$refs[formName].resetFields();
              this.chooseId = null
              this.getLeftList()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 保存并同步多语言
    saveAndSync(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs[formName].resetFields();
          this.dialogVisible = false
          this.sync = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消新增
    cellAdd(formName) {
      this.dialogVisible = false
      this.ruleForm.name = ''
      this.$refs[formName].resetFields();
    },
    batchSort() {
      if (this.categoryList.length == 0) return
      this.showSureSave = true
      this.showSort = false
      this.sort = true
      this.keyWord = ""
      this.getLeftList()
    },
    // 搜索
    search_box() {
      this.getLeftList()
    },
    // 新增标签
    btn_OpenAddEditBox() {
      if (this.showSureSave) {
        this.$message.warning('请拖拽完成之后再新增！')
        return
      }
      this.labelId = null
      this.dialogVisible1 = true
      this.title1 = "新增标签"
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'success-row';
      }
      return '';
    },
    // cell取消新增
    cell(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible1 = false
      this.labelForm = {
        name: "",
        switchValue: 1
      }
    },
    // 新增标签
    addLabel(formName) {
      console.log(this.labelForm.switchValue);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editOneLabel()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 修改标签
    editOneLabel() {
      let data = {
        id: this.labelId ? this.labelId : null,
        labelTypeId: this.DefaultId,
        labelName: this.labelForm.name,
        state: this.labelForm.switchValue,
        language: 'en-us'
      }
      editLabel(data).then(res => {
        if (res.code == '200') {
          this.$message.success(this.labelId ? '编辑标签成功！' : '新增标签成功！')
          this.getLeftList()
          this.labelForm = {
            name: "",
            switchValue: 1
          }
          this.labelId = null
          this.dialogVisible1 = false
          this.$refs['labelForm'].resetFields();
        }
      })
    },
    getVal(val) {
      // console.log(val);
      this.labelForm.type = val
    },
    // 拖拽方法
    setRowSort() {
      const that = this
      this.$nextTick(() => {
        this.drop = Sortable.create(
          document.querySelector(".el-table .el-table__body-wrapper tbody"),
          {
            //这里指操作的图标，点击图标可以进行拖拽，若注释掉，则点击此行都可以进行拖拽
            handle: ".el-icon-sort",
            animation: 150, // 拖拽延时
            // onEnd(元素拖拽结束)
            onEnd: ({ newIndex, oldIndex }) => {
              console.log(newIndex, oldIndex);
              if (newIndex == oldIndex) return
              this.categoryList.splice(
                newIndex,
                0,
                this.categoryList.splice(oldIndex, 1)[0]
              );
              // this.newTabelList = JSON.parse(JSON.stringify(this.categoryList))
              this.$forceUpdate()
            },
          }
        );
      });
    },
    // 排序保存
    sortSave() {
      let list = []
      if (this.newTabelList.length == 0) {
        this.newTabelList = JSON.parse(JSON.stringify(this.categoryList))
      }
      this.newTabelList.forEach((i, index) => {
        list.push({
          id: i.id,
          sortNum: index + 1
        })
      })
      console.log(this.newTabelList);
      sortLabels(this.newTabelList).then(res => {
        if (res.code == '200') {
          this.$message.success('保存成功!')
          this.getLeftList()
          this.sort = false
          this.showSureSave = false
        }
      })
    },
    // 排序取消
    sortCancellation() {
      this.showSureSave = false
      this.sort = false
    },
    // 置顶
    toTop(row, index) {
      let list = JSON.parse(JSON.stringify(this.categoryList))
      list.splice(index, 1)
      list.unshift(row)
      this.newTabelList = list
      this.categoryList = list
    },
    // 标签编辑
    editorClick(row) {
      this.dialogVisible1 = true
      this.title1 = '编辑标签'
      this.labelId = row.id
      this.labelForm = {
        name: row.labelName,
        switchValue: row.state
      }
    },
    changeLabel(row) {
      this.labelId = row.id
      this.labelForm = {
        name: row.labelName,
        switchValue: row.state
      }
      this.editOneLabel()
    },
    // 删除标签
    delLabel(row) {
      this.delType = 'label'
      this.content_text = '注：若已再模板库中使用此标签，则无法执行删除操作'
      this.ishidden = true;
      this.deleteItem = row
      this.title = '确认删除此标签？'
      this.sendBtnColor = '#D61B3F'
      this.borderColor = 'transparent'
    },
    handleClose1(n) {
      // this.cell(n)
    },
    dragMousedown(row) {
      // console.log('wwwwwww', row.id);
      this.draggingData = {
        categoryId: row.categoryId,
        id: row.id,
        newSort: row.sortNum
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    btn_addFatherType(type, item) {
      if (this.showSureSave) {
        this.$message.warning('请拖拽完成之后再新增！')
        return
      }
      this.ruleForm.name = ""
      this.chooseId = null
      this.title = '添加标签类别'
      this.dialogVisible = true
    },
    typeItemClick(item, index, id) {
      if (id == this.DefaultId) return
      this.keyWord = ""
      this.DefaultId = id
      this.copyCateList = item
      this.isEditInfo = false
      // this.categoryList = item.labelsList
      this.getLeftList()
      // this.categoryList = JSON.parse(JSON.stringify(this.leftList)).filter(x => x.id == this.copyCateList.id)[0].labelsList
    },
    btnIsShoweditMenu1(item, index, id) {
      this.showTooltip = true;
      this.showTooltipIndex = index;
      this.nowClickParams['num'] = index
      // this.typeItemClick (item, index, id)
    },
    btnIsShoweditMenu2() {
      this.showTooltip = false;
    },
    outside(e) {
      if (e.target.className !== "el-icon-more") {
        this.showTooltip = false;
      }
    },
    handleClose() {

    },
  },


}
</script>

<style scoped lang="scss">
.text_content {
  ::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 0px;
  }

  ::v-deep .el-form-item--small .el-form-item__content {
    line-height: 38px;
    font-size: 13px;
  }
}

.label_title {
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  color: black;
}

::v-deep .el-input-group__append {
  font-weight: 700;
  color: black;
}

::v-deep .el-switch__label * {
  line-height: 1;
  font-size: 10px;
  display: inline-block;
}

::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
  font-size: 10px !important;
}

::v-deep .el-switch__label--right {
  z-index: 1;
  right: 20px !important;
  margin-left: 0px;
}

::v-deep .el-switch__label--left {
  z-index: 1;
  left: 20px !important;
  margin-right: 0px;
}

::v-deep .el-switch__label.is-active {
  display: block;
}

::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}

::v-deep .el-dialog__body {
  padding: 3px 20px;
}

::v-deep .el-input-group__prepend {
  background: #fff;
}

.message_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-footer {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.contentBox {
  max-width: 100% !important;
  height: calc(100vh - 90px);
  background: #f4f4f4;
}

.content {
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 90px);

  .contents {
    box-sizing: border-box;
    padding: 30px 40px;
  }

  .text_content {
    width: 56%;

    .margin {
      margin-top: 30px;
    }

    >span {
      display: flex;
      margin-top: 15px;
      flex-wrap: wrap;
    }

    >span>b {
      display: inline-block;
      width: 120px;
      font-weight: 600 !important;

      span {
        display: inline-block;
        width: calc(100% - 120px);
      }
    }

    .view {
      cursor: pointer;
      color: #0089f5;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  .dialogContent {
    // border-top: 1px solid #ccc;
    width: 700px;
    padding-top: 17px;
  }

  .content_left,
  .content_right {
    background: #fff;
    position: relative;
  }

  .content_left {
    width: 13%;
    height: 100%;
    // overflow-y: auto;
    box-sizing: border-box;
    // padding: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    .CL_top_btn {
      margin-top: 18px;
    }

    .tit {
      margin-top: 22px !important;
      display: flex;
      justify-content: space-between;
    }

    .loop_box {
      width: 99%;
      margin-top: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 100px;
      font-size: 13px;
    }

    .loop_box::-webkit-scrollbar {
      display: none;
    }

    .loop_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      margin: 0;
      padding: 0 15px;
      cursor: pointer;
      transition: all 0.4s;
      position: relative;

      .name {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        z-index: 1;
        background: #f2f2f2;
        // transform: scale(1.07, 1);
        // box-shadow: 0 0 15px #f7eeee;
      }

      &.active {
        background: lighten($color-primary, 50%);
        color: $color-primary;

        &::before {
          position: absolute;
          left: 0;
          content: '';
          height: 100%;
          width: 5px;
          background: #0089f5;
        }
      }
    }

    @keyframes rotate {
      0% {
        transform: rotateY(0deg);
      }

      100% {
        transform: rotateY(100deg);
      }
    }
  }

  // -------右-------
  .content_right {
    width: 86.5%;
    height: 100%;
    // overflow-y: auto;
    box-sizing: border-box;

    // background: #f4f4f4;
    .regular {
      margin: 24px 0 0 17px;
    }

    .tab_menu {
      display: flex;
      margin-top: 12px;
      margin-left: 10px;

      .menu_item {
        width: 125px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        background: #dcdcdc;
        margin-right: 10px;
        border: 2px solid transparent;
        transition: all 0.4s;
        cursor: pointer;
        // &:hover {
        //   background: #e3e8f0;
        // }
      }

      // .menu_item:hover {
      .z_active {
        background: #fff;
        border: 2px solid #0089f5;
        color: #0089f5;
        border-bottom-color: transparent;
        transform: scale(1.1, 1);
      }
    }

    //
    .tab_content {
      background: #fff;
      // height: calc(100% - 56px);
      height: calc(100% - 48px);
      overflow-y: auto;
    }
  }
}

// ...点击
.tooltop {
  position: absolute;
  right: -10px;
  top: 20px;
  width: 92px;
  z-index: 99;

  .tooltop-card {
    border-radius: 5px;

    ::v-deep {
      .el-card__body {
        padding: 0;
      }
    }

    .menuBtn {
      padding-left: 15px;
      height: 30px;
      line-height: 25px;
      margin: 3px 0;
    }

    .menuBtn:hover {
      cursor: pointer;
      background: lighten($color-primary, 50%);
      transform: scale(1.1, 1);
    }

    .btnDel:hover {
      color: red;
    }
  }
}

.tooltop1 {
  top: 47px;
  right: 0px;
}

.el-icon-more {
  transform: rotate(90deg);
  color: #0089f5;
  margin-left: 5px;
}

.seach_box {
  display: flex;
  align-items: flex-start;
  padding: 15px 10px;
  flex-direction: column;

  .table {
    height: 630px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    margin-top: 10px;
  }
}

.operation {
  margin: 0 10px;
}

::v-deep .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
  font-size: 12px !important;
}

/*打开时文字位置设置*/
::v-deep .el-switch__label--right {
  z-index: 1;
  right: 20px;
}

/*关闭时文字位置设置*/
::v-deep .el-switch__label--left {
  z-index: 1;
  left: 20px;
  font-size: 12px !important;
}

/*显示文字*/
::v-deep .el-switch__label.is-active {
  display: block;
}

::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 58px !important;
  font-size: 12px !important;
}

.xiaHua {
  text-decoration: underline;
}

.showImg{
  display: block !important;
}
</style>