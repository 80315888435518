import layout from "@/views/layout/index.vue";
const InternalProblemManagement = () => import("@/views/interiorProblem/index.vue");
const factoryProblem = () => import("@/views/interiorProblem/factoryProblem.vue");
export default {
    path: "/interiorProblem",
    name: "interiorProblem",
    component: layout,
    meta: {
        title: "内部问题管理",
        icon: "el-icon-guide"
    },
    children: [{
        path: "InternalProblemManagement",
        component: InternalProblemManagement,
        name: "InternalProblemManagement",
        meta: {
            title: "内部问题管理"
        }
    },
        {
            path: "factoryProblem",
            component: factoryProblem,
            name: "factoryProblem",
            meta: {
                title: "工厂反馈问题单"
            }
        }
    ]
}