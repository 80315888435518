<template>
	<div class="wrap">
		<div class="popupEditorHelpBlock step0" v-show="actived === 0">
			<div class="popupEditorHelpOverlay">
				<div class="popupEditorHelpContainer" :style="{}">
					<div class="popupEditorHelpContentStart">
						<div class="popupEditorHelpTitle">
							<span></span>
							<label
								class="popupEditorHelpMiniAction anime-1"
								style="right: 0;font-size: 18px;"
								@click="skipHelp"
							>
								<span>Skip</span>
							</label>
						</div>
						<h2>
							How to quickly get started <br> with our design system?
						</h2>
						<div class="popupEditorHelpActions">
							<label class="buttonYellow" @click="next">Let's Start</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="popupEditorHelpBlock step1" v-show="actived === 1">
			<div class="popupEditorHelpContainer" :style="stylePosition">
				<div class="popupEditorHelpContent">
					<div class="popupEditorHelpTitle">
						<span class="popupEditorHelpStepLocation"><strong>{{ actived }}</strong>/5
						</span>
						<label
							class="popupEditorHelpMiniAction anime-1"
							style="right: 0;"
							@click="skipHelp"
						>
							<span>Skip</span>
						</label>
					</div>
					<p>
						Select your favorite product template.
					</p>
					<div class="popupEditorHelpActions">
						<label class="buttonYellow small" @click="next">Next</label>
					</div>
				</div>
			</div>
		</div>
		<div class="popupEditorHelpBlock step2" v-show="actived === 2">
			<div class="popupEditorHelpContainer" :style="stylePosition">
				<div class="popupEditorHelpContent">
					<div class="popupEditorHelpTitle">
						<span class="popupEditorHelpStepLocation">
							<strong>{{ actived }}</strong
							>/5
						</span>
						<label
							class="popupEditorHelpMiniAction anime-1"
							style="right: 0;"
							@click="skipHelp"
						>
							<span>Skip</span>
						</label>
					</div>
					<p>
						Add richer text and cliparts to your design.
					</p>
					<div class="popupEditorHelpActions">
						<label class="buttonYellow small" @click="next">Next</label>
					</div>
				</div>
			</div>
		</div>
		<div class="popupEditorHelpBlock step3" v-show="actived === 3">
			<div class="popupEditorHelpContainer" :style="stylePosition">
				<div class="popupEditorHelpContent">
					<div class="popupEditorHelpTitle">
						<span class="popupEditorHelpStepLocation">
							<strong>{{ actived }}</strong
							>/5
						</span>
						<label
							class="popupEditorHelpMiniAction anime-1"
							style="right: 0;"
							@click="skipHelp"
						>
							<span>Skip</span>
						</label>
					</div>
					<p>
						Utilize the powerful functional area to optimize your design.
					</p>
					<div class="popupEditorHelpActions">
						<label class="buttonYellow small" @click="next">Next</label>
					</div>
				</div>
			</div>
		</div>
		<div class="popupEditorHelpBlock step4" v-show="actived === 4">
			<div class="popupEditorHelpContainer" :style="stylePosition">
				<div class="popupEditorHelpContent">
					<div class="popupEditorHelpTitle">
						<span class="popupEditorHelpStepLocation">
							<strong>{{ actived }}</strong
							>/5
						</span>
						<label
							class="popupEditorHelpMiniAction anime-1"
							style="right: 0;"
							@click="skipHelp"
						>
							<span>Skip</span>
						</label>
					</div>
					<p>
						Click the "Get Price" button to quote your design or product.
					</p>
					<div class="popupEditorHelpActions">
						<label class="buttonYellow small" @click="next">Next</label>
					</div>
				</div>
			</div>
		</div>
		<div class="popupEditorHelpBlock step5" v-show="actived === 5">
			<div class="popupEditorHelpContainer" :style="stylePosition">
				<div class="popupEditorHelpContent">
					<div class="popupEditorHelpTitle">
						<span class="popupEditorHelpStepLocation">
							<strong>{{ actived }}</strong
							>/5
						</span>
						<label
							class="popupEditorHelpMiniAction anime-1"
							style="right: 0;"
							@click="skipHelp"
						>
							<span>Skip</span>
						</label>
					</div>
					<p>
						Log in to your account to save your designs and share them with others.
					</p>
					<div class="popupEditorHelpActions">
						<label class="buttonYellow small" @click="skipHelp">Design Now</label>
					</div>
				</div>
			</div>
		</div>
		<canvas id="canvas"></canvas>
	</div>
</template>

<script>
export default {
	data() {
		return {
			canvas: null,
			actived: 0,
			stylePosition: {
				top: 0,
				left: 0
			},
			config: {
				1: {
					class: '.tab-template'
				},
				2: {
					class: '.tab-element'
				},
				3: {
					class: '.tool-bar'
				},
				4: {
					class: '.getPrice'
				},
				5: {
					class: '.tool-share'
				}
			}
		};
	},
	methods: {
		updateCanvasDefault() {
			if (this.actived === 0) {
				return false;
			}
			const canvas = this.canvas;
			const ctx = this.canvas.getContext("2d");
			canvas.setAttribute("width", window.innerWidth + "px");
			canvas.setAttribute("height", window.innerHeight + "px");
			ctx.fillStyle = "rgba(15, 15, 15, 0.6)";
			ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
		},
		updateCanvas() {
			this.updateCanvasDefault();
			const ctx = this.canvas.getContext("2d");
			const config = this.config[this.actived];
			const customDom = document.querySelector(config.class);
			const rect = customDom.getBoundingClientRect();
			if(this.actived===1 || this.actived===2){
				this.stylePosition = {
					left: rect.left + rect.width + 24 +'px',
					top: rect.top +'px'
				}
			}else if(this.actived === 3){
				this.stylePosition = {
					left: rect.left + 55 + 'px',
					top: rect.top+ rect.height + 15 + 'px'
				}
			}else if(this.actived === 4){
				this.stylePosition = {
					left: rect.left - 360 + 'px',
					top: rect.top - 130 + 'px'
				}
			}else if(this.actived === 5){
				this.stylePosition = {
					left: rect.left - 360 + 'px',
					top: rect.top + 'px'
				}
			}
			console.log(this.stylePosition)
			ctx.clearRect(rect.left, rect.top, rect.width, rect.height);
		},
		skipHelp() {
			this.$cookies.set('show_dz_yd',0,{
				maxAge: 60 * 60 * 24 * 30,
				path: "/",
			})
			this.$emit("closeEditHelp");
		},
		prev() {
			this.actived--;
			this.updateCanvas();
		},
		next() {
			this.actived++;
			this.updateCanvas();
		},
		handleResize(){
			this.updateCanvasDefault();
			this.updateCanvas();
		}
	},
	mounted() {
		this.canvas = document.getElementById("canvas");
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize)
	}
};
</script>

<style scoped lang="scss">
.wrap {
	canvas {
		position: absolute;
		top: 0;
		z-index: 349;
	}

	.popupEditorHelpTitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.popupEditorHelpBlock {
		width: 100%;
		height: 100%;
		position: fixed;
		overflow: hidden;
		left: 0;
		top: 0;
		margin: 0;
		z-index: 2147483643;
		cursor: default;
	}

	.popupEditorHelpContainer {
		position: relative;
		z-index: 350;
		width: 345px;
		background: #fff;
		box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.95);
	}

	.popupEditorHelpContent {
		position: relative;
		padding: 10px 10px 20px;
		text-align: center;

		h2 {
			font-size: 15px;
			line-height: 20px;
			font-weight: 700;
			display: block;
		}

		p {
			padding: 9px 0 0;
			font-size: 18px;
		}
	}

	.popupEditorHelpStepLocation {
		box-sizing: content-box;
		display: block;
		color: rgba(0, 0, 0, 0.37);
		text-align: center;
		font-size: 14px;

		strong {
			color: rgba(0, 0, 0, 0.55);
			font-weight: 400;
			font-size: 18px;
		}
	}

	.popupEditorHelpMiniAction {
		box-sizing: content-box;
		font-size: 16px;
		display: block;
		cursor: pointer;
		color: #666666;

		&:hover {
			color: #2996FB;

			span::after {
				width: 100%;
				left: 0;
			}
		}

		.back {
			font-size: 18px;
			font-weight: 500;
		}

		span {
			display: inline-block;
			position: relative;
			overflow: hidden;

			&::after {
				content: "";
				transition: all 0.2s ease-in-out;
				display: block;
				height: 5px;
				width: 0%;
				border-top: 1px solid rgba(0, 140, 189, 0.73);
				position: absolute;
				bottom: -4px;
				left: -5px;
			}
		}
	}

	.anime-1 {
		transition: all 0.2s ease-in-out;
	}

	.popupEditorHelpActions {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px 0 0;
		text-align: center;

		.buttonYellow {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 180px;
			height: 50px;
			background: #2996FB;
			border-radius: 10px;
			cursor: pointer;
			font-weight: 700;
			text-transform: capitalize;
			color: #ffffff;
			font-size: 18px;
		}

		.buttonYellow.small {
			width: auto;
			padding: 0 20px;
			height: 32px;
			font-size: 16px;
		}
	}

	.step0 {
		.popupEditorHelpContainer {
			width: 460px;

			.popupEditorHelpContentStart {
				position: relative;
				padding: 55px 10px;

				.popupEditorHelpTitle {
					position: absolute;
					top: 10px;
					right: 10px;
				}

				p {
					display: block;
					text-align: center;
					padding: 0 0 12px;
				}

				h2 {
					color: #000;
					font-size: 28px;
					letter-spacing: -1px;
					text-align: center;
					text-shadow: 0 1px 0 #fff;
					display: block;
					font-weight: 700;
				}
			}
		}
	}

	.popupEditorHelpOverlay {
		display: flex;
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 350;
		justify-content: center;
		align-items: center;
		background: rgba(15, 15, 15, 0.6);
	}
}
</style>
