import request from '@/utils/request'

// 获取平台配置列表
export function getAllPlatformLimitation(data) {
    return request({
        url: '/design/platformLimitation/getAllPlatformLimitation',
        method: 'post',
        data,
    })
}

// 新增编辑
export function editPlatformLimitation(data) {
    return request({
        url: '/design/platformLimitation/editPlatformLimitation',
        method: 'post',
        data,
    })
}

// 删除
export function deletePlatformLimitation(data) {
    return request({
        url: '/design/platformLimitation/deletePlatformLimitation',
        method: 'post',
        data,
    })
}

// 获取平台数据
export function getSystemProjectList(data) {
    return request({
        url: '/systemProject/list',
        method: 'post',
        data,
    })
}

// 获取所有类目
export function getCategoryList(data) {
    return request({
        url: '/design/category/list',
        method: 'get',
        params:data,
    })
}