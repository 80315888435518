<template>
  <span class="leftIcon" v-if="icon">
    <i class="sub-el-icon" :class="icon" v-if="icon.includes('el-icon')"/>
    <svg-icon :icon-class="icon" v-else></svg-icon>
  </span>
</template>
<script>
export default {
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.leftIcon{
  margin-right: 4px;
  font-size: 18px;
}
.sub-el-icon {
  vertical-align: text-top;
  color: currentColor;
  width: 1em;
  height: 1em;
}
</style>
