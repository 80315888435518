<template>
  <!-- title:标题内容 默认值：默认标题
        content_text:自定义文字内容 默认值：默认内容
        template:插槽内容，可插入文本框，HTML，图片...
        hidden:判断弹出层显示隐藏
        popup_sub:子组件确定事件
        popup_clo:子组件的取消事件
        clo_box:点击了弹出框外部内容
        sendBtnColor:直接传按钮颜色 -->
  <transition name="el-fade-in-linear">
    <div class="box" v-show="this.hidden" @click.stop="clo_box">
      <div class="popup_content" :style="{border: `1px solid ${borderColor}`}">
        <i class="icon el-icon-close" @click="clo_box"></i>
        <div class="popup_title">{{ this.title }}</div>
        <div class="popup_center">
          <div v-if="this.content_text">{{ this.content_text }}</div>
          <slot></slot>
        </div>
        <div class="popup_bottom">
          <button @click="popup_sub" :style="{backgroundColor:sendBtnColor}">确认</button>
          <button @click="popup_clo">取消</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    sendBtnColor: {
      type: String,
      default: '#0089f5',
    },
    borderColor: {
      type: String,
      default: '#fff',
    },
    title: {
      type: String,
      default: "默认标题",
    },
    content_text: {
      type: String,
      default: "",
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    //点击确定事件
    popup_sub () {
      this.$emit("popup_sub");
    },
    //点击了取消事件
    popup_clo () {
      this.$emit("popup_clo", false);
    },
    //点击了弹出框以外区域
    clo_box (e) {
      if (e.target._prevClass == "box" || 'icon') {
        this.$emit("clo_box")
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.box {
  background-color: rgba(128, 128, 128, 0.507);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 99;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
}
.popup_content {
  position: relative;
  background-color: white;
  padding: 2%;
  /* border-radius: 20px; */
  width: 463px;
  height: 218px;
  margin: 10% auto;
  .icon {
    position: absolute;
    right: 13px;
    top: 13px;
    font-weight: 700;
    color: black;
    &:hover {
      -webkit-animation: spin 1s linear 1s 5 alternate;
      animation: spin 1s linear infinite;
    }
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.popup_title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0em;
  color: #3d3d3d;
}
.popup_center {
  text-align: center;
  padding: 20px 0px 20px 0px;

  font-family: Source Han Sans CN;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0em;

  color: #3d3d3d;
}
.popup_bottom {
  display: flex;
  justify-content: space-evenly;
  margin-top: 23px;
}
.redBtn {
  width: 105px;
  height: 34px;
  background-color: #d61b3f !important;
  color: #fff;
}

button {
  width: 105px;
  height: 34px;
  border: 1px solid #d8d8d8;
  color: #fff;
}
.blueBtn {
  background-color: #0089f5;
  color: white;
  font-size: 15px;
}
button:nth-child(2) {
  background: #d8d8d8;
  color: black;
  font-size: 15px;
}
</style>
