import Vue from "vue";

export function formatPercent(val) {}

const toDecimal2 = x => {
  let f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
};

const formatText = number => {
  if (!number) {
    return number;
  }
  return !(number + "").includes(".")
    ? // 就是说1-3位后面一定要匹配3位
      (number + "").replace(/\d{1,3}(?=(\d{3})+$)/g, match => {
        return match + ",";
      })
    : (number + "").replace(/\d{1,3}(?=(\d{3})+(\.))/g, match => {
        return match + ",";
      });
};

let filters = {
  formatPercent,
  formatText
};

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
export default filters
