import {getSignature, uploadToOss} from "@/api/ossUpload";
import store from '@/store';
import {generateRandomFileName,getFileSuffix,getFilePrefix} from "@/utils/utils";
let policyURL = 'https://products-api-o2o-prod.gs-souvenir.com/gyoss/oss/web/config'; //oss获取签名地址
const nameRegExp = /[\!\@\#\$\%\^\&\*\(\)\+\=\{\}\|\[\]\\\:\"\;\'\<\>\?\,\.\/\`\~]/gi;
const systemName = "CNOss"; //CNOss 国内，customOss国外
const dzxtSystemName = "customOss"


/**
 * 生成OSS文件上传的表单参数
 * @param ossSignature    OSS校验签名
 * @param file            要上传的文件
 * @param ossFileName     文件保存在OSS的文件名
 * @return {FormData}     生成的表单数据
 */
const getFormData = (ossSignature, ossFileName, file) => {
    const ossData = new FormData();
    // policy、OSSAccessKeyId、signature 是OSS上传的校验参数
    ossData.append("policy", ossSignature.policy);
    ossData.append("OSSAccessKeyId", ossSignature.accessid);
    ossData.append("signature", ossSignature.signature);
    // 上传成功后返回200状态码，不设置则默认返回204
    ossData.append("success_action_status", "201");
    // callback 是OSS上传成功的回调参数
    // ossData.append('callback', ossSignature.callback)
    // key 是文件在OSS上存储的路径
    let filePath = ossSignature.dir;
    if (!filePath.endsWith("/")) {
        filePath += "/";
    }
    ossData.append("key", filePath + ossFileName);
    ossData.append("name", ossFileName);
    // file 是文件在OSS上存储的数据，注意放在最后避免校验参数不被识别导致403错误
    ossData.append("file", file);
    return ossData;
}

//生产单修改使用
const getFormDatas = (ossSignature, ossFileName, file) => {
    const ossData = new FormData();
    // policy、OSSAccessKeyId、signature 是OSS上传的校验参数
    ossData.append("policy", ossSignature.policy);
    ossData.append("OSSAccessKeyId", ossSignature.accessid);
    ossData.append("signature", ossSignature.signature);
    // 上传成功后返回200状态码，不设置则默认返回204
    ossData.append("success_action_status", "201");
    // callback 是OSS上传成功的回调参数
    // ossData.append('callback', ossSignature.callback)
    // key 是文件在OSS上存储的路径
    let ids = store.state?.auth?.userInfo?.id || 0
    let filePath = ossSignature.dir + ids;
    if (!filePath.endsWith("/")) {
        filePath += "/";
    }
    ossData.append("key", filePath + ossFileName);
    ossData.append("name", ossFileName);
    // file 是文件在OSS上存储的数据，注意放在最后避免校验参数不被识别导致403错误
    ossData.append("file", file);
    return ossData;
}

/**
 * 获取OSS文件上传的校验签名
 * 1. 签名具备时效性，优先从浏览器缓存中获取
 * 2. 无缓存签名或签名过期，则重新向服务器请求
 * @return {Promise<unknown>} 在该promise的then方法中取得签名
 */
export const getSignaturePromise = (url, data) => {
    return new Promise((resolve, reject) => {
        // const timestamp = new Date().getTime() / 1000;
        // const cacheTimeStamp = localStorage.getItem(url) ? Math.round(JSON.parse(localStorage.getItem(url)).expire) : 0
        // if (timestamp > cacheTimeStamp - 5) {
        //     // 浏览器存储的Policy无效，重新请求接口获取OSS上传校验签名
        //     getSignature(url, data).then((response) => {
        //         const newSignature = response.data.data;
        //         // 存储生成的签名
        //         localStorage.setItem(url, JSON.stringify(newSignature));
        //         resolve(newSignature);
        //     }).catch(err => {
        //         localStorage.removeItem(url);
        //         reject('获取签名失败')
        //     })
        // } else {
        //     resolve(JSON.parse(localStorage.getItem(url)))
        // }
        getSignature(url, data).then((response) => {
            const newSignature = response.data.data;
            // 存储生成的签名
            localStorage.setItem(url, JSON.stringify(newSignature));
            resolve(newSignature);
        }).catch(err => {
            localStorage.removeItem(url);
            reject('获取签名失败')
        })
    });
}

/**
 * 处理文件名称
 * @param file
 * @param random
 * @return {string}
 */
const getFileName = (file, random = true) => {
    if (random) {
        let randomNamePrefix = (getFilePrefix(file.name)).replace(/\s+/g, '_').replace(nameRegExp, "");
        return randomNamePrefix + '_' + generateRandomFileName(file);
    } else {
        return (getFilePrefix(file.name)).replace(/\s+/g, '_').replace(nameRegExp, "") + getFileSuffix(file.name)
    }
}

export const uploadFile = async (file, ...args) => {
    return new Promise((resolve, reject) => {
        //默认参数,后续可以在这里拓展
        let defaultOptions = {
            isRandomName: true, //文件名是否随机
            hasVersion: false, //文件名后是否加版本号,图稿系统使用
            version: 1, //文件版本号,图稿系统使用
            systemName: 'CNOss', //oss桶名称 CNOss 国内，customOss 国外 总后台一般使用国内的桶，设计系统使用国外的桶，可以自定义传入
            modelDir: 'quoteManage', //oss上传文件夹
            replaceUrl: true, //是否替换上传后的文件地址
            isProductUpload: false, //是否生产单上传文件
            isSSL: 1
        }
        let options, cb;
        for (let i = 0; i < args.length; i++) {
            const arg = args[i];
            if (typeof arg === "function") {
                cb = arg
            }
            if (typeof arg === "object") {
                options = arg
            }
        }
        defaultOptions = Object.assign({}, defaultOptions, options);
        let formData = new FormData();
        formData.append("systemName", defaultOptions.systemName);
        formData.append("modelDir", defaultOptions.modelDir);
        formData.append("isSSL", defaultOptions.isSSL);
        getSignaturePromise(policyURL, formData).then(ossSignature => {
                let ossData;
                let ossFileName = getFileName(file, defaultOptions.isRandomName);
                if(defaultOptions.hasVersion){
                    ossFileName = ossFileName.replace(/_v[^_]+/, "");
                    let insertIndex = ossFileName.lastIndexOf('.');
                    ossFileName = ossFileName.substring(0, insertIndex) + `_v${options.version}` + ossFileName.substring(insertIndex);
                }
                if (defaultOptions.isProductUpload) {
                    ossData = getFormDatas(ossSignature, ossFileName, file);
                } else {
                    ossData = getFormData(ossSignature, ossFileName, file);
                }
                uploadToOss(ossSignature.url, ossData, (progressEvent) => {
                    if (typeof cb === 'function') {
                        cb(progressEvent)
                    }
                })
                    .then(() => {
                        let url = ossSignature.url + ossData.get("key");
                        if (defaultOptions.replaceUrl) {
                            url = url.replace(/oss-static-cn.liyi.co/i, 'static-oss.gs-souvenir.com');
                        }
                        resolve(url);
                    })
                    .catch((error) => {
                        localStorage.removeItem(policyURL);
                        error.msg = "OSS文件上传失败";
                        reject(error);
                    });
            }
        ).catch(err => {
            reject(err)
        })
    });
}