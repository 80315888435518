export default {
	inject: ['canvas'],
	data() {
		return {

		};
	},
	computed: {
		device() {
			return this.$store.state.device;
		}
	},
	created() {

	},
	methods: {
		//输入框聚集 展示搜索历史记录
		templateExtend(state) {
			this.templateState = state;
			this.templateRecordList = this.getLocalStorage("templateRecord").slice(0, 4);
		},
		artExtend(state) {
			this.artState = state;
			this.artRecordList = this.getLocalStorage("artRecord").slice(0, 4);
		},
		//存搜索记录到localStorage
		setLocalStorage(key, value) {
			// if(!value||value.length==0)
			if (value.trim() === "") {
				return false;
			}
			let arr = JSON.parse(localStorage.getItem(key)) || [];
			arr.unshift({ value, icon: true });
			let newArr = arr.reduce((acc, cur) => {
				let duplicateIndex = acc.findIndex((item) => item.value == cur.value);
				if (duplicateIndex < 0) {
					acc.push(cur);
				}
				return acc;
			}, []);
			if (newArr.length > 10) newArr.splice(4);
			localStorage.setItem(key, JSON.stringify(newArr));
		},
		getLocalStorage(key) {
			return JSON.parse(localStorage.getItem(key)) || [];
		},
	},
};
