import layout from "@/views/layout/index.vue";
const siteAdministration = () => import('@/views/promotionCenter/siteAdministration/index')
const seriesActivityManagement = () => import("@/views/promotionCenter/seriesActivityManagement/index");

export default {
	path: "/promotionCenter",
	name: "promotionCenter",
	component: layout,
	meta: {
		title: "促销中心管理",
		icon: "el-icon-s-ticket"
	},
	children: [
		{
			path: "siteAdministration",
			component: siteAdministration,
			name: "siteAdministration",
			meta: {
				title: "网站管理"
			}
		},
		{
			path: "seriesActivityManagement",
			component: seriesActivityManagement,
			name: "seriesActivityManagement",
			meta: {
				title: "系列活动管理"
			}
		},
	]
}
