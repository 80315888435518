import layout from "@/views/layout/index.vue";

const certificateManagement = () => import("@/views/CertificateManagement/index.vue");
export default {
	path: "/certificate",
	name: "certificate",
	component: layout,
	meta: {
		title: "网站证书管理",
		icon: "el-icon-tickets",
	},
	children: [
		{
			path: "certificateManagement",
			component: certificateManagement,
			name: "certificateManagement",
			meta: {
				title: "网站证书管理",
			},
		},
	],
};
