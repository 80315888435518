import daLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Opsætningsgebyr",
		PleaseAttention: "Bemærk venligst",
		MoldSetupFee: "Form-/opsætningsgebyr",
		invoice: "FAKTURA",
		PONumber: "Postnummer",
		reference: "Reference",
		HSCODE: "HS KODE",
		PURCHASEINVOICE: "KØBSFAKTURA",
		productPrintNo: "produktPrint nr",
		BILLTO: "REGNING TIL",
		SHIPTO: "SEND TIL",
		ProductDescription: "Produkt beskrivelse",
		total: "Total",
		PaymentStatus: "Betalingsstatus",
		checkInfo: "Tjek info",
		bankInfo: "Bank info",
		payOnline: "Betal online",
		checkPay: "Tjek: Betal og send til info nedenfor",
		attentionPlease: "Husk",
		directorOperations: "Driftsdirektør",
		signature: "Underskrift",
		nodesText: "Hvis du betaler via banken, skal du sørge for at medtage din",
		nodesText2: "i notatet.",
		thankbusiness: "Tak for din forretning!",
		paid: "BETALT",
		unpaid: "UBETALT",
		payInstall: "Delvis betaling, vent på saldoen.",
		remember:
			" For offshore-konto og udenlandsk valuta-konto, som bruges af udenlandske kunder til at overføre til vores bank, skal brugen af midler være 'Betaling for varer', eller lad det stå tomt.",
		extraText: [
			"",
			"Rush Fee",
			"Silketrykt gebyr",
			"Tilbagestødsgebyr",
			"Udskrevet Charge",
			"Zink trykstøbning Materialeomkostninger",
			"Ekstra fragt",
			"Gebyr for licenserede produkter",
			"Antal",
		],
		TaxPrice: "Skat Pris",
		discountedAmount: "rabatbeløb",

		card: "1. Kreditkort: VISA / Mastercard / AMEX og Discover",
		bankNote:
			"Bemærk: Hvis du gerne vil betale i en anden valuta end EUR, bedes du kontakte vores kundeservicerepræsentant.",
		checkFollow: "2. Find vores checkoplysninger som følger:",
		mention: "Når du foretager betalingen, bedes du oplyse fakturanummer. Tak skal du have!",
		topic: "Emne: Økonomirådgivning",
		invoiceDate: "Fakturadato",
		invoiceNumber: "Faktura nummer",
		excluding: "Subtotal eksklusiv",
		phone: "telefon",
		email: "E-mail",
		from: "Fra",
		to: "Til",
		attn: "Attn",
		lNVOICE: "FAKTURA",
		PROFORMA: "PROFORMA FAKTURA",
		invoiceNo: "Faktura nr",
		date: "Dato",
		paymentMethod: "Betalingsmetode",
		terms: "Betingelser",
		title: "Titel",
		no: "Ingen",
		artwork: "Kunstværk",
		item: "Vare",
		description: "Beskrivelse",
		qty: "Antal",
		unitPrice: "Pris per stk",
		moldFee: "Skimmelsvamp gebyr",
		extraFee: "Ekstra gebyr",
		amount: "Beløb",
		subtotal: "Subtotal",
		apoShippingFee: "Apo forsendelsesgebyr",
		taxPrice: "Skat Pris",
		couponAdditionalCosts: "Yderligere omkostninger",
		firstOrderCodeDisc: "Første ordre rabat",
		promoCode: "Tilbudskode",
		specialCode: "Særlige gruppetilbud",
		custompensCode: "$1 for 20 stk. penne",
		additionalCode: "$1 for en $75 kupon",
		pointsDeduct: "Point fradrag",
		points: "Points",
		giftVouchers: "Gavekort",
		couponDiscount: "Kupon rabat",
		orderDiscount: "Bestil rabat",
		extraDiscount: "Ekstra rabat",
		finalPrice: "Endelig pris",
		finalTotalPrice: "Endelig totalpris",
		estimatedShipDate: "Estimeret afsendelsesdato",
		notes: "Noter",
		shipTo: " Send til",
		name: "Navn",
		check: "Kontrollere",
		APOFPOAddress: "APO/FPO-adresse",
		Address: "Adresse",
		GrandTotal: "Grand Total",
		AmountPaid: "Betalt beløb",
		BalanceDue: "Forfaldne saldo",
		UserLevelDiscount: "Rabat på brugerniveau",
	},
	Quotation: {
		setupFee: "Opsætningsgebyr",
		free: "GRATIS",
		company: "Selskab",
		off: "AF",
		additionalCosts: "Ekstra omkostninger",
		firstOrderDiscount: "Første ordre rabat",
		specialGroup: "Særlige gruppetilbud",
		pointsDeduct: "Point fradrag",
		giftVouchers: "Gavekort",
		couponDiscount: "Kupon rabat",
		goldVipMember: "Guld VIP medlem",
		extraDiscount: "Ekstra rabat",
		userLevelDiscount: "Rabat på brugerniveau",
		points: "Points",
		title: "CITAT",
		to: "Til",
		from: "Fra",
		name: "Navn",
		email: "E-mail",
		telephone: "Telefon",
		country: "Land",
		oid: "Ordre ID",
		date: "Tilbudsdato",
		valid: "Gyldig indtil",
		contacts: "Kontaktpersoner",
		currency: "betalingsmiddel",
		no: "Ingen.",
		item: "Vare",
		artwork: "Kunstværk",
		description: "Beskrivelse",
		qty: "Antal",
		unitPrice: "Pris per stk",
		moldFee: "Skimmelsvamp gebyr",
		extraFee: "Ekstra gebyr",
		apoShippingFee: "Apo forsendelsesgebyr",
		taxPrice: "Skat Pris",
		amount: "Beløb",
		remarks: "Bemærkninger",
		total: "Total",
		titleTerms: "Vilkår og Betingelser:",
		first: "1. Gratis kunst og gratis forsendelse til USA / Canada / Europa.",
		second: "2. Ovenstående oplysninger er ikke en faktura og kun et skøn over ydelser/varer beskrevet ovenfor.",
		third: "3. Produktionen starter på den dato, vi modtager din bevisgodkendelse og betaling, betal venligst dette link for din ordre baseret på det godkendte bevis. (Betalingslink:",
		end: "Tak for din forretning!",
		tel: "Tlf",
		hours: "Arbejdstid",
		chat: "Live Chat",
		address: "USA adresse",
		hoursItem: "Pondělí až pátek 8:30 až 17:30 tichomořského času (PT)",
		chatItem: "24 hodin od pondělí do pátku",
		addressItem: "20829 Valley Blvd. Ořech, CA 91789",
	},
	member: {
		member1: "Sølv medlem",
		member4: "Guld VIP medlem",
		member5: "Platinum VIP-medlem",
	},
	...daLocale,
};
