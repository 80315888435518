import skLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Nastavenie poplatku",
		PleaseAttention: "Prosím, pozor.",
		MoldSetupFee: "Poplatok za mold/nastavenie",

		invoice: "FAKTÚRA",
		PONumber: "číslo objednávky",
		reference: "Odkaz",
		HSCODE: "HS KÓD",
		PURCHASEINVOICE: "NÁKUPNÁ FAKTÚRA",
		productPrintNo: "produktTlač č",
		BILLTO: "BILL TO",
		SHIPTO: "POSLAŤ DO",
		ProductDescription: "Popis produktu",
		total: "Celkom",
		PaymentStatus: "Stav platby",
		checkInfo: "Skontrolujte informácie",
		bankInfo: "Bankové informácie",
		payOnline: "Platba online",
		checkPay: "Skontrolujte: Zaplaťte a pošlite poštou na nižšie uvedené informácie",
		attentionPlease: "Zapamätajte si",
		directorOperations: "Riaditeľ prevádzky",
		signature: "Podpis",
		nodesText: "Ak platíte cez banku, nezabudnite uviesť svoje",
		nodesText2: "v poznámke.",
		thankbusiness: "Ďakujeme za váš obchod!",
		paid: "ZAPLATENÉ",
		unpaid: "NEZAPLATENÉ",
		payInstall: "Čiastočná platba, počkajte na zostatok.",
		remember:
			" V prípade offshore účtu a účtu v cudzej mene, ktorý používajú zahraniční klienti na prevod do našej banky, by použitie prostriedkov malo byť „Platba za tovar“ alebo toto pole nechajte prázdne.",
		extraText: [
			"",
			"Rush poplatok",
			"Poplatok za sitotlač",
			"Poplatok za spätnú formu",
			"Vytlačený poplatok",
			"Náklady na materiál na tlakové liatie zinku",
			"Extra náklad",
			"Poplatok za licencované produkty",
			"Množstvo",
		],
		TaxPrice: "Daňová cena",
		discountedAmount: "Zľavnená suma",

		card: "1. Kreditná karta: VISA / Mastercard / AMEX a Discover",
		bankNote:
			"Poznámka: Ak by ste chceli platiť v inej mene ako EUR, kontaktujte prosím nášho zástupcu zákazníckeho servisu.",
		checkFollow: "2. Informácie o šeku nájdete takto:",
		mention: "Pri platbe uveďte číslo faktúry. Ďakujem!",
		topic: "Téma: Finančné poradenstvo",
		invoiceDate: "Dátum vystavenia faktúry",
		invoiceNumber: "Číslo faktúry",
		excluding: "Medzisúčet bez",
		phone: "Telefón",
		email: "Email",
		from: "Od",
		to: "Komu",
		attn: "Attn",
		lNVOICE: "FAKTÚRA",
		PROFORMA: "PROFORMÁTNA FAKTÚRA",
		invoiceNo: "faktúra č",
		date: "Dátum",
		paymentMethod: "Spôsob platby",
		terms: "Podmienky",
		title: "Názov",
		no: "Nie",
		artwork: "Umelecké dielo",
		item: "Položka",
		description: "Popis",
		qty: "Množ",
		unitPrice: "Jednotková cena",
		moldFee: "Poplatok za formu",
		extraFee: "Extra poplatok",
		amount: "Suma",
		subtotal: "Medzisúčet",
		apoShippingFee: "Poplatok za dopravu Apo",
		taxPrice: "Daňová cena",
		couponAdditionalCosts: "Cena navyše",
		firstOrderCodeDisc: "Zľava na prvú objednávku",
		promoCode: "Promo kód",
		specialCode: "Špeciálne skupinové ponuky",
		custompensCode: "1 dolár za 20 kusov pier",
		additionalCode: "1 $ za poukážku v hodnote 75 $",
		pointsDeduct: "Odpočet bodov",
		points: "Body",
		giftVouchers: "Darčekové poukážky",
		couponDiscount: "Kupónová zľava",
		orderDiscount: "Zľava na objednávku",
		extraDiscount: "Extra zľava",
		finalPrice: "Konečná cena",
		finalTotalPrice: "Konečná celková cena",
		estimatedShipDate: "Približný dátum dodania",
		notes: "Poznámky",
		shipTo: " Odoslať do",
		name: "názov",
		check: "Skontrolujte",
		APOFPOAddress: "Adresa APO/FPO",
		Address: "Adresa",
		GrandTotal: "Úhrn",
		AmountPaid: "Čiastka vyplatená",
		BalanceDue: "Nedoplatok",
		UserLevelDiscount: "Zľava na úrovni používateľa",
	},
	Quotation: {
		setupFee: "Poplatok za nastavenie",
		free: "ZADARMO",
		company: "Spoločnosť",
		off: "VYPNUTÉ",
		additionalCosts: "Dodatočné náklady",
		firstOrderDiscount: "Zľava na prvú objednávku",
		specialGroup: "Špeciálne skupinové ponuky",
		pointsDeduct: "Odpočet bodov",
		giftVouchers: "Darčekové poukážky",
		couponDiscount: "Kupónová zľava",
		goldVipMember: "Zlatý VIP člen",
		extraDiscount: "Extra zľava",
		userLevelDiscount: "Zľava na úrovni používateľa",
		points: "Body",
		title: "CITÁCIA",
		to: "Komu",
		from: "Od",
		name: "názov",
		email: "Email",
		telephone: "Telefón",
		country: "Krajina",
		oid: "Číslo objednávky",
		date: "Dátum ponuky",
		valid: "Platné do",
		contacts: "Kontakty",
		currency: "mena",
		no: "Nie",
		item: "Položka",
		artwork: "Umelecké dielo",
		description: "Popis",
		qty: "Množstvo",
		unitPrice: "Jednotková cena",
		moldFee: "Poplatok za formu",
		extraFee: "Extra poplatok",
		apoShippingFee: "Poplatok za dopravu Apo",
		taxPrice: "Daňová cena",
		amount: "Suma",
		remarks: "Poznámky",
		total: "Celkom",
		titleTerms: "Podmienky:",
		first: "1. Bezplatné umelecké diela a bezplatná doprava do USA / Kanady / Európy.",
		second: "2. Vyššie uvedené informácie nie sú faktúrou, ale iba odhadom služieb/tovarov popísaných vyššie.",
		third: "3.Výroba začne v deň, keď dostaneme vaše potvrdenie a platbu, zaplaťte prosím tento odkaz za vašu objednávku na základe schváleného dokladu. (Platobný odkaz:",
		end: "Ďakujeme za váš obchod!",
		tel: "Tel",
		hours: "Úradné hodiny",
		chat: "Živý rozhovor",
		address: "Adresa USA",
		hoursItem: "Pondelok až piatok od 8:30 do 17:30 tichomorského času (PT)",
		chatItem: "24 hodín od pondelka do piatku",
		addressItem: "20829 Valley Blvd. Orech, CA 91789",
	},
	member: {
		member1: "Strieborný člen",
		member4: "Zlatý VIP člen",
		member5: "Platinový VIP člen",
	},
	...skLocale,
};
