<template>
	<div class="color-item" :class="{ active: isActive,noShowChild:!showChild}" @click="changeColor(colorItem)" :title="colorItem.pantone">
		<div class="color-inner-rect" :class="{isWhite:colorRgb(colorItem.code)==='rgb(255,255,255)'}" :style="{ backgroundColor: colorItem.code }">
			<span class="contentClass">
				{{colorItem.typeName}}
			</span>
		</div>
	</div>
</template>

<script>
	import {
		colorRgb,
	} from "@/utils/utils";
	export default {
		props: ["colorItem", "isActive", "showChild"],
		methods: {
			colorRgb(code) {
				return colorRgb(code);
			},
			changeColor(colorItem) {
				this.$emit("changeColor", colorItem);
			},
		},
	};
</script>

<style scoped lang="scss">
	.color-item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 4.0625rem;
		height: 2.875rem;
		aspect-ratio: 1;
		background: #FAFAFA;
		border: 1px solid #FAFAFA;
		border-bottom-color: #DBDBDB;
		cursor: pointer;
		font-size: 0;

		&:hover {
			background-color: #F0F0F0;
			border-radius: 4px 4px 0 0;
			border: 1px solid #DBDBDB;
			border-bottom: 1px solid #F0F0F0;
		}

		&.active {
			background-color: #F0F0F0;
			border-radius: 4px 4px 0 0;
			border: 1px solid #DBDBDB;
			border-bottom: 1px solid #F0F0F0;
		}

		&.noShowChild {
			border-bottom-color: #ffffff;

			&:hover {
				border-radius: 4px;
				border: 1px solid #DBDBDB;
			}
		}

		.contentClass {
			width: 50px;
			height: 20px;
			line-height: 20px;
			text-align: center;
			font-size: 12px;
			font-weight: 400;
			font-family: Calibri;
			color: #ffffff;
		}

		.color-inner-rect {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 2.875rem;
			height: 30px;
			border-radius: 4px;

			&.isWhite {
				border: 1px solid #CCCCCC;

				b,
				.contentClass {
					color: #333333;
				}
			}

			b {
				display: none;
				color: #ffffff;
				font-size: 17px;
			}
		}

	}

	@media screen and (max-width: 768px) {
		.color-item {
			width: 55px;
			height: 34px;

			.color-inner-rect {
				width: 46px;
				height: 28px;
				border-radius: 0px;
			}
		}
	}
</style>
