import { asyncRoutes, constantRoutes } from "@/router/common";
export function filterAsyncRoutes(routes, userAuthList) {
	const res = [];
	routes.forEach((route, index) => {
		const tmp = { ...route };
		userAuthList.forEach((auth, index1) => {
			const list = { ...auth };
			if (tmp.name === list.authorityName) {
				if (tmp.children && list.childList) {
					tmp.children = filterAsyncRoutes(tmp.children, list.childList);
				}
				//获取按钮权限
				let authBtnList = [];
				if (list.childList.length) {
					list.childList.forEach((item) => {
						if (item.authType === 1) {
							authBtnList.push(item.authorityName);
						}
					});
				}
				let obj = {
					children: tmp.children,
					component: tmp.component,
					meta: Object.assign(
						{},
						tmp.meta,
						{ authBtnList: authBtnList },
						{
							title: auth.urlTitle,
						},
					),
					hidden: tmp.hidden || Boolean(!list.isMenu),
					name: tmp.name,
					path: tmp.path,
				};
				if (tmp.redirect && tmp.children && tmp.children.length > 0 && tmp.children[0].name) {
					obj["redirect"] = {
						name: tmp.children[0].name,
					};
				}
				res.push(obj);
			}
		});
	});
	return res;
}

const state = {
	routes: [],
	addRoutes: [],
	MenuFlag: false,
};

const mutations = {
	SET_ROUTES(state, routes) {
		state.addRoutes = routes;
		state.routes = constantRoutes.concat(routes);
	},
	SET_MENUFLAG(state, val) {
		state.MenuFlag = !!val;
	},
};

const actions = {
	generateRoutes({ state, commit }, userData) {
		return new Promise((resolve) => {
			const { userInfo, userAuthList } = userData;
			// if (process.env.NODE_ENV === "development") {
			//   const rolesName = userInfo.roles.map(item => item.roleName);
			//   if (rolesName.includes("admin")) {
			//     let accessedRoutes = asyncRoutes || [];
			//     commit("SET_ROUTES", accessedRoutes);
			//     resolve(accessedRoutes);
			//   } else {
			//     let accessedRoutes = filterAsyncRoutes(asyncRoutes, userAuthList);
			//     commit("SET_ROUTES", accessedRoutes);
			//     resolve(accessedRoutes);
			//   }
			// } else {
			//   let accessedRoutes = filterAsyncRoutes(asyncRoutes, userAuthList);
			//   commit("SET_ROUTES", accessedRoutes);
			//   resolve(accessedRoutes);
			// }
			commit("SET_MENUFLAG", userData.flag);
			let accessedRoutes = filterAsyncRoutes(asyncRoutes, userAuthList);
			commit("SET_ROUTES", accessedRoutes);
			resolve(accessedRoutes);
			// let accessedRoutes = asyncRoutes || []
			// commit('SET_ROUTES', accessedRoutes)
			// resolve(accessedRoutes)
		});
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
