import layout from "@/views/layout/index.vue";
const sampleDashboard = () => import("@/views/sampleInventory/sampleDashboard/index.vue");
const sampleManagement = () => import("@/views/sampleInventory/sampleManagement/index.vue");
const inventoryInApply = () => import("@/views/sampleInventory/inventoryInApply/index.vue");
const shipOrder = () => import("@/views/sampleInventory/shipOrder/index.vue");
const inventoryApplyAll = () => import("@/views/sampleInventory/inventoryApplyAll/index.vue");
const diffApply = () => import("@/views/sampleInventory/diffApply/index.vue");
const warehouse = () => import("@/views/sampleInventory/warehouse/index.vue");
const goods = () => import("@/views/sampleInventory/goods/index.vue");
export default {
	path: "/sampleInventory",
	name: "sampleInventory",
	component: layout,
	meta: {
		title: "库存管理",
		icon: "el-icon-s-promotion",
	},
	children: [
		{
			path: "sampleDashboard",
			component: sampleDashboard,
			name: "sampleDashboard",
			meta: {
				title: "库存管理看板",
			},
		},
		{
			path: "sampleManagement",
			component: sampleManagement,
			name: "sampleManagement",
			meta: {
				title: "库存管理",
			},
		},
		{
			path: "inventoryInApply",
			component: inventoryInApply,
			name: "inventoryInApply",
			meta: {
				title: "入库申请单",
			},
		},
		{
			path: "shipOrder",
			component: shipOrder,
			name: "shipOrder",
			meta: {
				title: "发货单",
			},
		},
		{
			path: "diffApply",
			component: diffApply,
			name: "diffApply",
			meta: {
				title: "差异反馈单",
			},
		},
		{
			path: "inventoryApplyAll",
			component: inventoryApplyAll,
			name: "inventoryApplyAll",
			meta: {
				title: "出入库单",
			},
		},
		{
			path: "warehouse",
			component: warehouse,
			name: "warehouse",
			meta: {
				title: "仓库配置",
			},
		},
		{
			path: "goods",
			name: "goods",
			component: goods,
			meta: {
				title: "商品配置",
			},
		},
	],
};
