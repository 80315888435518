import layout from "@/views/layout/index.vue";
const jobInformation = () => import("@/views/liyiHome/jobInformation.vue");
const commonProblem = () => import("@/views/liyiHome/commonProblem.vue");
const liyiResume = () => import("@/views/liyiHome/liyiResume.vue");
const annualMeeting = () => import("@/views/liyiHome/annualMeeting.vue");
const companyEnvironment = () => import("@/views/liyiHome/companyEnvironment.vue");
const festivalBenefit = () => import("@/views/liyiHome/festivalBenefit.vue");
const newsCenter = () => import("@/views/liyiHome/newsCenter.vue");
const userCenter = () => import("@/views/liyiHome/userCenter.vue");
export default {
	path: "/liyiSetting",
	name: "liyiSetting",
	component: layout,
	meta: {
		title: "官网设置",
		icon: "el-icon-picture",
	},
	children: [
		{
			path: "jobInformation",
			name: "jobInformation",
			component: jobInformation,
			meta: {
				title: "岗位信息",
			},
		},
		{
			path: "commonProblem",
			name: "commonProblem",
			component: commonProblem,
			meta: {
				title: "常见问题",
			},
		},
		{
			path: "liyiResume",
			name: "liyiResume",
			component: liyiResume,
			meta: {
				title: "简历",
			},
		},
		{
			path: "annualMeeting",
			name: "annualMeeting",
			component: annualMeeting,
			meta: {
				title: "公司年会",
			},
		},
		{
			path: "companyEnvironment",
			name: "companyEnvironment",
			component: companyEnvironment,
			meta: {
				title: "公司环境",
			},
		},
		{
			path: "festivalBenefit",
			name: "festivalBenefit",
			component: festivalBenefit,
			meta: {
				title: "节日福利",
			},
		},
		{
			path: "newsCenter",
			name: "newsCenter",
			component: newsCenter,
			meta: {
				title: "新闻中心",
			},
		},
		{
			path: "userCenter",
			name: "userCenter",
			component: userCenter,
			meta: {
				title: "后台管理",
			},
		},
	],
};
