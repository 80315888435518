<template>
  <div class="wrap">
    <el-aside class="content_left" style="width: 16%;">
      <div class="asideText">&nbsp;全定制产品&nbsp;  类目外形配置</div>
      <el-menu :default-openeds="['1']">
        <el-submenu :index="`${index + 1}`"  v-for="(item,index) in leftList" :key="index">
          <template slot="title" >{{item.name}}</template>
          <el-menu-item v-for="(child,childindex) in item.children" :key="childindex"
          >{{child.name}}</el-menu-item>
          </el-submenu>
      </el-menu>
    </el-aside>
    <el-container class="content_right">
        <div class="right">
          <el-form :inline="true" :model="categoryTop" class="demo-form-inline">
           <el-form-item label="适用位置" >
             <el-select  placeholder="请选择" style="width: 103px;" v-model="categoryTop.position">
                 <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
               </el-select>
           </el-form-item>
           <el-form-item label="外形状态">
             <el-select  placeholder="请选择状态" style="width: 132px;" v-model="categoryTop.appearence">
               <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
             </el-select>
           </el-form-item>
           <el-form-item label="工艺">
               <el-select  placeholder="请选择工艺" style="width: 163px;" v-model="categoryTop.gongyi">
                 <el-option label="区域一" value="shanghai"></el-option>
                 <el-option label="区域二" value="beijing"></el-option>
               </el-select>
             </el-form-item>
            <el-form-item>
              <el-input placeholder="搜索外形ID或外形名称" v-model="categoryTop.inputCategoryIdOrName" 
              class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
            </el-form-item>
           <el-form-item style="float: right;">
             <el-button class="primary" type="primary" icon="el-icon-plus"
              @click="addCategoryAppearence=true">添加外形</el-button>
             <el-button  v-if="!flagMove" @click="clickMerge"
             class="btn_all" type="primary" 
              style=" width: 111px;background: #7F9DB5;border: #7F9DB5;" 
              >拖拽排序</el-button>
              <el-button v-else type="primary" @click="SaveClickMerge"
          style="width: 111px;margin-left:10px;background: #7F9DB5;border: #7F9DB5;"
          >保存拖拽</el-button>
          <el-button style="width:80px" v-if="flagMove"  @click="cancel">取 消</el-button>
            </el-form-item>
         </el-form>
         <main class="right_Main"  row-key="id">
          <div class="card" v-for="(item,index) in cards" :key="index" 
          style="position: relative;top: 0;" :style="flagMove ? 'cursor:move ' : ''">
              <div class="shade" v-if="flagMove">
                <div  class="dragItem">
                  <span class="dragIcon">
                    <b class="icon-a-zhiding1"  @click="tableToTop(cards[index], index)"
                     v-if="index!== 0"></b>
                  </span>
                  <span class="dragIcon">
                    <i class="el-icon-rank"></i>
                  </span>
                </div>
              </div>
            <div class="top" style="position: relative;">
              <i class="el-icon-minus"></i> <span class="name">{{ item.categoryName }}</span>
              <span>{{ item.categoryCode }}</span> 
              <div style="position: absolute;height: 33px;top: 0;right: 10px;"
              @mouseenter="btnIsShoweditMenu1(item, index, item.id)" @mouseleave="btnIsShoweditMenu2"> 
                <i class="el-icon-more" ></i>
                <el-collapse-transition >
                      <div class="tooltop" v-if="showTooltip && showTooltipIndex == index">
                        <el-card shadow="hover" class="tooltop-card" >
                          <p class="menuBtn btn1" @click.stop="editCategory(item, index, item.id)">编辑</p>
                          <p class="menuBtn" @click.stop="useCategory(item.enable, index, item.id)" 
                          :style="{color: item.enable == 1?'#FF0000':'#3D3D3D' }">{{item.enable==1?'停用':'启用'}}</p>
                          <p class="menuBtn" @click.stop="deleteCategory(index, item.id)" style="color: red;">删除</p>
                        </el-card>
                      </div>
                    </el-collapse-transition>
              </div>
            </div>
            <div class="info">
              <div class="text">
                <span>类目：{{ item.category }}</span>
                <span>产品属性： {{ item.productType }}</span>
              </div>
              <div class="text"> 
                <span>位置：{{ item.position }}</span>
                <span>电镀色卡：{{ item.color }}</span>
              </div>
            </div>
            <!-- 遮罩 -->
            <div class="greyBox" v-if="item.enable==0">
              <div class="greyBack"></div>
              <div class="greyBoxText">此外形已停用</div></div>
            <img :src="item.img" alt="" class="imgSize"
             style="display: block; width: 93%; height: 57%;margin: 10px;border: 1px solid #D8D8D8;">
          </div>
         </main>
        </div>
    </el-container>
    <div class="myDialog" >
    <el-dialog title="添加类目外形" :visible.sync="addCategoryAppearence" 
    top="6vh" width="37%">
       <div class="borderBottom"></div>
     <el-form :model="categoryAppearance" style="width: 96%;" :rules="rules">
           <el-form-item label="选择工艺:" :label-width="formLabelWidth" prop="selectProcess">
             <el-select v-model="categoryAppearance.selectProcess" class="mutipleCheck"
              multiple placeholder="请选择">
                 <el-option
                   v-for="item in options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
           </el-form-item>
           <el-form-item label="电镀色卡:" :label-width="formLabelWidth" prop="platedColorCard">
              <el-select v-model="categoryAppearance.platedColorCard" placeholder="请选择">
                 <el-option
                   v-for="item in options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
              </el-select>
           </el-form-item>
           <el-form-item label="外形名称:" :label-width="formLabelWidth" prop="formFactorName">
                <el-input v-model="categoryAppearance.formFactorName" placeholder="请选择">
                </el-input>
             </el-form-item>
           <el-form-item label="外形编号:" :label-width="formLabelWidth" prop="formFactor">
                <el-input v-model="categoryAppearance.formFactor" placeholder="请选择">
                </el-input>
           </el-form-item>
         <el-form-item label="类目状态:" :label-width="formLabelWidth" prop="categoryStatus">
           <el-switch v-model="categoryAppearance.switch"
               :active-value="1" :width="50" inactive-color="#767676"
               :inactive-value="0">
             </el-switch>
           <span class="switchText checkedText" @click="categoryAppearance.switch=0"
           :class="{ checkedSwitch: categoryAppearance.switch == '1' }">启用</span>
           <span class="switchText uncheckedText" @click="categoryAppearance.switch = 1"
           :class="{ checkedSwitch: categoryAppearance.switch == '0' }">停用</span>
         </el-form-item>
       <el-form-item label="上传对应适用位置外形图:" :model="categoryAppearance.categoryDesignLocation"
       :label-width="formLabelWidth" prop="categoryDesignLocation" class="uploadLocation">
          <span class="picTip">注:<br>请上传不带模板或元素的Blank外形图</span>  
          <div class="location"
          >Front</div>
          <div class="location" style="margin-left: 40px;"
         >Back</div>    
         <!-- 上传图片 -->
           <div style="height: 234px;">
             <div style="float:left;height: 198px;width: 200px;">
              <FileUpload single style="margin: 10px 0 0 10px;" 
                 :file-list="front.imagePhoto"
                 @updateList="updateFrontCategoryImg">
               </FileUpload></div>
             <div style="float:left;height: 198px;width: 200px;margin: 10px 0 0 50px;">
              <FileUpload single 
                 :file-list="back.imagePhoto"
                 @updateList="updateBackCategoryImg">
               </FileUpload>
               </div>
           </div>
         </el-form-item>
            </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="primary" @click="addCategoryAppearence = false" style="width: 135px;">保存</el-button>
          <el-button  @click="addCategoryAppearence = false" style="width: 135px;margin-right: 62px;" plain>取消</el-button>
        </div>
    </el-dialog>
    <el-dialog title="编辑类目外形" :visible.sync="editCategoryAppearence" 
      top="6vh" width="37%">
         <div class="borderBottom"></div>
       <el-form :model="categoryAppearance" style="width: 96%;" :rules="rules">
             <el-form-item label="选择工艺:" :label-width="formLabelWidth" prop="selectProcess">
               <el-select v-model="categoryAppearance.selectProcess" class="mutipleCheck"
                multiple placeholder="请选择">
                   <el-option
                     v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                   </el-option>
                 </el-select>
             </el-form-item>
             <el-form-item label="电镀色卡:" :label-width="formLabelWidth" prop="platedColorCard">
                <el-select v-model="categoryAppearance.platedColorCard" placeholder="请选择">
                   <el-option
                     v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                   </el-option>
                </el-select>
             </el-form-item>
             <el-form-item label="外形名称:" :label-width="formLabelWidth" prop="formFactorName">
                  <el-input v-model="categoryAppearance.formFactorName" placeholder="请选择">
                  </el-input>
               </el-form-item>
             <el-form-item label="外形编号:" :label-width="formLabelWidth" prop="formFactor">
                  <el-input v-model="categoryAppearance.formFactor" placeholder="请选择">
                  </el-input>
             </el-form-item>
           <el-form-item label="类目状态:" :label-width="formLabelWidth" prop="categoryStatus">
             <el-switch v-model="categoryAppearance.switch"
                 :active-value="1" :width="50" inactive-color="#767676"
                 :inactive-value="0">
               </el-switch>
             <span class="switchText checkedText" @click="categoryAppearance.switch = 0"
             :class="{ checkedSwitch: categoryAppearance.switch == '1' }">启用</span>
             <span class="switchText uncheckedText" @click="categoryAppearance.switch = 1"
             :class="{ checkedSwitch: categoryAppearance.switch == '0' }">停用</span>
           </el-form-item>
         <el-form-item label="上传对应适用位置外形图:" :model="categoryAppearance.categoryDesignLocation"
         :label-width="formLabelWidth" prop="categoryDesignLocation" class="uploadLocation">
            <span class="picTip">注:<br>请上传不带模板或元素的Blank外形图</span>  
            <div class="location"
            >Front</div>
            <div class="location" style="margin-left: 40px;"
            >Back</div>    
            <!-- 上传图片 -->
             <div style="height: 234px;">
               <div style="float:left;height: 198px;width: 200px;">
                <FileUpload single style="margin: 10px 0 0 10px;" 
                   :file-list="front.imagePhoto"
                   @updateList="updateFrontCategoryImg">
                 </FileUpload></div>
               <div style="float:left;height: 198px;width: 200px;margin: 10px 0 0 50px;">
                <FileUpload single 
                   :file-list="back.imagePhoto"
                   @updateList="updateBackCategoryImg">
                 </FileUpload>
                 </div>
             </div>
           </el-form-item>
              </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button class="primary" @click="editCategoryAppearence = false" style="width: 135px;">保存</el-button>
            <el-button  @click="editCategoryAppearence = false" style="width: 135px;margin-right: 62px;" plain>取消</el-button>
          </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";
import Sortable from "sortablejs";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      flagMove:false,
      sortable:'',
      newData:[],
      formLabelWidth: '82px',
      addCategoryAppearence:false,
      editCategoryAppearence:false,
      leftList:[{
        id:1,
        name:'test1',
        children:[{
          id:12,
          name:'1-1',
        },{
          id:12,
          name:'1-2',
        }]
      }, {
        id: 2,
        name: 'test2',
        children:[{
          id:12,
          name:'2-1',
        },{
          id:12,
          name:'2-2',
        }]
      }, {
        id: 3,
        name: 'test3',
        children:[{
          id:12,
          name:'3-1',
        },{
          id:12,
          name:'3-2',
        }]
      }],
      categoryTop:{
        position:'',
        appearence:'',
        gongyi:'',
        inputCategoryIdOrName:''
      },
      front:{
        imagePhoto: '',
      },
      back: {
        imagePhoto: '',
      },
      categoryAppearance:{
        selectProcess:[],
        platedColorCard:'',
        formFactorName:'',
        formFactor:'',
        categoryStatus:'',
        categoryDesignLocation:{
        },
        value1:[]
      },
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      },],
      rules:{
        selectProcess:[
          { required: true, message: '请选择选择工艺', trigger: 'blur' },
        ],
        platedColorCard: [
          { required: true, message: '请选择电镀色卡', trigger: 'blur' },
        ],
        formFactorName: [
          { required: true, message: '请输入外形名称', trigger: 'blur' },
        ],
        formFactor: [
          { required: true, message: '请输入外形编号', trigger: 'blur' },
        ],
        categoryDesignLocation: [
          { required: true, message: '请上传外形图', trigger: 'blur' },
        ],
      },
      cards:[
        {
          id: 1,
          categoryName: 'Regular Shape01',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable:1,//1启用0停用
          img:require('../../../../../assets/image/camera.png'),
        }, {
          id:2,
          categoryName:'Regular Shape02',
          categoryCode:'RS-001',
          category:'Lapel Pins',
          productType:'全定制',
          position:'Front,Back',
          color:'Shiny Gold',
          enable: 0,//1启用0停用
          img: require('../../../../../assets/image/angle1.png'),
        }, {
          id: 3,
          categoryName: 'Regular Shape03',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 1,//1启用0停用
          img: require('../../../../../assets/image/angle2.png'),
        },
        {
          id: 4,
          categoryName: 'Regular Shape04',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 1,//1启用0停用
          img: require('../../../../../assets/image/angle3.png'),
        }, {
          id: 5,
          categoryName: 'Regular Shape05',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 0,//1启用0停用
          img: require('../../../../../assets/image/sort.png'),
        }, {
          id: 6,
          categoryName: 'Regular Shape06',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 1,//1启用0停用
          img: require('../../../../../assets/image/tips.png'),
        }, {
          id: 7,
          categoryName: 'Regular Shape07',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 0,//1启用0停用
          img: require('../../../../../assets/image/front.png'),
        }, {
          id: 8,
          categoryName: 'Regular Shape08',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 1,//1启用0停用
          img: require('../../../../../assets/image/success.png'),
        }, {
          id: 9,
          categoryName: 'Regular Shape09',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 1,//1启用0停用
          img: require('../../../../../assets/image/video.png'),
        }, {
          id: 10,
          categoryName: 'Regular Shape010',
          categoryCode: 'RS-001',
          category: 'Lapel Pins',
          productType: '全定制',
          position: 'Front,Back',
          color: 'Shiny Gold',
          enable: 1,//1启用0停用
          img: require('../../../../../assets/image/Vector.png'),
        },     
      ],
      showTooltip:false,
      showTooltipIndex: null,
    };
  },
  methods:{
    editCategory(item,index,id){
      this.editCategoryAppearence=true;
    },
    useCategory(enable,index,id){
      if(enable==1){
        this.$confirm('<span style="font-size: 18px;font-weight: bold;">确认停用此外形？</span>', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'cancelButtonY2',
          confirmButtonClass: 'confirmPinkButtonY2',
          center: true
        }).then(() => {
          this.cards[index].enable=0;
            this.$message({
              type: 'success',
              message: '已停用!'
            });
        }, () => {
          this.$message({
            message: '取消停用!'
          });
        })
      }else {
        this.$confirm('<span style="font-size: 18px;font-weight: bold;">确认启用此外形？</span>', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'cancelButtonY2',
          confirmButtonClass: 'confirmBlueButtonY2',
          center: true
        }).then(() => {
          this.cards[index].enable = 1;
          this.$message({
            type: 'success',
            message: '已启用!'
          });
        }, () => {
          this.$message({
            message: '取消启用!'
          });
        })
      }
    },
    deleteCategory (index,id) {
      this.$confirm('确认后，此类目的所有信息将被清楚，无法恢复。<div style="font-size:12px;color:#ACACAC">注：若已在外形/模板库中使用此类目，则无法执行删除操作</div> ',
        '确认删除此类目？', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonY2',
        confirmButtonClass: 'confirmPinkButtonY2',
        center: true
      }).then(() => {
        this.cards.splice(index, 1)[0]
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        },()=>{
          this.$message({
            message: '取消删除!'
          });
        })
    },
    btnIsShoweditMenu1(item,index,id){
      this.showTooltip = true;
      this.showTooltipIndex = index;
    },
    btnIsShoweditMenu2 () {
      this.showTooltip = false;
    },
    updateFrontCategoryImg (list) {
      this.front.imagePhoto = list[0].response;
    }, 
    updateBackCategoryImg(list) {
      this.back.imagePhoto = list[0].response;
    },
    tableToTop (row, index) {
      this.cards.splice(0, 0, this.cards.splice(index, 1)[0])
      // this.tableData.splice(index, 1)
      // this.tableData.unshift(row)
      this.newData = [].concat(this.cards.map((item, index) => ({
        id: item.id,
        // sortNum: index + 1,
      })));

    },
    initSortable (disabled) {
      console.log(this.flagMove, 'this.flag');
      const el = document.querySelector('.right_Main');
      // const el = document.querySelector('.el-table__body-wrapper tbody')
      const sortable = new Sortable(el, {
        disabled: disabled,
        scroll: true,
        animation: 180,
        dragClass: "dragClass", //设置拖拽样式类名
        ghostClass: "ghostClass", //设置拖拽停靠样式类名
        chosenClass: "chosenClass",
        onEnd: (evt) => { // 监听拖动结束事件
            const currRow = this.cards.splice(evt.oldIndex, 1)[0]
            this.cards.splice(evt.newIndex, 0, currRow);
            // const newData = []
            this.cards.forEach((item, index) => {
              this.newData[index] = {
                id: item.id,
              }
            })
        }
      })
      this.sortable = sortable
    },
    clickMerge () {
      this.$notify({
        message: '可按住开始拖拽',
        type: 'success'
      });
      this.flagMove = true
      this.initSortable(false)
    },
    SaveClickMerge () {
      if (this.newData.length == 0) {
        this.$notify({
          message: '还没有拖拽任何信息哦！',
          type: 'warning'
        });
        return
      } else {
          this.$notify({
            message: '批量拖拽保存成功',
            type: 'success'
          });
        this.flagMove = false;
        //请求保存新顺序
        this.newData = []
      }
    },
    cancel () {
      let cards=this.cards;
      this.flagMove = false; 
      this.sortable.destroy();
      //重新请求数据
      this.newData = [];
    },
    outside (e) {
      if (e.target.className !== "el-icon-more") {
        this.showTooltip = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  padding: 9px 12px;
  
  ::v-deep .el-aside {
    width: 13%;
    height: 100%;
    background:#FFFFFF;
    position: relative;
    color: #3D3D3D;
    .is-opened.el-submenu {
      .el-submenu__title{
        color: #0089F5;
        background: #E1F1FF;
        .el-submenu__icon-arrow{
            font-weight: bold;
            color: #0089F5;
        }
        &::before {
          position: absolute;
          left: 0;
          content: '';
          height: 100%;
          width: 5px;
          background: #0089f5;
        }
      }
      }
    .asideText {
      padding: 15px 73px;
      font-family: Source Han Sans CN;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .el-submenu__title {
      font-weight: 500;
    }
    }
  .content_left,
  .content_right {
    float: left;
    background:#FFFFFF;
    position: relative;
  }
  .content_left {
    width: 260px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    overflow: hidden;
    .is-opened {
      .el-submenu__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      margin: 0;
      padding: 0 15px;
      cursor: pointer;
      transition: all 0.4s;
      position: relative;
      .name {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        z-index: 1;
        background: #f2f2f2;
        // transform: scale(1.07, 1);
        // box-shadow: 0 0 15px #f7eeee;
      }
      &.active {
        background: lighten($color-primary, 50%);
        color: $color-primary;
        &::before {
          position: absolute;
          left: 0;
          content: '';
          height: 100%;
          width: 5px;
          background: #0089f5;
        }
      }
    }
    }
  }
 ::v-deep 
 .content_right {
    margin-left: 8px;
    width: 83.4%;
    height: 100%;
    overflow-y: auto;
    // box-sizing: border-box;
    background: #FFFFFF;
    .right {
      width: 100%;
      padding: 15px 6px 0 15px;
      .el-form-item {
        margin-bottom: 0px;
        line-height: 60px;
        .el-form-item__label,.el-input__inner {
          height: 45px!important;
        }
      }
      .input-with-select {
          width: 300px;
          border-radius: 5px;
          border: 1px solid #0089F5;
          .el-input__inner{
            border-right: none;
            border-radius: 5px;
            }
          .el-icon-search {
          font-size: 20px;
          }
          .el-button {
            margin-right: -22px;
            width: 74px;
            border-radius: 5px;
            color: #fff;
            background: #0089F5;
            }
      }
      .right_Main {
        width: 100%;
        padding-top: 8px;
        overflow: hidden;
        // display: grid;
        // grid-template-columns: 23.8% 23.8% 23.8% 23.8%;
        // grid-template-rows: 242px 242px 242px 242px;
        .card {
          position: relative;
          float:left;
          width:23.3%;
          height:242px;
          margin:0 20px 12px 0;
          box-shadow:0px 0px 6px 4px #cfcfcf;
          transition: all 0.1s ease-in;
          &:nth-of-type(4n+1){
            margin-left: 12px;
          }
          &:nth-of-type(4n){
            margin-right:12px;
          }
          &:hover {
            transform: scale(1.04);
            box-shadow:0px 0px 6px 4px  #badcfc;

            .top {
              background-color: #E4F2FF;
            }
          }
          .top {
            width:100%;
            height:33px;
            background-color:#F6F6F6;
            padding:6px 12px 5px;
            .el-icon-minus,.el-icon-more {
              transform: rotate(90deg);
              color:#0089F5;
            }
            .el-icon-minus {
              font-weight:600;
            }
            .el-icon-more {
              // margin-top:3px;
              vertical-align: bottom;
            }
            .name {
              margin-right:12px;
            }
            // ...点击
            .tooltop {
              position: absolute;
              top: 32px;
              right: -5px;
              width: 72px;
              height: 96px;
              font-size: 14px;
              z-index: 99;
              .tooltop-card {
                border-radius: 5px;
                .el-card__body {
                  padding: 0;
                }
                .menuBtn {
                  text-align: center;
                  color: #3D3D3D;
                  height: 30px;
                  line-height: 30px;
                  margin: 3px 0;
                }
              
                .menuBtn:hover {
                  cursor: pointer;
                  background: lighten($color-primary, 50%);
                  transform: scale(1.1, 1);
                }
              
                .btnDel:hover {
                  color: red;
                }
              }
            }


          }
          .info {
            position: relative;
            top: 0;
            width:100%;
            height:50px;
            font-size:12px;
            padding:7px 12px 8px;
            .text {
              height:20px;
              span:nth-of-type(1){
                float:left;
              }
              span:nth-of-type(2){
                float:right;
              }
            }
          }
          // 遮罩
          .greyBox {
            .greyBack {
              position: absolute;
              width: 93%; 
              height: 57%;
              background-color:rgba(121, 121, 121, 0.5);
              bottom: 12px;
              left: 10px;
            }
            .greyBoxText {
              position: absolute;
              z-index: 2;
              left: 50%;
              top: 60%;
              transform: translateX(-50%);
              width: 124px;
              height: 32px;
              opacity: 1;
              color: #fff;
              font-size: 14px;
              background-color: #4C4C4C;
              border-radius: 4px;
              text-align: center;
              line-height: 32px;
            }
          }
        }
      }
    }
}
}
::v-deep .myDialog {
    position: relative;
    .borderBottom{
      position: absolute;
      top: 60px;
      left: 20px;
      width: 91.5%;
      height: 1px;
      border-bottom: 1px solid #BBBBBB;
    }
    .el-dialog__wrapper{
      top: 64px;
      .el-dialog {
      max-height: 120vh!important;
      overflow-y: hidden;
      padding-left:15px;
      .el-form-item,.el-form-item__label {
        line-height: 40px;
      }
      .el-input__inner {
          height: 40px!important;
        }
      .uploadLocation {
        .el-form-item__label {
          line-height: 20px;
        }
        .location {
          float: left;
          width: 200px;
          background: #E4F2FF;
          border:1px solid #BFBFBF;
          font-size: 16px;
          text-align: center;
        }
      }
      .mutipleCheck {
        .el-tag.el-tag--info {
          color: #0089F5;
          background: #E4F2FF;
        }
      }
      .picTip{
        position: absolute;
        width: 60px;
        line-height: 20px;
        font-size: 12px;
        color: #A8A8A8;
        top: 70px;
        left: -72px;
       }
       .el-upload {
        border-radius: 0;
        border: 1px solid #A8A8A8;
        border-top: none;
       }
       .fileUpload{
          float: left;
          transform: scale(1.11);
       }
      
       .el-select {
        width: 100%;
       }
       .switchText{
        display: none;
        color: #fff;
        position: absolute;
        user-select: none;
        margin-top: 1px;
        cursor: pointer;
       }
       .el-switch.is-checked .el-switch__core {
        background-color: #0089F5;
       }
       .checkedText {
        margin-left: -47px;
       }
       .uncheckedText {
        margin-left: -32px;
       }
       .checkedSwitch{
        display: inline;
       }
      .el-dialog__body {
        padding: 20px 30px 0px;
      }
      .dialog-footer{
        padding-top: 0px;
      }
    }
    }
    }
.el-button {
      height: 45px;
      border-radius: 5px;
      font-size: 16px;
   }
 .primary {
      color: #fff;
      background: #0089F5;
      border: 1px solid #0089F5;
 }
// 拖拽
::v-deep .dragClass {
  background: rgba($color: #e1f1ff, $alpha: 0.5) !important;
}

// 停靠
::v-deep .ghostClass {
  background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}

// 选择
::v-deep .chosenClass:hover > td {
  background: rgba($color: #6cacf5, $alpha: 0.5) !important;
}
.shade {
    position: absolute;
    top: 0;
    zoom: 2;
    z-index:3;
    background-color: #E4F2FF;
    opacity: 0.7;
    display: flex;
    width: 100%;
    height:100%; 
    justify-content: center;
    .dragIcon{
      display:inline-block;
      width: 35px;
      height: 35px;
      color: #000;
      line-height: 35px;
    }
    .dragItem{
      width: 55%;
      height: 65%;
      cursor: pointer;
      font-size: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: right;
    }
}
</style>
<style lang="scss">
//messageBox弹框样式
.cancelButtonY2 {
  float: right;
  width: 120px;
  margin-right: 60px !important;
}

.confirmPinkButtonY2 {
  float: left;
  width: 120px;
  margin-left: 60px !important;
  border-color: #D61B3F !important;
  background-color: #D61B3F !important;
}
.confirmBlueButtonY2 {
  float: left;
  width: 120px;
  margin-left: 60px !important;
  border-color: #0089F5 !important;
  background-color: #0089F5 !important;
}
</style>