import hotkeys from './hotKey';
import {generateUUID} from '@/utils/utils';
import myCanvas from "@/assets/js/fabricCore/dzPublic";
const keyNames = {
	lrdu: 'left,right,down,up', // 左右上下
	backspace: 'backspace', // backspace键盘
	delete: 'delete', // delete键盘
	ctrlz: 'ctrl+z',
	ctrlsz: 'ctrl+shift+z',
	ctrlc: 'ctrl+c',
	ctrlv: 'ctrl+v',
	ctrlx: 'ctrl+x',
};

function copyElement(canvas) {
	let copyEl;
	// 复制
	hotkeys(keyNames.ctrlc, () => {
		const activeObject = canvas.getActiveObjects();
		if (activeObject.length === 0) return;
		canvas.getActiveObject()?.clone((_copyEl) => {
			copyEl = _copyEl;
		});
	});
	//剪切
	hotkeys(keyNames.ctrlx, () => {
		const activeObject = canvas.getActiveObjects();
		if (activeObject.length === 0) return;
		canvas.getActiveObject()?.clone((_copyEl) => {
			copyEl = _copyEl;
			activeObject.map((item) => canvas.remove(item));
			canvas.requestRenderAll();
			canvas.discardActiveObject();
			myCanvas.saveStateToHistory();
			myCanvas.updateMiniMap();
		});
	});
	// 粘贴
	hotkeys(keyNames.ctrlv, () => {
		if (copyEl) {
			copyEl.clone((clonedObj) => {
				canvas.discardActiveObject();
				clonedObj.set({
					left: clonedObj.left + 10,
					top: clonedObj.top + 10,
					id: generateUUID()
				});
				if (clonedObj.type === "activeSelection") {
					clonedObj.canvas = canvas;
					clonedObj.forEachObject(function (obj) {
						obj.set("id", generateUUID())
						canvas.add(obj);
					});
					clonedObj.setCoords();
				} else {
					canvas.add(clonedObj);
				}
				copyEl = clonedObj
				canvas.setActiveObject(clonedObj);
				canvas.requestRenderAll();
				myCanvas.saveStateToHistory();
				myCanvas.updateMiniMap();
			});
		}
	});
}

function initHotkeys(canvas) {
	// 撤销快捷键
	hotkeys(keyNames.ctrlz, () => {
		myCanvas.undo()
	});
	// 回撤快捷键
	hotkeys(keyNames.ctrlsz, () => {
		myCanvas.redo()
	});
	// 删除快捷键
	hotkeys(keyNames.backspace, () => {
		const activeObject = canvas.getActiveObjects();
		if (activeObject) {
			activeObject.map((item) => canvas.remove(item));
			canvas.requestRenderAll();
			canvas.discardActiveObject();
			myCanvas.saveStateToHistory();
			myCanvas.updateMiniMap();
		}
	});
	hotkeys(keyNames.delete, () => {
		const activeObject = canvas.getActiveObjects();
		if (activeObject) {
			activeObject.map((item) => canvas.remove(item));
			canvas.requestRenderAll();
			canvas.discardActiveObject();
			myCanvas.saveStateToHistory();
			myCanvas.updateMiniMap();
		}
	});
	// 移动快捷键
	hotkeys(keyNames.lrdu, (event, handler) => {
		const activeObject = canvas.getActiveObject();
		if (!activeObject) return;
		switch (handler.key) {
			case 'left':
				if (activeObject.left === undefined) return;
				activeObject.set('left', activeObject.left - 1);
				break;
			case 'right':
				if (activeObject.left === undefined) return;
				activeObject.set('left', activeObject.left + 1);
				break;
			case 'down':
				if (activeObject.top === undefined) return;
				activeObject.set('top', activeObject.top + 1);
				break;
			case 'up':
				if (activeObject.top === undefined) return;
				activeObject.set('top', activeObject.top - 1);
				break;
			default:
		}
		canvas.renderAll();
	});

	// 复制粘贴
	copyElement(canvas);

	//编组
	var selectedArr = [];
	var isCtrlPressed = false; // 记录 Ctrl 键的状态
	// 监听键盘按下事件
	document.addEventListener('keydown', function (event) {
		if (event.ctrlKey) {
			// 如果按下了 Ctrl 键
			isCtrlPressed = true;
		}
	});
	// 监听键盘松开事件
	document.addEventListener('keyup', function (event) {
		if (event.key === 'Control') {
			// 如果松开了 Ctrl 键
			isCtrlPressed = false;
			if (selectedArr.length <= 1) {
				return;
			}
			let sel = new fabric.ActiveSelection(selectedArr, {
				canvas: canvas,
			});
			let newgroup = sel.toGroup();
			newgroup.set('id', generateUUID());
			canvas.discardActiveObject().requestRenderAll();
			canvas.setActiveObject(newgroup).requestRenderAll();
			selectedArr = [];
		}
	});
	canvas.on({
		"mouse:down": (options) => {
			if (options.e.ctrlKey && options.e.button === 0) {
				// 如果按下了 Ctrl 键并且是鼠标左键单击
				if (options.target && options.target.type !== 'activeSelection' && options.target.type !== 'group') {
					let find = selectedArr.find(item=>item===options.target);
					if(find){
						return
					}
					selectedArr.push(options.target)
				}
			}else{
				if(options.target && options.target.type !== 'activeSelection' && options.target.type !== 'group'){
					selectedArr[0] = options.target
				}
			}
		},
	})
}

export default initHotkeys;
