<template>
  <div class="right-tool">
    <div class="tool-icon undo" :class="{ disabled: !config.undoButton }" @click="undo">
      <b class="icon-a-icon-Undozhuanhuan"></b>
      <span>Undo</span>
    </div>
    <div class="tool-icon redo" :class="{ disabled: !config.redoButton }" @click="redo">
      <b class="icon-a-icon-Redozhuanhuan"></b>
      <span>Redo</span>
    </div>
    <div class="tool-icon" title="text" @click="triggerAddText">
      <b class="icon-a-icon-Text2zhuanhuan"></b>
      <span>Text</span>
    </div>
    <div class="tool-icon" title="upload" @click="triggerUpload">
      <b class="icon-a-icon-Uploadzhuanhuan"></b>
      <span>Upload</span>
    </div>
  </div>
</template>

<script>
import dzMixin from "@/mixins/dzMixin";
import {addDesignTemplateShare} from "@/api/newDzxt";


export default {
  mixins: [dzMixin],
  data() {
    return {
      shareShow: 1,
      shareDialogDialog: false,
      shareImg: 'https://customed-center.oss-accelerate.aliyuncs.com/web/quoteManage/20220821/20220821rB4ZHrbJ.png',
      shareLink: '',
    }
  },
  computed: {
    config() {
      return this.canvas.config
    },
  },
  methods: {
    saveClick() {
      this.$emit("saveClick");
    },
    async shareClick() {
      this.shareImg = await this.canvas.getTemplatePicPath();
      let layersData = this.canvas.getTemplateData();
      addDesignTemplateShare({
        productId: this.$store.state.design?.pageInfo?.id,
        imgUrl: this.shareImg,
        layersData: JSON.stringify(layersData),
        routingName: this.$route.params.systemName,
      }).then((res) => {
        this.shareLink = `${window.location.origin}/us/design/${this.$route.params.designName}?id=${res.data.secret}`
      });
      this.shareShow = 1;
      this.shareDialogDialog = true;
    },
    triggerAddText() {
      this.$Bus.$emit('triggerAddText', 2)
    },
	  triggerUpload() {
		  this.$Bus.$emit('triggerUpload','upload1')
	  },
    shareDialog() {
      this.shareDialogDialog = false;
    },
    undo() {
      this.canvas.undo()
    },
    redo() {
      this.canvas.redo()
    },
    component1DataChange(params) {
      this.shareDialogDialog = params
    }
  }
};
</script>

<style scoped lang="scss">
.right-tool {
  padding-top: 60px;
  margin-left: 40px;

  .tool-share {
    width: 80px;
  }

  .tool-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 4px;
    background: #f3f3f3;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;

    span {
      margin-top: 4px;
      font-size: 16px;
    }

    &:hover {
      background-color: #ffffff;
    }
  }

  .undo.disabled,
  .redo.disabled {
    cursor: not-allowed;
    color: #999999;
  }
}
</style>
