<template>
	<video class="customImage-inner" v-bind="$attrs" v-on="$listeners" :src="customSrc" @click="clickHandler">
		<source :src="customSrc" type="video/mp4" />
	</video>
</template>

<script>
import { replacePicPrefix } from "@/utils/utils";

export default {
	name: "customVideo",
	inheritAttrs: false,
	props: {
		src: String,
        isPreview: Boolean,
	},
	computed: {
		customSrc() {
			return replacePicPrefix(this.src);
		}
	},
	methods: {
        clickHandler(){
            if(this.isPreview){
                this.$previewVideo(this.customSrc);
            }
        },

	}
};
</script>
<style scoped lang="scss">
.customImage {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
	}
}

.customImage-preview {
	cursor: pointer;
}

.customImage-error {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	background-color: #eeeeee;
}

.customImage-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	background-color: #eeeeee;
}
</style>
