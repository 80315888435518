<template>
	<div class="tool-bar">
		<el-tooltip popper-class="dzToolTip" effect="light" content="copy" placement="bottom">
			<div class="tool-icon" @click="clone">
				<b class="icon-a-icon-Copyzhuanhuan"></b>
			</div>
		</el-tooltip>
		<el-tooltip popper-class="dzToolTip" effect="light" content="Center" placement="bottom">
			<div class="tool-icon" @click="centerElement">
				<b class="icon-a-icon-centerzhuanhuan"></b>
			</div>
		</el-tooltip>
		<el-tooltip popper-class="dzToolTip" effect="light" content="Flip" placement="bottom">
			<div class="tool-icon" @click="flip">
				<b class="icon-a-icon-FlipVzhuanhuan"></b>
			</div>
		</el-tooltip>
		<el-tooltip popper-class="dzToolTip" effect="light" content="Layer" placement="bottom">
			<el-popover popper-class="layer-popover" placement="bottom" :title="lang.moveLayer" width="200" trigger="click">
				<div class="tool-icon" slot="reference">
					<b class="icon-a-icon-layers2zhuanhuan"></b>
				</div>
				<div class="list">
					<div class="list-item" @click="up">
						<b class="icon-a-icon-upzhuanhuan"></b>
						<span>{{ lang.up2 }}</span>
					</div>
					<div class="list-item" @click="down">
						<b class="icon-a-icon-Downzhuanhuan"></b>
						<span>{{ lang.down2 }}</span>
					</div>
				</div>
			</el-popover>
		</el-tooltip>
        <el-tooltip popper-class="dzToolTip" effect="light" content="Adapt" placement="bottom">
            <div class="tool-icon" @click="adapt">
                <b class="icon-a-icon-zsyzhuanhuan"></b>
            </div>
        </el-tooltip>
		<template v-if="!canvas.isSticker">
			<el-tooltip popper-class="dzToolTip" effect="light" :content="lang.grid" placement="bottom">
				<div class="tool-icon" :class="{ active: isGrid }" @click="addGrid">
					<b class="icon-a-icon-Gridlineszhuanhuan"></b>
				</div>
			</el-tooltip>
		</template>
		<el-tooltip popper-class="dzToolTip" effect="light" content="Group" placement="bottom">
			<div class="tool-icon" :class="{ active: isGroup }" @click="group">
				<b class="icon-a-icon-Groupzhuanhuan"></b>
			</div>
		</el-tooltip>
		<el-tooltip popper-class="dzToolTip" effect="light" content="Pen" placement="bottom">
			<el-popover popper-class="pen-popover" placement="bottom" width="453" trigger="click">
				<div class="tool-icon" slot="reference">
					<b class="icon-a-icon-huihzhuanhuan"></b>
				</div>
				<div class="penWrap">
					<div class="top">
						<span>{{ lang.brushSize }}</span>
						<el-slider v-model="penWidth" show-input :show-input-controls="false" :min="1" :max="100" :step="1" :show-tooltip="false" @change="changeDrawWidth"></el-slider>
						<el-popover popper-class="color-popover" placement="bottom-end" trigger="click">
							<div class="colorPicker" slot="reference">
								<div class="color-inner" :style="{ backgroundColor: penColor }">
									<b class="icon-down"></b>
								</div>
							</div>
							<div class="colorWrap" scrollbar>
								<ColorPickerItem :colorItem="item" :isActive="item.code === penColor" v-for="(item, index) in colorList" :key="index" @changeColor="changeDrawColor"></ColorPickerItem>
							</div>
						</el-popover>
					</div>
					<div class="btnWrap">
						<div class="startBtn" @click="startDraw">
							<b class="icon-a-icon-huihzhuanhuan"></b>
							<span>{{ lang.startDrawing }}</span>
						</div>
						<div class="stopBtn" @click="stopDraw">
							<button type="button" class="dzBtn stopBtn">{{ lang.stopDrawing }}</button>
						</div>
					</div>
				</div>
			</el-popover>
		</el-tooltip>
		<el-tooltip popper-class="dzToolTip" effect="light" content="Clear" placement="bottom">
			<div class="tool-icon" @click="clear">
				<b class="icon-a-icon-Clearzhuanhuan"></b>
			</div>
		</el-tooltip>
		<el-tooltip popper-class="dzToolTip" effect="light" content="Shortcut Key" placement="bottom">
			<el-popover placement="bottom" width="260" trigger="click">
				<div class="tool-icon" slot="reference">
					<b class="icon-a-icon-keyzhuanhuan"></b>
				</div>
				<div class="list2">
					<ul>
						<li>
							<div>Delete :</div>
							<div style="margin-left: 10px">
								<span>Delete</span>
							</div>
						</li>
						<li>
							<div>Undo:</div>
							<div style="margin-left: 20px">
								<span>Ctrl</span>
								<span class="jia">+</span>
								<span>Z</span>
							</div>
						</li>
						<li>
							<div>Redo:</div>
							<div style="margin-left: 22px">
								<span>Ctrl</span>
								<span class="jia">+</span>
								<span>Shift</span>
								<span class="jia">+</span>
								<span>z</span>
							</div>
						</li>
						<li>
							<div>Copy:</div>
							<div style="margin-left: 18px">
								<span>Ctrl</span>
								<span class="jia">+</span>
								<span>C</span>
							</div>
						</li>
						<li>
							<div>Paste:</div>
							<div style="margin-left: 17px">
								<span>Ctrl</span>
								<span class="jia">+</span>
								<span>V</span>
							</div>
						</li>
						<li>
							<div>Cut:</div>
							<div style="margin-left: 30px">
								<span>Ctrl</span>
								<span class="jia">+</span>
								<span>X</span>
							</div>
						</li>
						<li>
							<div>Group:</div>
							<div style="margin-left: 30px">
								<span>Ctrl</span>
								<span class="jia">+</span>
								<b class="icon-mouseL"></b>
							</div>
						</li>
						<li>
							<div>Multiple Choice:</div>
							<div style="margin-left: 30px">
								<span>Shift</span>
								<span class="jia">+</span>
								<b class="icon-mouseL"></b>
							</div>
						</li>
						<li>
							<div>move:</div>
							<div style="margin-left: 20px">
								<b class="icon-left"></b>
								<b class="icon-right"></b>
								<b class="icon-left"></b>
								<b class="icon-right"></b>
							</div>
						</li>
					</ul>
				</div>
			</el-popover>
		</el-tooltip>
	</div>
</template>

<script>
import ColorPickerItem from "@/components/MyDzxt/ColorPickerItem.vue";
import dzMixin from "@/mixins/dzMixin";

export default {
	mixins: [dzMixin],
	data() {
		return {
			penWidth: 1,
			penColor: "#000000"
		};
	},
	components: { ColorPickerItem },
	computed: {
		lang() {
			return this.$store.getters.lang?.design;
		},
		colorList() {
			return this.$store.state.design.colorList;
		},
		isGroup() {
			return this.canvas.mSelectMode === "one" && this.canvas.mSelectOneType === "group";
		},
		isGrid() {
			return !!this.canvas?.getWorkspace()?.get("fill");
		}
	},
	methods: {
        adapt(){
            let canvas = this.canvas.c;
            let canvasWorker = this.canvas.getWorkspace();
            canvas.discardActiveObject();
            let sel = new fabric.ActiveSelection(canvas.getObjects().filter((o) => {
                return o.id !== "workspace";
            }), {
                canvas: canvas,
            });
            let zoom = canvas.getZoom();
            if (sel._objects.length === 0) return false;
            canvas.setActiveObject(sel);
            let scale = 0.8;
            let sw = sel.width > sel.height ? sel.width : sel.height;
            let scaleMultiplier = ((canvasWorker.width / zoom) * scale) / sw;
            if (scaleMultiplier === 1) {
                canvas.discardActiveObject();
                canvas.requestRenderAll();
                return false;
            }
            sel.set({
                scaleX: scaleMultiplier,
                scaleY: scaleMultiplier,
            });
            canvas.viewportCenterObject(sel);
            canvas.discardActiveObject();
            canvas.requestRenderAll();
            this.canvas.saveStateToHistory();
            this.canvas.updateMiniMap();
        },
		centerElement() {
			this.canvas.position("center");
			this.$Bus.$emit("triggerUpdateLocation");
		},
		changeElementProperty(val, property) {
			this.canvas.changeElementProperty(val, property);
		},
		clone() {
			this.canvas.clone();
		},
		flip() {
			this.canvas.flip();
		},
		up() {
			this.canvas.up();
		},
		down() {
			this.canvas.down();
		},
		addGrid() {
			if (this.isGrid) {
				this.canvas.hideGrid();
			} else {
				this.canvas.showGrid();
			}
		},
		group() {
			if (this.isGroup) {
				this.canvas.unGroup();
			} else {
				this.canvas.group();
			}
		},
		clear() {
			this.$confirm(this.lang.deleteDesign, this.lang.tips, {
				confirmButtonText: this.lang.comfirm,
				cancelButtonText: this.lang.cancel,
				type: this.lang.warning
			})
				.then(() => {
					this.canvas.clear();
					//其他处理
				})
				.catch(() => {});
		},
		changeDrawWidth(penWidth) {
			this.canvas.changeDrawWidth(penWidth);
		},
		changeDrawColor(item) {
			this.penColor = item.code;
			this.canvas.changeDrawColor(item.code);
		},
		startDraw() {
			this.canvas.startDraw();
			this.$toast.success("success");
		},
		stopDraw() {
			this.canvas.stopDraw();
			this.$toast.success("success");
		}
	}
};
</script>

<style scoped lang="scss">
@import "@/assets/css/dzxt_theme";

.list2 {
	ul {
		margin-left: 0;
		li {
			list-style: none;
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			&:last-child{
				margin-bottom: 0;
			}

			b, span {
				display: inline-block;
				background: #F4F4F4;
				padding: 5px 10px;
				height: 30px;
			}

			.jia {
				background: #fff;
				padding: 3px 5px;
			}
		}
	}
}

.tool-bar {
	display: flex;
	justify-content: center;
	align-items: center;

	.tool-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		margin: 0 10px;
		width: 36px;
		height: 36px;
		border-radius: 2px;
		font-size: 22px;
		cursor: pointer;
		transition: all 0.3s;

		&.active {
			background-color: #ffffff;
		}

		&:hover {
			background-color: #ffffff;
		}
	}
}

.stopBtn {
	width: 100px;
	height: 40px;
	background: #2a96fa;
	border-radius: 6px;
	font-weight: 400;
	color: #ffffff;
}
</style>
