<template>
	<div class="exchange-container">
		<el-card class="exchange-card">
			<div class="header">
				<h2>汇率换算</h2>
				<h4>1 {{ fromCurrency }} ≈ {{ convertedAmountto }} {{ toCurrency }}</h4>
				<h4>1 {{ toCurrency }} ≈ {{ convertedAmountnext }} {{ fromCurrency }}</h4>
			</div>

			<div class="exchange-form">
				<el-select v-model="fromCurrency" class="currency-select" @change="handleChange">
					<el-option v-for="item in currencyList" :key="item.code" :label="item.code" :value="item.code">
						<span>
							<!--							<img :src="item.flagPath" class="currency-icon" />-->
							{{ item.code }}
						</span>
					</el-option>
				</el-select>
				<el-input-number
					v-model="amount"
					:precision="2"
					:step="1"
					:min="0"
					controls-position="right"
				></el-input-number>
				<!-- 货币互换按钮 -->
				<el-button icon="el-icon-refresh" class="swap-btn" @click="swapCurrencies"></el-button>

				<!-- 目标货币 -->
				<el-select v-model="toCurrency" class="currency-select" @change="handleChange">
					<el-option v-for="item in currencyList" :key="item.code" :label="item.code" :value="item.code">
						<span>
							<!--							<img :src="item.flagPath" class="currency-icon" />-->
							{{ item.code }}
						</span>
					</el-option>
				</el-select>
				<el-input-number
					v-model="convertedAmount"
					:precision="2"
					:step="1"
					:min="0"
					controls-position="right"
				></el-input-number>
			</div>

			<!-- <p class="update-time">更新时间：{{ lastUpdate }}</p> -->
		</el-card>
	</div>
</template>

<script>
import { getCurrency } from "@/api/CurrencyConverter/CurrencyConverter";
export default {
	name: "ExchangeCalculator",
	data() {
		return {
			exchangeRate: 7.2862, // 固定汇率，可改为动态获取
			amount: 1,
			convertedAmount: 0,
			isUpdating: 0,
			isChange: false,
			fromCurrency: "USD",
			toCurrency: "CNY",
			lastUpdate: "2025-02-28 08:35",
			currencyList: [],
		};
	},
	computed: {
		convertedAmountto() {
			return convertCurrency(1, this.fromCurrency, this.toCurrency, this.currencyList);
		},
		convertedAmountnext() {
			return convertCurrency(1, this.toCurrency, this.fromCurrency, this.currencyList);
		},
	},
	watch: {
		amount(newVal) {
			if (this.isUpdating > 0 || this.isChange) {
				this.isUpdating = 0;
				return;
			}
			this.isUpdating++;
			this.convertedAmount = convertCurrency(newVal, this.fromCurrency, this.toCurrency, this.currencyList);
		},
		convertedAmount(newVal) {
			if (this.isUpdating > 0 || this.isChange) {
				this.isUpdating = 0;
				return;
			}
			this.amount = convertCurrency(newVal, this.toCurrency, this.fromCurrency, this.currencyList);
			this.isUpdating++;
		},
	},
	methods: {
		swapCurrencies() {
			this.isChange = true;
			[this.fromCurrency, this.toCurrency] = [this.toCurrency, this.fromCurrency];
			this.convertedAmount = convertCurrency(this.amount, this.fromCurrency, this.toCurrency, this.currencyList);
			this.$nextTick(() => {
				this.isChange = false;
			});
		},
		handleChange() {
			this.isChange = true;
			this.convertedAmount = convertCurrency(this.amount, this.fromCurrency, this.toCurrency, this.currencyList);
			this.$nextTick(() => {
				this.isChange = false;
			});
		},
	},
	async mounted() {
		await getCurrency().then((res) => {
			this.currencyList = res.data?.filter((e) => e.isUsed === 1);
			this.currencyList.find((element) => {
				if (element.code === "CNY") {
					this.exchangeRate = element.rate;
					return true;
				}
				return false;
			});
		});
		this.isChange = true;
		this.convertedAmount = convertCurrency(this.amount, this.fromCurrency, this.toCurrency, this.currencyList);
		console.log("@@@", this.convertedAmount);

		this.$nextTick(() => {
			this.isChange = false;
		});
	},
};
function convertCurrency(amount, fromCurrency, toCurrency, currencyRates) {
	const fromRate = currencyRates.find((currency) => currency.code === fromCurrency)?.rate;
	const toRate = currencyRates.find((currency) => currency.code === toCurrency)?.rate;

	if (!fromRate || !toRate) {
		return null;
	}

	return amount * (toRate / fromRate).toFixed(2);
}
</script>

<style lang="scss" scoped>
.exchange-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60%;

	.exchange-card {
		width: 100%;
		max-width: 700px;
		padding: 20px;
		background-image: url("../../assets/image/汇率换算背景图.jpg");
		background-size: 100% 50%;
		background-repeat: no-repeat;

		::v-deep {
			.el-card__body {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
		.header {
			text-align: center;
			margin-bottom: 15px;

			h2 {
				margin-bottom: 5px;
				text-align: start;
			}

			h4 {
				text-align: start;
			}

			p {
				font-size: 14px;
				color: #666;
			}
		}

		.exchange-form {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 15px;

			.currency-select {
				width: 120px;
			}

			.amount-input {
				flex: 1;
			}

			.swap-btn {
				border: none;
				background: transparent;
				font-size: 18px;
				cursor: pointer;
				transition: transform 0.3s;

				&:hover {
					transform: rotate(180deg);
				}
			}
		}

		.update-time {
			text-align: center;
			font-size: 12px;
			color: #999;
		}
	}
}
</style>
