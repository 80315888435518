import layout from "@/views/layout/index.vue";
const allWebsite = () => import("@/views/websiteManage/allWebsite.vue");
const allWePrice = () => import("@/views/websiteManage/allWePrice.vue");
export default {
    path: "/websiteManage",
    name: "websiteManage",
    component: layout,
    meta: {
        title: "自营平台管理",
        icon: "el-icon-s-platform"
    },
    children: [
        {
            path: "allWebsite",
            name: "allWebsite",
            component: allWebsite,
            meta: {
                title: "网站内容管理",
                type: 0
            }
        },
        {
            path: "allWePrice",
            name: "allWePrice",
            component: allWePrice,
            meta: {
                title: "网站价格控制",
                type: 0
            }
        }
    ]
}
