<template>
  <div class="contentAll">
      <div class="content_left" v-loading="loading1">
        <div class="loop_box">
          <div class="loop_item" v-for="(item, index) in leftList" :key="index"
            @click="typeItemClick(item, index, item.id)" :class="{ active: item.id === DefaultId }">
            <span class="name" :title="item.templateTypeName">{{ item.templateTypeName }}</span>
          </div>
        </div>
      </div>
      <div class="content_right">
      </div>
      <!--	  设计系统弹窗-->
    <div v-if="designDialog">
      <el-dialog :visible.sync="designDialog" fullscreen custom-class="designDialog" append-to-body>
        <designSystem :designData="designData" @closeDialog="closeDesignDialog"></designSystem>
      </el-dialog>
    </div>
    </div>
</template>
<script>
import designSystem from "@/components/designSystem.vue";
import {getTemplateTypeOnDisplay,getTemplateList} from "@/api/systemManagement/templateManagement";
export default {
  data(){
    return{
      loading1: false,
      DefaultId:"",
      leftList: [],
      designDialog: false,
      designData: {},
    }
  },
  components:{designSystem},
  methods:{
    closeDesignDialog() {
      this.$store.commit('design/set_showTemplate', false);
      this.$store.commit('design/set_showArt', false);
      this.$store.commit('design/set_isDefault', true);
      this.designDialog = false;
	  this.$store.commit('design/set_designDialog',false);
    },
    // 获取左侧列表
    getLeftList() {
      let templateData = JSON.parse(sessionStorage.getItem("templateData"));
      getTemplateTypeOnDisplay({ language: "en-us" }).then(res => {
        if (res.code == "200") {
          if (res.data.length > 0) {
            this.leftList = res.data;
            this.DefaultId = templateData ? Number(templateData.DefaultId) : res.data[0].id;
            if (templateData) {
              let a = res.data.filter(x => x.categoryId == templateData.categoryId);
              console.log(a);
              this.copyCateList = a[0];
              this.modelName = a[0].templateTypeName;
            } else {
              this.copyCateList = res.data[0];
              this.modelName = res.data[0].templateTypeName;
            }
            let data = {
              language: "en-us",
              categoryId: templateData ? Number(templateData.categoryId) : this.copyCateList.categoryId
              // searchType: "ALL",
            };
            // this.getTableData(data);
            // this.searchClass();
            // this.getSomeSelect();
          }
        }
      });
    },
    typeItemClick(item, index, id) {
      this.DefaultId = id;
      let data = {
        categoryId: item.categoryId,
        isCache: false,
        cId: item.id,
        relcache: true
      };
      this.designDialog = true;
	    this.$store.commit('design/set_designDialog',true);
      this.designData = data;
      data.DefaultId = this.DefaultId;
      sessionStorage.setItem("templateData", JSON.stringify(data));
    },
  },
  created(){
    this.getLeftList();
  }
  
}
</script>
<style lang="scss" scoped>
::v-deep .designDialog {

.el-dialog__header {
  display: none;
}

.el-dialog__body {
  padding: 0;
}
}

.contentAll {
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  background: #efefef;
.content_left,
  .content_right {
    background: #fff;
    position: relative;
  }

  .content_left {
    width: 20%;
    height: 100%;
    // overflow-y: auto;
    box-sizing: border-box;
    // padding: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    margin-right: 5px;

    .CL_top_btn {
      margin-top: 18px;
    }

    .tit {
      margin-top: 22px !important;
      display: flex;
      justify-content: space-between;
    }

    .loop_box {
      width: 99%;
      margin-top: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 100px;
      font-size: 13px;
    }

    .loop_box::-webkit-scrollbar {
      display: none;
    }

    .loop_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      margin: 0;
      padding: 0 15px;
      cursor: pointer;
      transition: all 0.4s;
      position: relative;

      .name {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        z-index: 1;
        background: #f2f2f2;
        // transform: scale(1.07, 1);
        // box-shadow: 0 0 15px #f7eeee;
      }

      &.active {
        background: lighten($color-primary, 50%);
        color: $color-primary;

        &::before {
          position: absolute;
          left: 0;
          content: "";
          height: 100%;
          width: 5px;
          background: #0089f5;
        }
      }
    }

    @keyframes rotate {
      0% {
        transform: rotateY(0deg);
      }

      100% {
        transform: rotateY(100deg);
      }
    }
  }
  .content_right {
    width: 80%;
  }
}
</style>