import layout from "@/views/layout/index.vue";
const packageForwarding = () => import("@/views/shipmentStatus/packageForwarding/index.vue");
export default {
	path: "/shipmentStatus",
	name: "shipmentStatus",
	component: layout,
	meta: {
		title: "国外发货",
		icon: "el-icon-collection",
	},
	children: [
		{
			path: "packageForwarding",
			component: packageForwarding,
			name: "packageForwarding",
			meta: {
				title: "APO国外发货",
			},
		},
	],
};
