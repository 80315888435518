<template>
	<div class="custom-zoom">
		<div class="editorZoomOut" title="Zoom out" @click="zoomOutStage">
			<b class="icon-minus"></b>
		</div>
		<div class="editorZoomValue" title="Current zoom">
			<span>{{ stageScale | formatScale }}</span>
		</div>
		<div class="editorZoomFit" title="Fit to window" @click="fitStage">
			<span>Fit</span>
		</div>
		<div class="editorZoomIn" title="Zoom on" @click="zoomInStage">
			<b class="icon-plus"></b>
		</div>
	</div>
</template>

<script>
import dzMixin from "@/mixins/dzMixin";

export default {
	mixins: [dzMixin],
	data() {
		return {
			stageScale: 1, //画布比例
		};
	},
	filters: {
		formatScale(str) {
			return parseInt(str * 100) + "%";
		},
	},
	methods: {
		zoomOutStage() {
			this.canvas.small();
			this.stageScale = this.canvas.c.getZoom();
		},

		zoomInStage() {
			this.canvas.big();
			this.stageScale = this.canvas.c.getZoom();
		},

		fitStage() {
			this.canvas.auto();
			this.stageScale = this.canvas.c.getZoom();
		},
	},
};
</script>

<style scoped lang="scss">
.custom-zoom {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 184px;
	height: 34px;
	background: #f0f1f2;
	border-radius: 17px;
	color: #90979e;
	font-size: 18px;

	.editorZoomValue {
		margin: 0 20px;
	}

	.editorZoomFit {
		position: relative;
		margin-right: 20px;
		cursor: pointer;

		&::before {
			content: "";
			position: absolute;
			top: -10px;
			right: -10px;
			bottom: -10px;
			left: -10px;
		}
	}

	.editorZoomOut,
	.editorZoomIn {
		position: relative;
		cursor: pointer;

		&::before {
			content: "";
			position: absolute;
			top: -10px;
			right: -10px;
			bottom: -10px;
			left: -10px;
		}
	}
}
</style>
