<template>
	<div class="searchInput" ref="searchInput">
		<form class="inputWrap" @submit.prevent="selectItem(value)">
			<input type="text" :placeholder="lang.search" @focus="focusInput" :value="value" @input="updateValue($event.target.value)" />
			<span class="suffix-inner" @click="selectItem(value)">
				<b class="icon-a-uspp-sousuozhuanhuan"></b>
			</span>
		</form>
		<ul class="extend-list" v-if="extendList.length > 0 && showExtend">
			<li class="extend-item" :class="{ active: index === selectedIndex }" v-for="(item, index) in extendList" @click="selectItem(item.value)" :key="index" v-html="highlightedText(item)"></li>
		</ul>
	</div>
</template>
<script>
export default {
	props: {
		value: {
			type: String
		},
		extendList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			showExtend: false,
			selectedIndex: -1,
			isSelecting: false
		};
	},
	computed: {
		lang() {
			return this.$store.getters.lang?.design;
		}
	},
	methods: {
		handleClick(event) {
			try {
				this.isSelecting = false;
				let searchInput = this.$refs.searchInput;
				if (searchInput && !searchInput.contains(event.target)) {
					this.isSelecting = true;
					this.showExtend = false;
					document.body.removeEventListener("click", this.handleClick);
					this.$emit("focus", false);
				}
			} catch (e) {}
		},
		focusInput() {
			this.showExtend = true;
			this.selectedIndex = -1;
			document.body.addEventListener("click", this.handleClick);
			if (this.value.trim() == "" || this.value.trim().length == 1) {
				this.$emit("focus", true);
			}
		},
		updateValue(newValue) {
			this.showExtend = true;
			this.$emit("input", newValue);
			if (newValue.trim() == "") {
				if (this.isSelecting) {
					document.body.addEventListener("click", this.handleClick);
					this.isSelecting = false;
				}
			}
		},
		handleSearch(event) {
			event.preventDefault();
		},
		highlightedText(item) {
			const iconStyle = item.icon ? "" : "display: none;";
			if (this.value) {
				const regex = new RegExp(`(${this.value})`, "i");
				let newText = item.value.replace(regex, '<span class="highlight">$1</span>');
				return `<div style="display:flex;align-items:center;">
					<span><b class="icon-lishijilu" style="margin-right:10px;font-size:14px;${iconStyle}}"></b><span>
						</div>${newText}</div>
						</div>`;
			}
			return `<div style="display:flex;align-items:center;"><span><b class="icon-lishijilu" style="margin-right:10px;font-size:14px;${iconStyle}"></b></span>
				<div>${item.value}</div>
				</div>`;
		},
		handleKeyDown(event) {
			if (this.showExtend) {
				if (event.key === "ArrowUp") {
					event.preventDefault();
					if (this.selectedIndex > 0) {
						this.selectedIndex--;
					} else {
						this.selectedIndex = this.extendList.length - 1;
					}
				} else if (event.key === "ArrowDown") {
					event.preventDefault();
					if (this.selectedIndex < this.extendList.length - 1) {
						this.selectedIndex++;
					} else {
						this.selectedIndex = 0;
					}
				} else if (event.key === "Enter") {
					event.preventDefault();
					if (this.selectedIndex > -1) {
						this.selectItem(this.extendList[this.selectedIndex]);
					} else {
						this.selectItem(this.value);
					}
				}
			}
		},
		selectItem(text) {
			this.updateValue(text);
			this.showExtend = false;
			this.$emit("search", text);
		}
	}
};
</script>

<style scoped lang="scss">
@import "@/assets/css/dzxt_theme";

.searchInput {
	position: relative;

	::v-deep {
		.highlight {
			font-weight: 700;
		}
	}

	.extend-list {
		overflow: hidden auto;
		position: absolute;
		top: calc(100% + 2px);
		left: 0;
		right: 0;
		max-height: 152px;
		background: #ffffff;
		border: 1px solid #dbdbdb;
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
		border-radius: 4px;
		padding: 15px 0;
		margin: 0;
		z-index: 10;

		.extend-item {
			display: flex;
			align-items: center;
			height: 30px;
			padding: 0 20px;
			cursor: pointer;

			&:hover {
				background-color: #f2f2f2;
			}

			&.active {
				background-color: #f2f2f2;
			}
		}
	}

	.inputWrap {
		position: relative;
	}

	input {
		width: 100%;
		height: 34px;
		background: #f2f2f2;
		border-radius: 17px;
		padding: 0 18px;
		border: 1px solid transparent;
		transition: all 0.3s;

		&:focus {
			border: 1px solid $dz-primary;
			background-color: #ffffff;
		}
	}

	.suffix-inner {
		position: absolute;
		top: 50%;
		right: 18px;
		transform: translateY(-50%);
		cursor: pointer;

		b {
			color: #666666;
		}
	}
}
</style>
