import layout from "@/views/layout/index.vue";
const customIndex = () => import("@/views/index.vue")
const profitData = () => import("@/views/profitCenter/profitData.vue");
const waybillProfit = () => import("@/views/profitCenter/waybillProfit.vue");
const abnormalwaybill = () => import("@/views/profitCenter/abnormalwaybill.vue");
const abnormalWeightWaybill = () => import("@/views/profitCenter/abnormalWeightWaybill.vue");
const feeData = () => import("@/views/profitCenter/feeData.vue");
const refundForm = () => import("@/views/InquiryOrderManage/order/refundForm.vue");
const orderExport = () => import("@/views/financial/orderExport.vue");
const finRefundUserExport = () =>
	import("@/views/financial/finRefundUserExport.vue");
const purchaseOrderExport = () => import("@/views/financial/purchaseOrderExport.vue")
const financialOffline = () => import('@/views/financial/financialOffline.vue')
const customsData = () => import('@/views/financial/customsData.vue')
export default {
	path: "/financial",
	name: "financial",
	component: layout,
	meta: {
		title: "财务管理",
		icon: "el-icon-money"
	},
	children: [
		{
			path: "/profitCenter",
			name: "profitCenter",
			component: customIndex,
			meta: {
				title: "利润中心",
				icon: "el-icon-money"
			},
			children: [
				{
					path: "waybillProfit",
					component: waybillProfit,
					name: "waybillProfit",
					meta: {
						title: "运单数据"
					}
				},
				{
					path: "profitData",
					component: profitData,
					name: "profitData",
					meta: {
						title: "利润数据"
					}
				},
				{
					path: "feeData",
					component: feeData,
					name: "feeData",
					meta: {
						title: "费用数据"
					}
				},
				{
					path: "abnormalwaybill",
					component: abnormalwaybill,
					name: "abnormalwaybill",
					meta: {
						title: "异常运单"
					}
				},
				{
					path: "abnormalWeightWaybill",
					component: abnormalWeightWaybill,
					name: "abnormalWeightWaybill",
					meta: {
						title: "重量异常运单"
					}
				}
			]
		},
		{
			path: "refundForm",
			name: "refundForm",
			component: refundForm,
			meta: {
				title: "退款管理"
			}
		},
		{
			path: "orderExport",
			name: "orderExport",
			component: orderExport,
			meta: {
				title: "订单导出"
			}
		},
		{
			path: "finRefundUserExport",
			name: "finRefundUserExport",
			component: finRefundUserExport,
			meta: {
				title: "财务用友导出"
			}
		},
		{
			path: "purchaseOrderExport",
			name: "purchaseOrderExport",
			component: purchaseOrderExport,
			meta: {
				title: "采购订单导出"
			}
		},
		{
			path: "/financialOffline",
			name: "financialOffline",
			component: financialOffline,
			meta: {
				title: "财务线下收款"
			}
		},
		{
			path: "/customsData",
			name: "customsData",
			component: customsData,
			meta: {
				title: "报关运单数据"
			}
		}
	]
};
