import deLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Einrichtungsgebühr",
		PleaseAttention: "Bitte Achtung",
		MoldSetupFee: "Form-/Einrichtungsgebühr",
		invoice: "RECHNUNG",
		PONumber: "Bestellnummer",
		reference: "Referenz",
		HSCODE: "HS-CODE",
		PURCHASEINVOICE: "KAUFRECHNUNG",
		productPrintNo: "ProduktDrucken Nein",
		BILLTO: "GESETZESENTWURF FÜR",
		SHIPTO: "AUSLIEFERN",
		ProductDescription: "Produktbeschreibung",
		total: "Gesamt",
		PaymentStatus: "Zahlungsstatus",
		checkInfo: "Informationen prüfen",
		bankInfo: "Bankdaten",
		payOnline: "Online bezahlen",
		checkPay: "Prüfen: Zahlen und an die unten stehenden Informationen senden",
		attentionPlease: "Denken Sie daran",
		directorOperations: "Betriebsleiter",
		signature: "Unterschrift",
		nodesText: "Wenn Sie die Zahlung per Banküberweisung vornehmen, geben Sie bitte unbedingt Ihre",
		nodesText2: "im Memo.",
		thankbusiness: "Vielen Dank für Ihr Geschäft!",
		paid: "BEZAHLT",
		unpaid: "UNBEZAHLT",
		payInstall: "Teilzahlung, warten Sie auf den Restbetrag.",
		remember:
			" Bei Offshore-Konten und Fremdwährungskonten, die von ausländischen Kunden für Überweisungen an unsere Bank verwendet werden, muss die Verwendungsart der Gelder „Zahlung für Waren“ sein oder das Feld leer gelassen werden.",
		extraText: [
			"",
			"Eilgebühr",
			"Silketrykt gebyr",
			"Gebühr für Rückenform",
			"Gedruckte Ladung",
			"Kosten für Zinkdruckgussmaterial",
			"Zusätzliche Fracht",
			"Lizenzierte Produktgebühr",
			"Menge",
		],
		TaxPrice: "Steuer Preis",
		discountedAmount: "Ermäßigter Betrag",

		card: "1. Kreditkarte: VISA / Mastercard / AMEX und Discover",
		bankNote:
			"Hinweis: Wenn Sie in einer anderen Währung als EUR bezahlen möchten, wenden Sie sich bitte an unseren Kundendienstmitarbeiter.",
		checkFollow: "2. Unsere Scheckinformationen finden Sie wie folgt:",
		mention: "Bitte geben Sie bei der Zahlung die Rechnungsnummer an. Danke schön!",
		topic: "Thema: Finanzberatung",
		invoiceDate: "Rechnungsdatum",
		invoiceNumber: "Rechnungsnummer",
		excluding: "Zwischensumme ohne",
		phone: "Telefon",
		email: "Email",
		from: "Aus",
		to: "Zu",
		attn: "Achtung",
		lNVOICE: "RECHNUNG",
		PROFORMA: "PROFORMA-RECHNUNG",
		invoiceNo: "Rechnungsnr",
		date: "Datum",
		paymentMethod: "Bezahlverfahren",
		terms: "Bedingungen",
		title: "Titel",
		no: "NEIN",
		artwork: "Kunstwerk",
		item: "Artikel",
		description: "Beschreibung",
		qty: "Menge",
		unitPrice: "Einzelpreis",
		moldFee: "Formgebühr",
		extraFee: "Zusatzgebühr",
		amount: "Menge",
		subtotal: "Zwischensumme",
		apoShippingFee: "Apo-Versandgebühr",
		taxPrice: "Steuerpreis",
		couponAdditionalCosts: "Zusätzliche Kosten",
		firstOrderCodeDisc: "Erstbestellungsrabatt",
		promoCode: "Promo-Code",
		specialCode: "Spezielle Gruppenangebote",
		custompensCode: "$1 für 20 Stück Stifte",
		additionalCode: "1 $ für einen Gutschein im Wert von 75 $",
		pointsDeduct: "Punkteabzug",
		points: "Punkte",
		giftVouchers: "Geschenkgutschein",
		couponDiscount: "Coupon-Rabatt",
		orderDiscount: "Bestellrabat",
		extraDiscount: "Zusätzlicher Rabatt",
		finalPrice: "Endgültiger Preis",
		finalTotalPrice: "Endgültiger Gesamtpreis",
		estimatedShipDate: "Voraussichtliches Versanddatum",
		notes: "Anmerkungen",
		shipTo: " Ausliefern",
		name: "Name",
		check: "Überprüfen",
		APOFPOAddress: "APO/FPO-Adresse",
		Address: "Adresse",
		GrandTotal: "Gesamtsumme",
		AmountPaid: "Bezahlter Betrag",
		BalanceDue: "Restbetrag fällig",
		UserLevelDiscount: "Rabatt auf Benutzerebene",
	},
	Quotation: {
		setupFee: "Einrichtungsgebühr",
		free: "FREI",
		company: "Unternehmen",
		off: "AUS",
		additionalCosts: "Zusätzliche Kosten",
		firstOrderDiscount: "Erstbestellungsrabatt",
		specialGroup: "Spezielle Gruppenangebote",
		pointsDeduct: "Punkteabzug",
		giftVouchers: "Geschenkgutschein",
		couponDiscount: "Coupon-Rabatt",
		goldVipMember: "Gold-VIP-Mitglied",
		extraDiscount: "Zusätzlicher Rabatt",
		userLevelDiscount: "Rabatt auf Benutzerebene",
		points: "Punkte",
		title: "ZITAT",
		to: "Zu",
		from: "Aus",
		name: "Name",
		email: "Email",
		telephone: "Telefon",
		country: "Land",
		oid: "Auftragsnummer",
		date: "Angebotsdatum",
		valid: "Gültig bis",
		contacts: "Kontakte",
		currency: "Währung",
		no: "NEIN.",
		item: "Artikel",
		artwork: "Kunstwerk",
		description: "Beschreibung",
		qty: "Menge",
		unitPrice: "Einzelpreis",
		moldFee: "Formgebühr",
		extraFee: "Zusatzgebühr",
		apoShippingFee: "Apo-Versandgebühr",
		taxPrice: "Steuerpreis",
		amount: "Menge",
		remarks: "Bemerkungen",
		total: "Gesamt",
		titleTerms: "Terms & amp; Bedingungen:",
		first: "1. Kostenlose Grafik und kostenloser Versand in die USA/Kanada/Europa.",
		second: "2. Bei den oben genannten Informationen handelt es sich nicht um eine Rechnung, sondern nur um einen Kostenvoranschlag für die oben beschriebenen Dienstleistungen/Waren.",
		third: "3. Die Produktion beginnt an dem Datum, an dem wir Ihre Genehmigung und Zahlung für den Probeabzug erhalten. Bitte zahlen Sie über diesen Link für Ihre Bestellung auf der Grundlage des genehmigten Probeabzugs. (Zahlungslink:",
		end: "Vielen Dank für Ihr Geschäft!",
		tel: "Tel",
		hours: "Geschäftszeiten",
		chat: "Live-Chat",
		address: "USA-Adresse",
		hoursItem: "Montag – Freitag 8:30 bis 17:30 Uhr Pacific Time (PT)",
		chatItem: "24 Stunden Montag bis Freitag",
		addressItem: "20829 Valley Blvd. Walnuss, CA 91789",
	},
	member: {
		member1: "Silbernes Mitglied",
		member4: "Gold-VIP-Mitglied",
		member5: "Platin-VIP-Mitglied",
	},
	...deLocale,
};
