import { getToken } from "@/utils/auth";
import Vue from "vue";
import { Config } from "@/public/config";
import moment from "moment";
let token = getToken();
export const connectWs = () => {
	try {
		if (token) {
			const ws = new WebSocket(process.env.VUE_APP_WS_URL + "?token=" + token);
			ws.onopen = () => {};
			ws.onmessage = (response) => {
				let data = JSON.parse(response.data);
				if (data.type === 1) {
					Vue.prototype.$notify({
						dangerouslyUseHTMLString: true,
						title: data.message.announcementTitle,
						message: `<div><div>${data.message.announcementContent}</div> <a href="javascript:location.reload();">刷新页面</a></div>`,
						duration: 0,
						type: data.message.messageType,
					});
				}
			};
		}
	} catch (e) {}
};

/**
 * 工厂通讯与断点重连
 * 参数1:  推送url
 * 参数2： 推送对象
 * 参数3： 握手成功回调
 * 参数4： 握手断连回调
 * demo:
 * import { messageWs } from "@/plugins/ws";
	 更多文档：https://www.yuque.com/chaojigang-eu86m/cc492a/ez7q1ychl7ahnrsh
 */
let op = true; //防止重复
export const messageWs = (url, objectWs, success, error) => {
	try {
		if (url == null) {
			return;
		}
		if (objectWs == null) {
			if (op) {
				const ws = new WebSocket(url);
				ws.onopen = () => {
					console.log("通讯握手成功", moment().format("YYYY-MM-DD HH:mm:ss"), ws);
					objectWs = ws;
					if (success) {
						success(ws);
					}
				};
				ws.onclose = (event) => {
					console.log("通讯断开了=======>", event);
					op = true;
					if (error) {
						error(ws);
					}
					/* if (ws.readyState !== WebSocket.CLOSED) {
							ws.close();
					} else {
							console.log('WebSocket is already closed');
					} */
				};
				ws.onerror = (event) => {
					op = true;
					if (error) {
						error(ws);
					}
				};
				op = false;
				setInterval(
					() => {
						console.log("123456789", Config.app.equipmentInfo.downlink);
						if (Config.app.equipmentInfo.downlink) {
							if (ws.readyState === WebSocket.OPEN) {
								console.log("发送一条数据123");
								ws.send("7d173e126bb8fc68bc3ea268ad15b48a3d5465b4");
							}
						} else {
							console.log("断网了 不发送给服务端");
						}
					},
					//1000 * 3,
					//1000 * 59,
					45 * 1000,
				);
			}
		} else {
			//console.log("心跳检测", objectWs);
		}
	} catch (e) {
		//console.log("连接异常", e);
	}
};
