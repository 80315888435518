<template>
	<div>
		<div class="search">
			<el-form v-model="searchForm" inline>
				<el-form-item>
					<el-select
						filterable
						v-model="searchForm.userId"
						placeholder="请选择用户"
						clearable
						style="width: 200px"
					>
						<el-option
							v-for="item in userList"
							:key="item.userId"
							:label="item.userName"
							:value="item.userId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select
						filterable
						v-model="searchForm.type"
						placeholder="请选择日志类型"
						clearable
						style="width: 200px"
					>
						<el-option label="分类编辑" value="1"></el-option>
						<el-option label="基础价格编辑" value="2"></el-option>
						<el-option label="价格分层数据编辑" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker
						v-model="searchForm.time"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-select
						filterable
						v-model="searchForm.className"
						placeholder="请选择className"
						clearable
						style="width: 200px"
					>
						<el-option label="QuoteCateParam" value="QuoteCateParam"></el-option>
						<el-option label="QuoteParam" value="QuoteParam"></el-option>
						<el-option label="QuoteCate" value="QuoteCate"></el-option>
						<el-option label="WebsiteIncreasePrice" value="WebsiteIncreasePrice"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select
						filterable
						v-model="searchForm.operation"
						placeholder="请选择操作类型"
						clearable
						style="width: 200px"
					>
						<el-option label="insert" value="insert"></el-option>
						<el-option label="update" value="update"></el-option>
						<el-option label="delete" value="delete"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="table">
			<el-table :data="tableData" border @row-click="rowClick" style="overflow-x: hidden">
				<el-table-column prop="id" label="ID"></el-table-column>
				<el-table-column prop="type" label="日志类型">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">分类编辑</span>
						<span v-if="scope.row.type == 2">基础价格编辑</span>
						<span v-if="scope.row.type == 3">价格分层数据编辑</span>
					</template>
				</el-table-column>
				<el-table-column prop="userName" label="用户名"></el-table-column>
				<el-table-column prop="beforeData" label="beforeData">
					<template slot-scope="scope">
						<span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{
							scope.row.beforeData
						}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="afterData" label="afterData">
					<template slot-scope="scope">
						<span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{
							scope.row.afterData
						}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column prop="className" label="类名"></el-table-column>
				<el-table-column prop="operation" label="操作"></el-table-column>
			</el-table>
			<el-pagination
				background
				:current-page="current"
				@current-change="handleCurrentChange"
				:page-size="pageSize"
				layout="total, prev, pager, next, jumper"
				:total="total"
				style="text-align: center; margin-top: 20px"
			>
			</el-pagination>
		</div>
		<el-dialog
			:visible.sync="dialogVisible"
			width="80%"
			style="overflow-y: auto"
			scrollable
			:close-on-click-modal="false"
		>
			<el-card>
				<div slot="header" class="clearfix">
					<span class="text-h5">Diff Details</span>
				</div>
				<div class="container">
					<el-col :span="24">
						<CodeDiff
							:old-string="diffDetails.beforeStr"
							:new-string="diffDetails.afterStr"
							diff-style="word"
							language="json"
							output-format="side-by-side"
							hide-header
						/>
					</el-col>
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialogVisible = false"> OK </v-btn>
				</v-card-actions>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
import { CodeDiff } from "v-code-diff";
import { getLogList, getUserList } from "@/api/quotationLog";
export default {
	name: "quotationLog",
	components: { CodeDiff },
	data() {
		return {
			dialogVisible: false,
			searchForm: {
				type: "",
				time: [],
				userId: "",
				className: "",
				operation: "",
			},
			tableData: [],
			diffDetails: {},
			userList: [],
			current: 1,
			total: null,
			pageSize: 10,
			searchData: {},
		};
	},
	methods: {
		search() {
			let startTime =
				this.searchForm.time && this.searchForm.time[0] ? this.formatDate(this.searchForm.time[0]) : "";
			let endTime =
				this.searchForm.time && this.searchForm.time[1] ? this.formatDate(this.searchForm.time[1]) : "";
			const Data = {
				page: 1,
				pageSize: this.pageSize,
				type: this.searchForm.type,
				userId: this.searchForm.userId,
				className: this.searchForm.className,
				operation: this.searchForm.operation,
				startTime,
				endTime,
			};
			this.searchData = Data;
			this.current = 1;
			this.getLogList(Data);
		},
		rowClick(row) {
			this.dialogVisible = true;
			this.diffDetails = this.handleDiffDetails(row);
		},
		handleDiffDetails(row) {
			const beforeData = JSON.parse(row.beforeData);
			const afterData = JSON.parse(row.afterData);
			let obj = {
				id: row.id,
				beforeStr: JSON.stringify(beforeData, null, 2),
				afterStr: JSON.stringify(afterData, null, 2),
			};
			return obj;
		},
		getUserList() {
			getUserList().then((res) => {
				this.userList = res.data.userList;
			});
		},
		getLogList(Data) {
			const data = Data ? { ...Data } : { page: 1, pageSize: this.pageSize };
			getLogList(data).then((res) => {
				this.total = res.data.total;
				this.tableData = res.data.records.map((item) => {
					return {
						...item,
						createTime: this.formatDate(item.createTime),
					};
				});
			});
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, "0");
			const day = String(date.getDate()).padStart(2, "0");
			const hours = String(date.getHours()).padStart(2, "0");
			const minutes = String(date.getMinutes()).padStart(2, "0");
			const seconds = String(date.getSeconds()).padStart(2, "0");
			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},
		handleCurrentChange(current) {
			this.current = current;
			this.searchData.page = current;
			this.searchData.pageSize = this.pageSize;
			this.getLogList(this.searchData);
		},
	},
	created() {
		this.getLogList();
		this.getUserList();
	},
};
</script>

<style scoped>
.search {
	padding: 20px;
}
.table {
	padding: 20px;
}
.container {
	overflow: auto;
	height: 480px;
}
</style>
