import request from "@/utils/request";

//获取日志
export function getLogList(data) {
	return request({
		url: "/quote/quoteEntityLog/listLog",
		method: "post",
		data: data,
	});
}
// 获取所有经销商用户
export function getUserList() {
	return request({
		url: "/quote/quoteEntityLog/getSearchParam",
		method: "get",
	});
}
