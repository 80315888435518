<template>
	<div class="noResult">
		<div class="imgWrap">
			<img src="https://static-oss.gs-souvenir.com/web/quoteManage/20230822/icon-Oops_20230822RErNEC.png" alt="">
		</div>
		<div class="tip">Oops!</div>
		<ul>
			<li>Use other related words.</li>
			<li>Contact our customer service
				<a href="mailto:">123</a>
			</li>
			<li>Create or upload your own personalized cliparts.</li>
		</ul>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "@/assets/css/dzxt_theme";

.noResult {
	margin: 0 auto;
	padding: 20px;
	text-align: center;
	color: #666666;
	font-size: 18px;

	.tip {
		margin:10px 0 20px;
		font-size: 24px;
	}

	.imgWrap {
		display: inline-block;
		width: 100px;
		height: 87px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	ul {
		text-align: left;

		li {
			list-style: disc;

			a {
				color: $dz-primary;
			}
		}
	}
}
</style>
