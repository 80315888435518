/**
 * 全局配置文件 || cg
 */
export class Config {
	static imgServer = "https://static-oss.gs-souvenir.com/web/"; //国外
	static imgServerStatic = "https://oss-static-cn.liyi.co/web/"; //国内
	static api = process.env.VUE_APP_BASE_API || process.env.VUE_APP_BASE_URL; //"https://test-manage.ehomepoct.com/base-manage/"; // "http://dev-manage-api.ehome.com/";
	static app = {
		wsServer: "ws://52.80.115.183:15674/ws", //Rabbit MQ
		//图片服务
		imgServer: Config.imgServer,
		imgServerStatic: Config.imgServerStatic,

		//前端测试接口-小刚
		apiTest: "http://192.168.21.156:9988/", //http://www.51xiaogang.com/img/test.do
		api: Config.api,
		/* *
		 * 定时任务指定小时执行.timing:小时  timingM:分钟
		 * 如： timing:15,timingM:6,
		 * 15:06分触发
		 * */
		timing: 0,
		timingM: 30,
		// 用户信息
		userInfo: {},

		// 页面白名单权限控制
		controller: ["/login"],
		//ip地址白名单
		ipController: [
			/* 	{ ip: "111.22.163.200", name: "俊码星球" },
			{ ip: "", name: "蓝石" } */
		],
		/**
		 * 页面资源管理器
		 * 每打开一个页面，都会把当前页面的地址及基本信息记录下来. 跟后端的数据追踪,数据被删了可以还原是一样的。
		 * 前端用户一直点点点。都能记录下来。用户操作了哪些页面。
		 * 关键：当用户X了浏览器。history对象数据全部会清空
		 * demo:console.log(Config.app.history);
		 *  */
		history: [],
		/**
		 * 访问设备信息
		 * 用户访问页面是用的什么端。比如isWx是否微信打开，name:谷歌 ie 火狐各大浏览器.
		 * demo: if(Config.app.equipmentInfo.type == 'pc'){}
		 */
		equipmentInfo: {
			cityInfo: {
				//城市信息
				cid: null, //邮政编号
				cip: null, //ip地址
				cname: null, //所在城市
				address: null, //详细地址
				location: {
					//经纬度
					lat: "",
					lng: "",
				},
				message: "", //地图msg
			},
			system: null, //客户端访问操作系统.    win10  win7  安卓  苹果
			name: null, //型号(浏览器版本, 手机版本)
			isWx: null, //是否微信访问
			type: null, //pc||m
			downlink: 0, //网速Mbps(兆/秒)
			languageQywx: undefined, //企业微信语言包
			lang: undefined, //企业微信--远景系统语言包zh_cn||中文  en_us||英文
		},
	};
}
